/**
 * Module dependencies.
 */

import { MenuItem } from 'app/types/navigation';
import { Link } from 'react-router-dom';
import React from 'react';

/**
 * Export `compileMenu`.
 */

export function compileMenu(menu: MenuItem[]): MenuItem[] {
  return menu.map((item: MenuItem) => ({
    ...item,
    ...(!item.pathname
      ? {
          label: item.label
        }
      : ({
          label: <Link to={item.pathname as any}>{item.label}</Link>
        } as any)),
    children: item.children ? compileMenu(item.children) : undefined
  }));
}
