/**
 * Module dependencies.
 */

import { LastLineProps } from 'app/components/organisms/crud/form-types';
import tableStyles from 'app/components/organisms/table/table.module.less';
import { formatCurrency } from 'app/core/utils/formatter';
import BigNumber from 'bignumber.js';
import { reduce } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.less';
import classnames from 'classnames';
import { Field } from 'app/components/organisms/field/field';

/**
 * Export `TableFooter` component.
 */

export function TableFooter({ data }: LastLineProps): JSX.Element {
  const [translate] = useTranslation();
  const total = useMemo(() => {
    return reduce(
      data,
      (acc: any, item) => {
        acc.total = acc.total.plus(item.amount ?? '0');
        acc.totalWithTax = acc.totalWithTax.plus(item.total ?? '0');

        if (item.paid) {
          acc.totalPaid = acc.totalPaid.plus(item.total ?? '0');
        }

        return { ...acc };
      },
      {
        total: new BigNumber('0'),
        totalWithTax: new BigNumber('0'),
        totalPaid: new BigNumber('0')
      }
    );
  }, [data]);

  return (
    <div className={classnames(tableStyles.row, styles.total)}>
      <div className={styles.line}>
        <Field title={translate('common.labels.items')} value={String(data?.length)} />

        {Object.keys(total).map(key => {
          return <Field key={key} title={translate(`common.labels.${key}`)} value={formatCurrency(total[key])} />;
        })}

        <Field
          title={translate('common.labels.totalTAX')}
          value={formatCurrency(total.totalWithTax.minus(total.total))}
        />

        <Field
          title={translate('common.labels.inDebt')}
          value={formatCurrency(total.totalWithTax.minus(total.totalPaid))}
        />
      </div>
    </div>
  );
}
