/**
 * Module dependencies.
 */

import classnames from 'classnames';
import React from 'react';
import styles from './table-footer.module.less';
import { GlobalPrevision } from './global-prevision';
import { LastLineProps } from 'app/components/organisms/crud/form-types';
import { OpenClose } from './open-close';
import { Report } from './report';
import { Payments } from './payments';

/**
 * Export `TableFooter` component.
 */

export function TableFooter({ data }: LastLineProps): JSX.Element {
  return (
    <div className={classnames(styles.total)}>
      <GlobalPrevision data={data} />

      <OpenClose data={data} />

      <Payments data={data} />

      <Report data={data} />
    </div>
  );
}
