/**
 * Module dependencies.
 */

import { useCallback, useEffect, useState } from 'react';

/**
 * `Options` type.
 */

type Options = {
  size: number;
  breakpoint: string;
};

/**
 * Names.
 */

const names = ['mobileS', 'mobileM', 'mobileL', 'mobileXL', 'tabletM', 'laptopS', 'laptopM', 'laptopL', 'laptopXL'];

/**
 * Sizes.
 */

const sizes = [320, 374, 424, 600, 767, 991, 1200, 1439, 1440];

/**
 * Export `useContabBreakpoints`.
 */

export function useContabBreakpoints(): Options {
  const handleResize = useCallback((returnData?: boolean) => {
    const { innerWidth: width } = window;
    let index = sizes.findIndex(size => size >= width);

    index = index === -1 ? sizes.length - 1 : index;

    const size = sizes[index];
    const name = names[index];

    if (returnData) {
      return {
        size,
        breakpoint: name
      };
    }

    setState(data => {
      if (data.size === size && data.breakpoint === name) {
        return data;
      }

      return {
        size,
        breakpoint: name
      };
    });
  }, []);

  const [state, setState] = useState<Options>(handleResize(true) as any);

  useEffect(() => {
    function resize() {
      handleResize();
    }

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  });

  return state;
}
