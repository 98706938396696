/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';

/**
 * bank account rebuild.
 */

function bankAccountRebuild(client: AxiosInstance, accountId: number): Promise<any> {
  return client
    .post(
      resolveAppUrl('bankAccountConnectRebuild', {
        interpolations: { accountId }
      })
    )
    .then(({ data }) => data);
}

/**
 * Export `useBankAccountConnectRebuild`.
 */

export function useBankAccountConnectRebuild() {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async (accountId: number) => {
      const result = await bankAccountRebuild(client, accountId);

      return result;
    },
    onSuccess: () => {
      // Nothing to do.
    }
  });
}
