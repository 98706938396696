/**
 * Module dependencies.
 */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card } from '@tremor/react';
import styles from './overview.module.less';
import { CanvasChart } from 'app/components/organisms/canvas-chart/canvas-chart';
import { Title } from 'app/components/atoms/title/title';
import { useTranslation } from 'react-i18next';
import { useMovementsOverview } from 'app/hooks/requests/use-movements-overview';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import { merge } from 'lodash';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { appRoutes } from 'app/routes';
import { MonthStats } from './month-stats';
import { YearStats } from './year-stats';
import { useContabBreakpoints } from 'app/hooks/use-contab-breakpoints';

/**
 * Export `MovementsOverview` component.
 */

export function MovementsOverview(): JSX.Element {
  const [translate] = useTranslation();
  const ref = useRef();
  const { size } = useContabBreakpoints();
  const navigate = useNavigate();
  const [monthIndex, setMonthIndex] = useState<number | undefined>();
  const { data, refetch } = useMovementsOverview(monthIndex);
  const clickZone = useCallback((e: any) => {
    const serie = e.dataSeries.dataPoints[e.dataPointIndex];

    setMonthIndex(serie.x);
  }, []);

  const config = useMemo(() => {
    if (size <= 600) {
      return { column: 'column', labelAngle: -90, height: 600 };
    }

    return { column: 'bar', labelAngle: 0, height: 960 };
  }, [size]);

  console.log('>>', config);

  const budgetClick = useCallback(
    (event: any) => {
      const metadata = event.dataPoint.metadata;

      if (!metadata) {
        return;
      }

      const query = {
        filter: JSON.stringify({
          orderBy: 'bank_date#desc',
          category: metadata.budget.subCategoryId,
          year: metadata.yearIndex,
          transactionType: 'normal',
          month: metadata.monthIndex !== undefined ? metadata.monthIndex + 1 : undefined
        }),
        filterOpened: true
      };

      navigate({
        pathname: appRoutes.dashboard.bankAccounts.movementsManager.base,
        search: `?${createSearchParams(query as any)}`
      });
    },
    [navigate]
  );

  const incomeVsExpenses = useMemo(() => {
    if (!data) {
      return null;
    }

    return {
      ...data.incomeVsExpenses,
      title: {
        text: translate('bankAccounts.overview.incomeVsExpenses.title')
      },
      data: data.incomeVsExpenses.data.map((item: any) => ({
        ...item,
        click: clickZone
      }))
    };
  }, [clickZone, data, translate]);

  const budgets = useMemo(() => {
    if (!data?.budgetGraph || monthIndex === undefined) {
      return null;
    }

    const common = {
      dataPointMaxWidth: 10,
      height: config.height,
      axisX: {
        labelFontSize: 14,
        interval: 1,
        labelAngle: config.labelAngle
      }
    };

    const budgets = {
      monthExpenses: merge({}, data?.budgetGraph?.monthExpenses, common, {
        title: {
          text: translate('bankAccounts.overview.budgets.monthExpenses.title')
        },
        data: data?.budgetGraph?.monthExpenses.data.map((item: any) => ({
          ...item,
          click: budgetClick,
          type: config.column
        }))
      }),
      monthIncomes: merge({}, data?.budgetGraph?.monthIncomes, common, {
        title: {
          text: translate('bankAccounts.overview.budgets.monthIncomes.title')
        },
        data: data?.budgetGraph?.monthIncomes.data.map((item: any) => ({
          ...item,
          click: budgetClick,
          type: config.column
        }))
      }),
      yearExpenses: merge({}, data?.budgetGraph?.yearExpenses, common, {
        title: {
          text: translate('bankAccounts.overview.budgets.yearExpenses.title')
        },
        data: data?.budgetGraph?.yearExpenses.data.map((item: any) => ({
          ...item,
          click: budgetClick,
          type: config.column
        }))
      }),
      yearIncomes: merge({}, data?.budgetGraph?.yearIncomes, common, {
        title: {
          text: translate('bankAccounts.overview.budgets.yearIncomes.title')
        },
        data: data?.budgetGraph?.yearIncomes.data.map((item: any) => ({
          ...item,
          click: budgetClick,
          type: config.column
        }))
      })
    };

    budgets.monthExpenses.data = [
      {
        type: config.column,
        name: 'Preview',
        color: '#F6C336',
        showInLegend: true,
        dataPoints: budgets.monthExpenses.data?.[0].dataPoints.map((item: any, index: number) => {
          const totalBudget = budgets.yearExpenses.data?.[0].dataPoints[index].y;
          const totalConsumed = budgets.yearExpenses.data?.[1].dataPoints[index].y;
          const remainMonths = 12 - budgets.monthExpenses.data.length - 2;
          const dividedByMonth = new BigNumber(totalBudget).minus(totalConsumed).dividedBy(remainMonths).toNumber();

          return {
            label: item.label,
            y: dividedByMonth
          };
        })
      },
      ...budgets.monthExpenses.data
    ];

    return budgets;
  }, [budgetClick, config.column, config.height, config.labelAngle, data?.budgetGraph, monthIndex, translate]);

  useEffect(() => {
    if (!monthIndex || monthIndex > -1) {
      refetch();
    }
  }, [monthIndex, refetch]);

  console.log(budgets);

  return (
    <Card>
      <Title>{translate('bankAccounts.overview.title')}</Title>

      <div className={styles.wrapper}>
        {!!incomeVsExpenses && <CanvasChart className={styles.graph2} options={incomeVsExpenses} ref={ref} />}
      </div>

      {!!budgets && (
        <div className={styles.budgets}>
          <Title className={styles.titleMonth}>
            {translate('bankAccounts.overview.stats.monthTitle', {
              month: moment()
                .month(monthIndex ?? 0)
                .format('MMMM')
            })}
          </Title>

          <div className={styles.monthExpenses}>
            <CanvasChart className={styles.content} options={budgets.monthExpenses} ref={ref} />
          </div>

          <div className={styles.monthIncome}>
            <CanvasChart className={styles.content} options={budgets.monthIncomes} ref={ref} />
          </div>

          <div className={styles.monthStats}>
            <MonthStats
              incomeVsExpenses={incomeVsExpenses}
              monthExpenses={budgets.monthExpenses}
              monthIncomes={budgets.monthIncomes}
              monthIndex={monthIndex ?? -1}
            />
          </div>

          <Title className={styles.titleYear}>
            {translate('bankAccounts.overview.stats.yearTitle', {
              year: moment()
                .month(monthIndex ?? 0)
                .format('YYYY')
            })}
          </Title>

          <div className={styles.yearExpenses}>
            <CanvasChart className={styles.content} options={budgets.yearExpenses} ref={ref} />
          </div>

          <div className={styles.yearIncome}>
            <CanvasChart className={styles.content} options={budgets.yearIncomes} ref={ref} />
          </div>

          <div className={styles.yearStats}>
            <YearStats
              incomeVsExpenses={incomeVsExpenses}
              yearExpenses={budgets.yearExpenses}
              yearIncomes={budgets.yearIncomes}
            />
          </div>
        </div>
      )}
    </Card>
  );
}
