/**
 * Module dependencies.
 */

import { Typography } from 'antd';
import classnames from 'classnames';
import React from 'react';
import styles from './sub-title.module.less';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  children?: React.ReactElement | string;
};

/**
 * Export `SubTitle` component.
 */

export function SubTitle({ children, className }: Props): JSX.Element {
  return <Typography.Text className={classnames(styles.wrapper, className)}>{children}</Typography.Text>;
}

/**
 * Export `subTitleComponents`.
 */

export const subTitleComponents = {
  bold: <SubTitle className={styles.bold} />,
  regular: <SubTitle />
};
