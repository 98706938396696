/**
 * Module dependencies.
 */

import { useCrudById } from 'app/hooks/requests/crud/use-crud-by-id';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { CrudForm } from './crud-form';
import { CrudEditType, CrudTemplate, FormList, FormListField, IfConditionWidget } from './form-types';
import React, { useMemo } from 'react';
import { flattenDeep } from 'lodash';
import { useCrudEdit } from 'app/hooks/requests/crud/use-crud-edit';
import { CrudTemplateProvider } from './crud-template-provider';

/**
 * `Props` type.
 */

type Props = {
  template: CrudTemplate;
};

/**
 * Resolve form keys.
 */

export function resolveFormKeys(formFields: FormList): string[] {
  const result = formFields.map((item: FormListField) => {
    switch (item.type) {
      case 'if':
        return [
          ...resolveFormKeys((item as IfConditionWidget).thenIf ?? []),
          ...resolveFormKeys((item as IfConditionWidget).elseIf ?? [])
        ];
      default:
        return item.name;
    }
  });

  return flattenDeep(result);
}

/**
 * Export `CrudEdit` component.
 */

export function CrudEdit({ template }: Props): JSX.Element {
  const params = useParams();
  const navigate = useNavigate();
  const { mutate: edit } = useCrudEdit(template, params);
  const { redirect, submitLabel, normalizePayload, normalizeInitialValues } = template.edit as CrudEditType;
  const { data: payload } = useCrudById(template, params);
  const formFields = useMemo(() => {
    if (typeof template.edit?.formFields === 'function') {
      return template.edit?.formFields(payload);
    }

    return template.edit?.formFields ?? [];
  }, [payload, template.edit]);

  const formKeys = useMemo(() => {
    if (!template.edit?.formFields) {
      return [];
    }

    return resolveFormKeys(formFields);
  }, [formFields, template.edit?.formFields]);

  const routeList = template?.list?.route;
  const form = useForm({
    values: normalizeInitialValues?.(formKeys, payload),
    mode: 'all'
  });

  const onSubmit = async (values: any) => {
    await edit(normalizePayload?.(values), {
      onSuccess: () => {
        navigate(redirect ?? routeList ?? '');
      }
    });
  };

  return (
    <CrudTemplateProvider template={template}>
      <CrudForm
        form={form}
        formFields={formFields}
        formType={'edit'}
        onSubmit={onSubmit as any}
        submitLabel={submitLabel}
      />
    </CrudTemplateProvider>
  );
}
