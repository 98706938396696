/**
 * Module dependencies.
 */

import classnames from 'classnames';
import React from 'react';
import styles from './title.module.less';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  style?: any;
  children?: React.ReactElement | string;
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

/**
 * Export `Title` component.
 */

export function Title({ children, className, style, level = 'h1' }: Props): JSX.Element {
  return (
    <h1
      className={classnames(styles.wrapper, className, {
        [styles.h1]: level === 'h1',
        [styles.h2]: level === 'h2',
        [styles.h3]: level === 'h3',
        [styles.h4]: level === 'h4',
        [styles.h5]: level === 'h5',
        [styles.h6]: level === 'h6'
      })}
      style={style}
    >
      {children}
    </h1>
  );
}

/**
 * Export `titleComponents`.
 */

export const titleComponents = {
  bold: <Title className={styles.bold} />,
  regular: <Title />
};
