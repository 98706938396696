/**
 * Module dependencies.
 */

import { SubTitle } from 'app/components/atoms/sub-title/sub-title';
import { Field } from 'app/components/organisms/field/field';
import { formatCurrency } from 'app/core/utils/formatter';
import React, { useMemo } from 'react';
import styles from './table-footer.module.less';
import BigNumber from 'bignumber.js';
import { reduce } from 'lodash';
import { Text } from 'app/components/atoms/text/text';

/**
 * `Props` type.
 */

type Props = {
  data: any[] | undefined;
};

/**
 * Export `Payments` component.
 */

export function Payments({ data }: Props): JSX.Element {
  const { debt } = useMemo(() => {
    return reduce(
      data,
      (acc, item) => {
        console.log(item);

        if (new BigNumber(item.debt).isGreaterThan(0)) {
          acc.debt = acc.debt.plus(item.debt);
        }

        return {
          ...acc
        };
      },
      {
        debt: new BigNumber('0')
      }
    );
  }, [data]);

  return (
    <div className={styles.line}>
      <SubTitle className={styles.subTitle}>{'Pagamentos'}</SubTitle>

      <Text className={styles.info}>
        {'Informação de propostas abertas e fechadas. Abertas indica o valor de budget que ainda não tem proposta.'}
      </Text>

      <Field
        info={'Soma de todos os valores em divida após finalização de trabalhos da área.'}
        title={'A Decorrer'}
        value={formatCurrency(new BigNumber(debt).toFixed(2))}
      />
    </div>
  );
}
