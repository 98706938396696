/**
 * Module dependencies.
 */

import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { Label } from '../label/label';
import styles from './form-field.module.less';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  label?: string;
  children: ReactNode;
};

/**
 * Export `FormField` component.
 */

export function FormField({ className, label, children }: Props): JSX.Element {
  return (
    <div className={classnames(styles.wrapper, className)}>
      {label && <Label>{label}</Label>}

      {children}
    </div>
  );
}
