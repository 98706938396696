/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { BankAccount } from 'app/types/bank-account';
import { RequestOptions } from 'app/types/network';
import { List } from '../crud/use-crud-list';

/**
 * Get bank accounts.
 */

export function getBankAccounts(client: AxiosInstance, options?: RequestOptions): Promise<List<BankAccount>> {
  return client.get(resolveAppUrl('bankAccount', { params: options })).then(({ data }) => {
    return {
      results: data?.data,
      total: data?.data.length
    };
  });
}

/**
 * Export `useBankAccountsInvalidator`.
 */

export function useBankAccountsInvalidator() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(['bankAccounts']);
  }, [queryClient]);
}

/**
 * Export `useBankAccounts`.
 */

export function useBankAccounts(options?: RequestOptions) {
  const client = useRequestProvider();

  return useQuery(['bankAccounts'], {
    queryFn: async () => {
      return await getBankAccounts(client, options);
    }
  });
}
