/**
 * Module dependencies.
 */

import React, { forwardRef } from 'react';
import { Checkbox as AntdCheckbox, CheckboxProps as AntdCheckboxProps } from 'antd';
import { FormField } from '../../form-field/form-field';
import { ErrorField } from '../../error-field/error-field';
import styles from './checkbox.module.less';
import classnames from 'classnames';
import { Label } from '../../label/label';

/**
 * `Props` type.
 */

export type CheckboxProps = Omit<AntdCheckboxProps, 'error'> & {
  error: any;
  className?: string;
  fieldClassName?: string;
  invalid?: boolean;
  isDirty?: boolean;
  label: string;
  isTouched?: boolean;
};

/**
 * Export `CheckboxComponent` component.
 */

export function CheckboxComponent(props: CheckboxProps, forwardedRef: any): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, invalid, fieldClassName, isTouched, isDirty, label, ...rest } = props;
  const { error } = rest;

  return (
    <FormField className={className}>
      <AntdCheckbox
        checked={!!rest.value}
        className={classnames(styles.wrapper, fieldClassName)}
        ref={forwardedRef}
        {...rest}
      >
        <Label>{label}</Label>
      </AntdCheckbox>

      <ErrorField error={error} isVisible={invalid || isTouched} />
    </FormField>
  );
}

/**
 * Export `Checkbox` component.
 */

export const Checkbox = forwardRef<any, CheckboxProps>(CheckboxComponent);
