/**
 * Module dependencies.
 */

import { Title } from 'app/components/atoms/title/title';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './projects-overview.module.less';
import { Button } from 'app/components/atoms/button/button';
import { appRoutes } from 'app/routes';
import { Link } from 'react-router-dom';

/**
 * Export `ProjectsOverview` component.
 */

export function ProjectsOverview(): JSX.Element {
  const [translate] = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Title>{translate('projects.overview.title')}</Title>

      <Link to={appRoutes.dashboard.projects.addNewProject}>
        <Button type={'primary'}>{translate('projects.overview.createProject')}</Button>
      </Link>
    </div>
  );
}
