/**
 * Module dependencies.
 */

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import {
  CrudAddType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  ListColumns
} from 'app/components/organisms/crud/form-types';

import { normalizeTemplate } from 'app/components/organisms/crud/utils';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import { appRoutes } from 'app/routes';
import { TFunction } from 'i18next';
import { formFields } from './form-fields';
import { omit, pick } from 'lodash';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'id',
    size: '30px',
    key: 'id'
  },
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'provider',
    size: '1fr',
    key: 'provider'
  },
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'apiKey',
    key: 'apiKey'
  },
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'secret',
    key: 'secret'
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: String(translate('common.labels.search'))
  }
];

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType => ({
  formFields: formFields(translate),
  normalizePayload(values) {
    switch (values.accountType) {
      case 'exchangeAccount':
        const masterKeys = ['provider', 'apiKey', 'secret'];

        return {
          ...pick(values, masterKeys),
          metadata: JSON.stringify({
            ...omit(values, masterKeys)
          })
        };
      default:
        return {};
    }
  }
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType => ({});

/**
 * List.
 */

const list = (translate: TFunction): CrudListType => ({
  columns: listColumns(translate),
  canEdit: false,
  route: appRoutes.dashboard.crypto.accounts,
  key: ['cryptoAccounts'],
  endpoint: apiEndpoints.cryptoAccounts,
  filters: listFilters(translate)
});

/**
 * Config.
 */

export function createCryptoAccountsTemplate(translate: TFunction): CrudTemplate {
  return normalizeTemplate(translate, {
    add: add(translate),
    list: list(translate),
    remove: remove()
  });
}
