/**
 * Module dependencies.
 */

import { Loading } from 'app/components/atoms/loading/loading';
import React, { useMemo } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { CrudForm } from '../crud-form';
import { SwitchConditionWidget } from '../form-types';

/**
 * `Props` type.
 */

type Props = {
  formType: 'add' | 'edit';
  name: string;
  item: SwitchConditionWidget;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * `SwitchHookCondition` component.
 */

export function SwitchHookCondition(props: Props) {
  const { form, item, formType } = props;
  const { condition, switchCases } = item;
  const { data, isLoading } = (condition as any).hook?.(form, formType);
  const caller = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return switchCases?.[data] ?? switchCases?.default;
  }, [switchCases, data]);

  if (!caller) {
    return null;
  }

  return (
    <Loading isLoading={isLoading}>
      {typeof caller === 'object' ? (
        <CrudForm form={form} formFields={caller} formType={formType} />
      ) : (
        (caller as any)?.()
      )}
    </Loading>
  );
}

/**
 * `SwitchStateCondition` component.
 */

export function SwitchStateCondition(props: Props) {
  const { form, item, formType } = props;
  const { watch } = form;
  const { condition, switchCases } = item;
  const values = watch((item as any).watchFields);
  const result = useMemo(() => (condition as any)(values), [condition, values]);
  const caller = useMemo(() => {
    if (!result) {
      return;
    }

    return switchCases?.[result] ?? switchCases?.default;
  }, [result, switchCases]);

  if (!caller) {
    return undefined;
  }

  if (typeof caller === 'object') {
    return <CrudForm form={form} formFields={caller} formType={formType} />;
  } else {
    return (caller as any)?.();
  }
}

/**
 * Export `SwitchCondition` component.
 */

export function SwitchCondition(props: Props): JSX.Element | null {
  if (typeof (props.item?.condition as any).hook === 'function') {
    return <SwitchHookCondition {...props} />;
  }

  return <SwitchStateCondition {...props} />;
}
