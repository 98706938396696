/**
 * Module dependencies.
 */

import React, { forwardRef, useMemo } from 'react';
import {
  InputNumber as AntdInputNumber,
  InputNumberProps as AntdInputNumberProps,
  Slider as AntdSlider,
  SliderSingleProps
} from 'antd';

import { FormField } from '../../form-field/form-field';
import { ErrorField } from '../../error-field/error-field';
import styles from './slider.module.less';
import classnames from 'classnames';

/**
 * `Props` type.
 */

export type SliderProps = Omit<AntdInputNumberProps, 'error'> &
  SliderSingleProps & {
    error: any;
    className?: string;
    fieldClassName?: string;
    invalid?: boolean;
    isDirty?: boolean;
    label: string;
    isTouched?: boolean;
  };

/**
 * Export `SliderComponent` component.
 */

export function SliderComponent(props: SliderProps, forwardedRef: any): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, invalid, fieldClassName, isTouched, isDirty, label, max, min, ...rest } = props;
  const { error } = rest;
  const { onChange, value } = rest;
  const normalizedValue = useMemo(() => {
    return typeof value === 'number' ? value : 0;
  }, [value]);

  console.log(normalizedValue, value);

  return (
    <FormField className={className} label={label}>
      <div className={styles.wrapper}>
        <div className={styles.slider}>
          {value !== undefined && (
            <AntdSlider max={max} min={min} onChange={onChange} ref={forwardedRef} value={normalizedValue} />
          )}
        </div>

        <div className={styles.input}>
          <AntdInputNumber className={classnames(styles.inputWrapper, fieldClassName)} {...rest} />
        </div>
      </div>

      <ErrorField error={error} isVisible={invalid || isTouched} />
    </FormField>
  );
}

/**
 * Export `Slider` component.
 */

export const Slider = forwardRef<any, SliderProps>(SliderComponent);
