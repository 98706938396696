/**
 * Module dependencies.
 */

import { useParams } from 'react-router-dom';
import { useProject } from './requests/projects/use-project';

/**
 * Export `useFormLoadProject` hook.
 */

export function useFormLoadProject() {
  const params = useParams();
  const { data } = useProject(params.projectId);

  return data;
}
