/**
 * Module dependencies.
 */

import { useBankAccount } from 'app/hooks/requests/bank-accounts/use-bank-account';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SelectField } from 'app/components/atoms/forms/fields/select/select-field';
import { searchOnInput } from 'app/core/utils/filter-utils';
import { requiredRule } from 'app/core/utils/field-rules';
import { SubmitButton } from 'app/components/atoms/forms/submit-button/submit-button';
import { useForm } from 'react-hook-form';
import { Title } from 'app/components/atoms/title/title';
import { Field } from 'app/components/organisms/field/field';
import { formatCurrency } from 'app/core/utils/formatter';
import { SubTitle } from 'app/components/atoms/sub-title/sub-title';
import { useBankAccountConnectRedirectUrl } from 'app/hooks/requests/bank-accounts/use-bank-account-redirect-url';
import { Alert, Popconfirm, Table, TableProps } from 'antd';
import React, { useMemo, useCallback, useState } from 'react';
import styles from './bank-account-connect.module.less';
import { Button } from 'app/components/atoms/button/button';
import { useBankAccountConnectRebuild } from 'app/hooks/requests/bank-accounts/use-bank-account-rebuild';
import { useBankAccountCleanTransactions } from 'app/hooks/requests/bank-accounts/use-bank-account-clean-transactions';
import { Bank, BankAccountLink } from 'app/types/bank-account';
import { Loading } from 'app/components/atoms/loading/loading';
import { useBankAccountLinks } from 'app/hooks/requests/bank-accounts/use-bank-account-links';
import { useBankList } from 'app/hooks/requests/bank-accounts/use-bank-list';
import { toLower, uniqBy } from 'lodash';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { appRoutes } from 'app/routes';
import { useBankAccountLocalConnection } from 'app/hooks/requests/bank-accounts/use-bank-account-local-connection';
import { MdOutlineAutoDelete, MdOutlineDelete } from 'react-icons/md';
import { useBankAccountDeleteConnection } from 'app/hooks/requests/bank-accounts/use-bank-account-delete-connection';
import { BankList } from './components/bank-list';

/**
 * Export `BankAccountConnect` component.
 */

export function BankAccountConnect(): JSX.Element {
  const params = useParams();
  const [translate] = useTranslation();
  const [needRefresh, setNeedRefresh] = useState<any>();
  const { data: account, refetch: refresh } = useBankAccount(params.id ?? '');
  const { data: bankList } = useBankList('local');
  const { mutateAsync: getRedirectUrl, isLoading: isLoadingConnectRedirect } = useBankAccountConnectRedirectUrl();
  const { mutateAsync: createLocalConnection } = useBankAccountLocalConnection();
  const { mutateAsync: deleteConnection } = useBankAccountDeleteConnection();
  const { mutateAsync: rebuild } = useBankAccountConnectRebuild();
  const { mutateAsync: cleanAllTransactions, isLoading: isCleanTransactionsLoading } =
    useBankAccountCleanTransactions();

  const { data: accounts, isLoading: isLoadingBankAccounts, refetch: accountsRefresh } = useBankAccountLinks();
  const navigate = useNavigate();
  const [showBankList, setShowBankList] = useState(false);
  const [showCredentials, setShowCredentials] = useState<Bank | null>(null);

  const handleDeleteConnection = useCallback(
    async (item: any, soft: boolean) => {
      try {
        await deleteConnection({ bankAccount: item.id, soft });
        await refresh();
        await accountsRefresh();
      } catch (error) {
        console.log(error);
      }
    },
    [accountsRefresh, deleteConnection, refresh]
  );

  const accountOptions = useMemo(() => {
    return uniqBy(accounts, 'iban').map((item: BankAccountLink) => {
      return {
        label: item.name,
        value: item.id
      };
    });
  }, [accounts]);

  const handleAddNewConnection = useCallback(async () => {
    try {
      const bank = bankList?.find(item => {
        return (
          toLower(item.name) === toLower(account?.name) &&
          toLower(item.country) ===
            toLower((account as any).bankAccountConnectAccount?.[0]?.bankAccountConnect?.country)
        );
      });

      if (!bank) {
        return null;
      }

      const result = await getRedirectUrl({ bank, accountId: params.id ?? '' });

      if (result?.url) {
        window.location.href = result.url;
      }
    } catch (err) {}
  }, [account, bankList, getRedirectUrl, params.id]);

  const { control, handleSubmit } = useForm({
    mode: 'all',
    values: {
      integration: account?.integration?.id
    }
  });

  const onSubmit = useCallback(
    async (data: any) => {
      if (data.integration && !data.username && !data.password) {
        navigate(
          resolveAppPath(appRoutes.dashboard.bankAccounts.accounts.sync, {
            accountId: params.id,
            integrationId: data.integration
          })
        );
      }

      if (data.username && data.password && showCredentials) {
        try {
          await createLocalConnection({
            bank: showCredentials,
            username: data.username,
            password: data.password
          });

          await refresh();
          await accountsRefresh();

          setShowCredentials(null);
          setShowBankList(false);
        } catch (error) {
          console.log(error);
        }
      }
    },
    [accountsRefresh, createLocalConnection, navigate, params.id, refresh, showCredentials]
  );

  const schema = useMemo(() => {
    return {
      integration: requiredRule(translate)
    };
  }, [translate]);

  const haveTransactions = account?.transactions?.length;

  const handleReconnect = useCallback(async () => {
    location.href = needRefresh?.updateConsentLink;
  }, [needRefresh?.updateConsentLink]);

  const handleRebuild = useCallback(async () => {
    try {
      if (!account?.id) {
        return;
      }

      const result = await rebuild(account?.id);

      if (result.status !== 'UPDATED') {
        setNeedRefresh(result);
      }

      await refresh();
    } catch (error) {
      // Nothing to do.
    }
  }, [account?.id, rebuild, refresh]);

  const columns: TableProps<any>['columns'] = [
    {
      title: translate('common.labels.id'),
      dataIndex: 'id',
      key: 'name',
      render: item => {
        console.log(item);
        return '...' + item.substring(item.length - 8, item.length);
      }
    },
    {
      title: translate('common.labels.iban'),
      dataIndex: 'iban',
      key: 'age'
    },
    {
      title: translate('common.labels.balance'),
      dataIndex: 'balance',
      key: 'address'
    },
    {
      title: translate('common.labels.actions'),
      dataIndex: 'balance',
      key: 'address',
      render: (_, item) => {
        return (
          <>
            <Button
              icon={<MdOutlineDelete />}
              onClick={() => {
                handleDeleteConnection(item, false);
              }}
              size={'small'}
            />

            <Button
              icon={<MdOutlineAutoDelete />}
              onClick={() => {
                handleDeleteConnection(item, true);
              }}
              size={'small'}
            />
          </>
        );
      }
    }
  ];

  return (
    <div className={styles.page}>
      <Title>{translate('bankAccounts.connect.title')}</Title>

      <Loading isLoading={isLoadingBankAccounts}>
        {!!account && (
          <>
            <Field title={translate('common.labels.name')} value={account?.name} />

            <Field title={translate('common.labels.coin')} value={account?.coin?.name} />

            <Field title={translate('common.labels.initialBalance')} value={formatCurrency(account?.initialBalance)} />

            <Field title={translate('common.labels.balance')} value={formatCurrency(account?.balance)} />
          </>
        )}

        <SubTitle className={styles.integrationTitle}>{translate('bankAccounts.connections.title')}</SubTitle>

        <Table columns={columns} dataSource={(account as any)?.bankAccountConnectAccount} pagination={false} />

        <BankList
          accountId={params.id}
          isOpen={showBankList}
          refresh={async () => {
            await accountsRefresh();
            await refresh();
          }}
          setVisible={value => {
            setShowBankList(value);
          }}
        />

        {!!haveTransactions ? (
          <Alert
            className={styles.warningBox}
            description={translate('bankAccounts.connect.invalidAccount.description')}
            message={translate('bankAccounts.connect.invalidAccount.title')}
            showIcon
            type={'error'}
          />
        ) : (
          <>
            <SubTitle className={styles.subTitle}>{translate('bankAccounts.connect.selectIntegration')}</SubTitle>

            <form onSubmit={handleSubmit(onSubmit)}>
              {!account?.hasConnected && !showCredentials && (
                <div className={styles.connectionWrapper}>
                  <SelectField
                    className={styles.select}
                    control={control}
                    disabled={!!account?.integration}
                    filterOption={searchOnInput}
                    label={translate('common.labels.integration')}
                    name={'integration'}
                    options={accountOptions}
                    placeholder={translate('common.labels.integration')}
                    rules={schema.integration}
                    showSearch
                  />

                  {!showCredentials && (
                    <Button
                      className={styles.button}
                      isLoading={isLoadingConnectRedirect}
                      onClick={() => {
                        setShowBankList(!showBankList);
                      }}
                      type={'primary'}
                    >
                      {translate(!showBankList ? 'bankAccounts.connect.createConnection' : 'common.actions.cancel')}
                    </Button>
                  )}
                </div>
              )}

              {!showBankList && !showCredentials && (
                <div className={styles.actions}>
                  {!account?.hasConnected && (
                    <SubmitButton
                      className={styles.submitButton}
                      label={translate(
                        !(account as any)?.bankAccountConnectAccount
                          ? 'common.actions.reconnect'
                          : 'common.actions.connect'
                      )}
                      loading={false}
                    />
                  )}

                  {account?.hasConnected && (
                    <>
                      {needRefresh ? (
                        <Button danger onClick={handleReconnect}>
                          {translate('common.actions.reconnect')}
                        </Button>
                      ) : (
                        <Button onClick={handleRebuild}>{translate('common.actions.rebuild')}</Button>
                      )}
                    </>
                  )}

                  {account?.hasConnected && (
                    <>
                      {needRefresh ? (
                        <Button danger onClick={handleReconnect}>
                          {translate('common.actions.reconnect')}
                        </Button>
                      ) : (
                        <Button onClick={handleAddNewConnection} type={'primary'}>
                          {translate('common.actions.addNewConnection')}
                        </Button>
                      )}
                    </>
                  )}

                  {!account?.hasConnected && (
                    <Popconfirm
                      okButtonProps={{ loading: isCleanTransactionsLoading }}
                      {...{ onClick: (event: any) => event.stopPropagation() }}
                      onConfirm={async (event: any) => {
                        event.stopPropagation();
                        try {
                          await cleanAllTransactions(account?.id);
                        } catch (error) {}
                      }}
                      title={translate('removeAction')}
                    >
                      <Button>{translate('common.actions.cleanAllTransactions')}</Button>
                    </Popconfirm>
                  )}
                </div>
              )}
            </form>
          </>
        )}
      </Loading>
    </div>
  );
}
