// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-module__wrapper--UaG0N {
  background-color: gray;
}
.home-module__login--bY9bF {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./components/pages/home/home.module.less"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AAGA;EACE,aAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADF","sourcesContent":[".wrapper {\n  background-color: gray;\n}\n\n\n.login {\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `home-module__wrapper--UaG0N`,
	"login": `home-module__login--bY9bF`
};
export default ___CSS_LOADER_EXPORT___;
