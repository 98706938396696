/**
 * Module dependencies.
 */

import { FormList } from 'app/components/organisms/crud/form-types';
import { TFunction } from 'react-i18next';
import { formNormal } from './form-normal';
import { switchAccount } from './switch-account';
import { splitTransaction } from './split-transaction';

/**
 * Export `formFieldsAccountSelected`.
 */

export const formFieldsAccountSelected = (translate: TFunction, data: any): FormList => [
  {
    type: 'selectField',
    name: 'transactionType',
    label: translate('common.labels.transactionType'),
    rules: undefined,
    options: [
      {
        value: 'normal',
        label: translate('common.labels.normal')
      },
      {
        value: 'switchAccount',
        label: translate('common.labels.movementToOtherAccount')
      },
      ...(!data
        ? []
        : [
            {
              value: 'splitTransaction',
              label: translate('common.labels.splitTransaction')
            }
          ])
    ]
  },
  {
    type: 'switch',
    name: 'need_account_id',
    watchFields: ['transactionType'],
    condition: values => {
      return values?.[0];
    },
    switchCases: {
      normal: formNormal(translate, data),
      switchAccount: switchAccount(translate, data),
      splitTransaction: splitTransaction(translate, data)
    }
  }
];
