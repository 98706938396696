/**
 * Module dependencies.
 */

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import { CrudListType, CrudTemplate, ListColumns } from 'app/components/organisms/crud/form-types';
import { normalizeTemplate } from 'app/components/organisms/crud/utils';
import { appRoutes } from 'app/routes';
import { TFunction } from 'i18next';
import { hasSelectedProposal } from '../proposals/form-config';
import { formatCurrency } from 'app/core/utils/formatter';
import { RenderIva } from 'app/components/molecules/render-iva/render-iva';
import React from 'react';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'id',
    size: '30px',
    key: 'id'
  },
  {
    title: translate('common.table.columns.subject'),
    dataIndex: 'title',
    size: '1fr',
    key: 'title'
  },
  {
    title: translate('common.table.columns.supplier'),
    dataIndex: 'supplier',
    key: 'supplier',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start'
      }),
    render: item => item.supplier.company
  },
  {
    title: translate('common.table.columns.ivaPerc'),
    dataIndex: 'ivaPerc',
    key: 'ivaPerc',
    size: '50px',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      }),
    styleRow: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        fontFamily: 'Oswald',
        justifyContent: 'flex-end'
      }),
    render: () => <RenderIva />
  },
  {
    title: translate('common.table.columns.ivaRange'),
    dataIndex: 'ivaRange',
    key: 'ivaRange',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      }),
    styleRow: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        fontFamily: 'Oswald',
        justifyContent: 'flex-end'
      }),
    render: item => `${item.ivaRange}%`
  },
  {
    title: translate('common.table.columns.amount'),
    dataIndex: 'amount',
    key: 'amount',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      }),
    styleRow: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        fontFamily: 'Oswald',
        justifyContent: 'flex-end'
      }),
    render: item => formatCurrency(item.budget)
  },
  {
    title: translate('common.table.columns.ivaTotalCharged'),
    dataIndex: 'ivaTotalCharged',
    key: 'ivaTotalCharged',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      }),
    styleRow: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        fontFamily: 'Oswald',
        justifyContent: 'flex-end'
      }),
    render: item => formatCurrency(item.ivaTotalCharged)
  },
  {
    title: translate('common.table.columns.ivaAmountApplied'),
    dataIndex: 'notivaAmountAppliedes',
    key: 'ivaAmountApplied',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      }),
    styleRow: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        fontFamily: 'Oswald',
        justifyContent: 'flex-end'
      }),
    render: item => formatCurrency(item.ivaAmountApplied)
  },
  {
    title: translate('common.table.columns.total'),
    dataIndex: 'total',
    key: 'total',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        paddingLeft: 64
      }),
    styleRow: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        fontFamily: 'Oswald',
        justifyContent: 'flex-end'
      }),
    render: item => formatCurrency(item.total)
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: String(translate('common.labels.search'))
  }
];

/**
 * List.
 */

const list = (translate: TFunction): CrudListType => ({
  addButton: undefined,
  columns: listColumns(translate),
  canEdit: false,
  canRemove: false,
  style: { minWidth: 500, maxWidth: '100%' },
  nestedList: item => {
    return item.proposals;
  },
  route: appRoutes.dashboard.projects.project.suppliersContacts,
  key: ['projectSuppliersContacts'],
  endpoint: null,
  filters: listFilters(translate)
});

/**
 * Config.
 */

export function createExtendedProposalsTemplate(translate: TFunction): CrudTemplate {
  return normalizeTemplate(translate, {
    list: list(translate)
  });
}
