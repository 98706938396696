/**
 * Module dependencies.
 */

import { Title } from 'app/components/atoms/title/title';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StatsItem } from '../../../organisms/stats-item/stats-item';
import { formatCurrency } from 'app/core/utils/formatter';
import BigNumber from 'bignumber.js';

/**
 * `Props` type.
 */

type Props = {
  incomeVsExpenses: any;
  monthExpenses: any;
  monthIncomes: any;
  monthIndex: number;
};

/**
 * Export `MonthStats` component.
 */

export function MonthStats({ monthIndex, incomeVsExpenses }: Props): JSX.Element {
  const [translate] = useTranslation();
  const stats = useMemo(() => {
    const income = incomeVsExpenses.data[0].dataPoints[monthIndex].y;
    const expense = incomeVsExpenses.data[1].dataPoints[monthIndex].y;
    const diff = new BigNumber(income).minus(expense);
    const perc = new BigNumber(expense).multipliedBy(100).dividedBy(income);

    return { income, expense, diff, perc: new BigNumber(100).minus(perc) };
  }, [monthIndex, incomeVsExpenses.data]);

  return (
    <>
      <Title>{translate('bankAccounts.overview.stats.details.month.title')}</Title>

      <StatsItem label={translate('common.labels.income')} value={formatCurrency(stats.income)} />

      <StatsItem label={translate('common.labels.expenses')} value={formatCurrency(stats.expense)} />

      <StatsItem label={translate('common.labels.savings')} value={formatCurrency(stats.diff)} />

      <StatsItem label={translate('common.labels.savingsPerc')} value={`${stats.perc.toFixed(2)}%`} />
    </>
  );
}
