/**
 * Module dependencies.
 */

import React, { ReactNode } from 'react';

/**
 * `Props` type.
 */

type Props =
  | {
      className?: string;
      children: ReactNode;
    }
  | any;

/**
 * Export `Wrapper` component.
 */

export function Wrapper({ children, className }: Props): JSX.Element {
  return <div className={className}>{children}</div>;
}
