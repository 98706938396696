/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { EndpointKey, EndpointRequestOptions, resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { castArray } from 'lodash';

/**
 * `Options` type.
 */

type Options = {
  endpoint: EndpointKey | null;
  options: EndpointRequestOptions | undefined;
  key: string | string[];
};

/**
 * Crud Request.
 */

function crudRequest<T>(client: AxiosInstance, endpoint: EndpointKey, options = {}): Promise<T> {
  return client.get(resolveAppUrl(endpoint, options)).then(({ data }) => data?.data);
}

/**
 * Export `useCrudRequest`.
 */

export function useCrudRequest<T>({ endpoint, options, key }: Options) {
  const client = useRequestProvider();

  return useQuery(['crud-request', ...castArray(key), JSON.stringify(options)], {
    queryFn: async () => {
      if (!endpoint) {
        return null;
      }

      return await crudRequest<T>(client, endpoint, options);
    }
  });
}
