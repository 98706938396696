/**
 * Module dependencies.
 */

import { Field } from 'app/components/organisms/field/field';
import { formatCurrency } from 'app/core/utils/formatter';
import { AccountSync } from 'app/hooks/requests/bank-accounts/use-bank-account-status';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * `Props` type.
 */

type Props = {
  data: AccountSync;
};

/**
 * Export `DefaultInfo` component.
 */

export function DefaultInfo({ data }: Props): JSX.Element | null {
  const [translate] = useTranslation();

  return (
    <>
      <Field title={translate('common.labels.name')} value={data.name} />

      {!!data.iban && <Field title={translate('common.labels.iban')} value={data.iban} />}

      <Field title={translate('common.labels.bic')} value={data.bic} />

      <Field title={translate('common.labels.country')} value={data.country} />

      <Field
        title={translate('common.labels.balance')}
        value={formatCurrency(data.balance, { locale: 'en', currency: data.currency })}
      />

      <Field title={translate('common.labels.currency')} value={data.currency} />
    </>
  );
}
