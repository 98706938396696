/**
 * Module dependencies.
 */

import { SubTitle } from 'app/components/atoms/sub-title/sub-title';
import { Field } from 'app/components/organisms/field/field';
import { formatCurrency } from 'app/core/utils/formatter';
import BigNumber from 'bignumber.js';
import { reduce } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './table-footer.module.less';
import { Text } from 'app/components/atoms/text/text';

/**
 * Metros quadrados.
 */

const METROS_QUADRADOS = 220;

/**
 * `Props` type.
 */

type Props = {
  data: any[] | undefined;
};

/**
 * Export `Report` component.
 */

export function Report({ data }: Props): JSX.Element {
  const [translate] = useTranslation();
  const { initialPrevision, paid, debt, budgetDeviation } = useMemo(() => {
    return reduce(
      data,
      (acc, item) => {
        acc.initialPrevision = acc.initialPrevision.plus(item.prevision ?? '0');
        acc.paid = !item.paid ? acc.paid : acc.paid.plus(item.paid);
        acc.debt = !item.debt ? acc.debt : acc.debt.plus(item.debt);
        acc.budgetDeviation =
          !item.diffOverBudget || item.proposals.length === 0
            ? acc.budgetDeviation
            : acc.budgetDeviation.plus(item.diffOverBudget);

        // TODO: Bug no budgetDeviation

        return {
          ...acc
        };
      },
      {
        initialPrevision: new BigNumber('0'),
        paid: new BigNumber('0'),
        debt: new BigNumber('0'),
        budgetDeviation: new BigNumber('0')
      }
    );
  }, [data]);

  return (
    <div className={styles.line}>
      <SubTitle className={styles.subTitle}>{translate('common.labels.paid')}</SubTitle>

      <Field
        title={translate('common.labels.initialPrevision')}
        value={formatCurrency(new BigNumber(initialPrevision).toFixed(2))}
      />

      <Field title={translate('common.labels.totalPaid')} value={formatCurrency(new BigNumber(paid).toFixed(2))} />

      <Field
        danger={debt.isGreaterThan('0')}
        title={translate('common.labels.needPaid')}
        value={formatCurrency(new BigNumber(debt).toFixed(2))}
      />

      <Field
        danger={budgetDeviation.isLessThan('0')}
        title={translate('common.labels.budgetDeviation')}
        value={formatCurrency(new BigNumber(budgetDeviation).toFixed(2))}
      />

      <Field
        title={translate('common.labels.newPrevision')}
        value={formatCurrency(initialPrevision.minus(new BigNumber(budgetDeviation).toFixed(2)))}
      />

      <Field
        title={translate('common.labels.toComplete')}
        value={formatCurrency(initialPrevision.minus(budgetDeviation).minus(paid).toFixed(2))}
      />

      <Text className={styles.statsTitle}>{'Estatisticas'}</Text>

      <Text className={styles.info}>
        {`Informação relativo a m2 com a nota de que a área habitável é de ${METROS_QUADRADOS} m2`}
      </Text>

      <Field
        title={'Preço atual por m2'}
        value={formatCurrency(new BigNumber(paid).dividedBy(METROS_QUADRADOS).toFixed(2))}
      />

      <Field
        title={'Preço Previsto por m2'}
        value={formatCurrency(
          initialPrevision.minus(new BigNumber(budgetDeviation).toFixed(2)).dividedBy(METROS_QUADRADOS).toFixed(2)
        )}
      />
    </div>
  );
}
