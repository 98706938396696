/**
 * Module dependencies.
 */

import 'app/styles/antd-load.less';
import 'app/styles/globals.less';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { App } from './app';

/**
 * Container.
 */

const container = document.getElementById('root');

/**
 * Bridge buffer.
 */

if (!window.Buffer) {
  window.Buffer = require('buffer/').Buffer;
}

/**
 * Root.
 */

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

/**
 * Render.
 */

root.render(<App />);
