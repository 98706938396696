/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from 'app/routes';

/**
 * Logout.
 */

function logout(client: AxiosInstance): Promise<void> {
  return client.post(resolveAppUrl('logout')).then(({ data }) => data);
}

/**
 * Export `useLogout`.
 */

export function useLogout() {
  const client = useRequestProvider();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async () => {
      const result = await logout(client);

      navigate(appRoutes.home);

      return result;
    }
  });
}
