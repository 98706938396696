/**
 * Module dependencies.
 */

import { Modal as AntdModal } from 'antd';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styles from './modal.module.less';
/**
 * `Props` type.
 */

type Props = {
  className?: string;
  isOpen: boolean;
  buttons: ReactNode;
  isLoading: boolean;
  maskClosable?: boolean;
  children: JSX.Element;
  onClose?: () => void;
};

/**
 * Export `Modal` component.
 */

export function Modal(props: Props): JSX.Element {
  const { className, buttons, maskClosable, children, isLoading, onClose, isOpen } = props;

  return (
    <AntdModal
      className={classNames(styles.wrapper, className)}
      closable={!isLoading}
      confirmLoading={isLoading}
      footer={buttons}
      maskClosable={maskClosable}
      onCancel={onClose}
      open={isOpen}
    >
      {children}
    </AntdModal>
  );
}
