/**
 * Module dependencies.
 */

import { Transaction } from 'app/types/transaction';
import React from 'react';
import styles from './styles.module.less';
import moment from 'moment';
import { formatCurrency } from 'app/core/utils/formatter';

/**
 * `Props` type.
 */

type Props = {
  item: Transaction;
};

/**
 * Export `TransactionItem` component.
 */

export function TransactionItem({ item }: Props): JSX.Element {
  const logo = item.account.integration?.logo;

  return (
    <div className={styles.wrapper}>
      <div className={styles.bank}>
        {!!logo && <img className={styles.logo} src={logo} />}

        <div className={styles.amountWrapper}>
          <div className={styles.title}>{item.account.name}</div>

          <div className={styles.amount}>{formatCurrency(item.amount)}</div>
        </div>
      </div>

      <div className={styles.transaction}>
        <div className={styles.text}>{item.description ?? item.bank_description}</div>

        <div className={styles.text}>{moment(item.date ?? item.bank_date).format('DD-MM-YYYY')}</div>
      </div>
    </div>
  );
}
