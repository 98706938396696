/**
 * Module dependencies.
 */

import React, { ReactNode } from 'react';
import styles from './table.module.less';
import classNames from 'classnames';

/**
 * `Props` type.
 */

type Props<T> = {
  columns: Column<T>[];
};

/**
 * Export `Column` type.
 */

export type Column<T> = {
  title: string;
  size?: string;
  dataIndex?: string;
  key: string;
  render?: (item: T) => ReactNode;
};

/**
 * Export `Header` component.
 */

export function Header<T>({ columns }: Props<T>): JSX.Element {
  return (
    <div className={styles.headerWrapper}>
      {columns.map((item: any, index: number) => {
        const { title, key, style } = item;

        return (
          <div
            className={classNames(styles.headerColumn, {
              [styles.first]: index === 0,
              [styles.last]: index === columns.length - 1
            })}
            key={key}
            style={typeof style === 'function' ? style() : style}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
}
