/**
 * Module dependencies.
 */

import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { LoadHooksWidget } from '../form-types';
import { CrudForm } from '../crud-form';
import { set } from 'lodash';

/**
 * `Props` type.
 */

type Props = {
  formType: 'add' | 'edit';
  name: string;
  item: LoadHooksWidget;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * Export `LoadHooks` component.
 */

export function LoadHooks(props: Props): JSX.Element {
  const { form, item, formType } = props;
  const { hooks, formFields } = item as LoadHooksWidget;

  for (let i = 0; i < hooks.length; i++) {
    const hook = hooks[0];
    const result = hook.hook(...hook.hookProps);

    set(form, ['cache', 'hooks', hook.hookName], result);
  }

  return <CrudForm form={form} formFields={formFields} formType={formType} />;
}
