/**
 * Module dependencies.
 */

import React, { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  PieChartOutlined,
  SettingOutlined,
  AlertOutlined,
  MenuUnfoldOutlined,
  PicCenterOutlined,
  BorderLeftOutlined,
  AppstoreAddOutlined
} from '@ant-design/icons';

import { Outlet } from 'react-router-dom';
import { Dashboard } from './dashboard';
import { appRoutes } from 'app/routes';
import { useProjects } from 'app/hooks/requests/projects/use-projects';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { compileMenu } from 'app/core/utils/menu';
import { Project } from 'app/types/project';
import { MdPayment } from 'react-icons/md';

/**
 * Create project menu.
 */

function createProjectMenu(project: Project, translate: TFunction) {
  return [
    {
      key: `${project.id}_overview`,
      pathname: resolveAppPath(appRoutes.dashboard.projects.project.overview, { projectId: project.id }),
      icon: <PieChartOutlined />,
      label: translate('projects.menu.overview')
    },
    {
      key: `${project.id}_budgets`,
      pathname: resolveAppPath(appRoutes.dashboard.projects.project.budgets, { projectId: project.id }),
      icon: <PicCenterOutlined />,
      label: translate('projects.menu.budgets')
    },
    {
      key: `${project.id}_suppliers`,
      pathname: resolveAppPath(appRoutes.dashboard.projects.project.suppliers, { projectId: project.id }),
      icon: <AlertOutlined />,
      label: translate('projects.menu.suppliers')
    },
    {
      key: `${project.id}_proposals`,
      pathname: resolveAppPath(appRoutes.dashboard.projects.project.proposals, { projectId: project.id }),
      icon: <BorderLeftOutlined />,
      label: translate('projects.menu.proposals')
    },

    {
      key: `${project.id}_movements`,
      pathname: resolveAppPath(appRoutes.dashboard.projects.project.movements, { projectId: project.id }),
      icon: <MenuUnfoldOutlined />,
      label: translate('projects.menu.movements')
    },
    {
      key: `${project.id}_paymentTypes`,
      pathname: resolveAppPath(appRoutes.dashboard.projects.project.paymentTypes, { projectId: project.id }),
      icon: <MdPayment />,
      label: translate('projects.menu.paymentTypes')
    },
    {
      key: `${project.id}_settings`,
      pathname: resolveAppPath(appRoutes.dashboard.projects.project.settings, { projectId: project.id }),
      icon: <SettingOutlined />,
      label: translate('projects.menu.settings')
    }
  ];
}

/**
 * Export `SkinProjects` component.
 */

export function SkinProjects(): JSX.Element {
  const [translate] = useTranslation();
  const { data } = useProjects();
  const items = useMemo(
    () =>
      compileMenu([
        {
          key: 'overview',
          pathname: appRoutes.dashboard.projects.base,
          icon: <PieChartOutlined />,
          label: translate('settings.menu.overview')
        },
        ...(data?.map(item => ({
          key: `project_${item.id}`,
          pathname: undefined,
          icon: <AppstoreAddOutlined />,
          label: item.name,
          children: createProjectMenu(item, translate)
        })) ?? [])
      ]),
    [data, translate]
  );

  return (
    <Dashboard menu={items}>
      <Outlet />
    </Dashboard>
  );
}
