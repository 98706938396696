/**
 * Module dependencies.
 */

import { Route } from 'react-router-dom';
import { CrudTemplate } from './form-types';
import { CrudList } from './crud-list';
import { CrudAdd } from './crud-add';
import { CrudEdit } from './crud-edit';
import React from 'react';
import { TFunction } from 'i18next';
import { identity, merge, pick, values } from 'lodash';

/**
 * Get props from form.
 */

export function getPropsFromForm(...fields: string[]) {
  return (formValues: any) => {
    return values(pick(formValues, fields));
  };
}

/**
 * Export `listCrudRoutes`.
 */

export function listCrudRoutes(templateConfig: CrudTemplate) {
  return (
    <>
      {templateConfig.list && (
        <Route
          Component={() => <CrudList template={templateConfig} />}
          id={templateConfig.list.route}
          path={templateConfig.list.route}
        />
      )}

      {templateConfig.add && (
        <Route
          Component={() => <CrudAdd template={templateConfig} />}
          id={templateConfig.add.route}
          path={templateConfig.add.route}
        />
      )}

      {templateConfig.edit && (
        <Route
          Component={() => <CrudEdit template={templateConfig} />}
          id={templateConfig.edit.route}
          path={templateConfig.edit.route}
        />
      )}
    </>
  );
}

/**
 * Normalize initial values.
 */

function normalizeInitialValues(keys: string[], payload: any) {
  return payload;
}

/**
 * Normalize Template.
 */

export function normalizeTemplate(translate: TFunction, template: CrudTemplate) {
  if (template.edit) {
    template.edit = merge(
      {},
      {
        submitLabel: translate('common.actions.edit'),
        redirect: -1,
        endpoint: template.list?.endpoint ? `${template.list?.endpoint}/\${id}` : null,
        route: `${template.list?.route}/edit/:id`,
        normalizePayload: identity,
        normalizeInitialValues: normalizeInitialValues
      },
      template.edit
    );
  }

  if (template.list) {
    template.list = merge(
      {},
      {
        addButton: !template.add ? null : translate('common.actions.add'),
        endpointById: template.list.endpoint ? `${template.list.endpoint}/\${id}` : null,
        normalizeFilters: (data: any) => data,
        canEdit: true,
        canRemove: true
      },
      template.list
    );
  }

  if (template.add) {
    template.add = merge(
      {},
      {
        endpoint: template.list?.endpoint,
        redirect: -1,
        route: `${template.list?.route}/add`,
        submitLabel: translate('common.actions.add'),
        normalizePayload: identity
      },
      template.add
    );
  }

  if (template.remove) {
    template.remove = merge(
      {},
      {
        endpoint: `${template.list?.endpoint}/\${id}`
      },
      template.remove
    );
  }

  return template;
}
