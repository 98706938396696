/**
 * Module dependencies.
 */

import classNames from 'classnames';
import { reduce } from 'lodash';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './table.module.less';
import React from 'react';
import { Select } from 'app/components/atoms/forms/fields/select/select';

/**
 * `Pagination` type.
 */

export type Pagination = {
  total: number;
  pageSize: number;
  pageSizeOptions: number[];
};

/**
 * `Props` type.
 */

type Props = {
  pagination: Pagination;
  columnsLength: number;
  changePageSize?: (value: number) => void;
};

/**
 * Export `TablePagination` component.
 */

export function TablePagination(props: Props): JSX.Element {
  const { pagination, columnsLength, changePageSize } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') ?? '1', 10);
  const { total, pageSize } = pagination;
  const page = parseInt(searchParams.get('page') ?? '1', 10);
  const pages = Math.ceil(total / pageSize);
  const items = useMemo(() => {
    const result = Array.from({ length: pages }, (_, i) => i + 1);

    return reduce(
      result,
      (acc: any, item: any) => {
        if (item < 4) {
          return [...acc, item];
        }

        if (item > currentPage - 2 && item < currentPage + 2) {
          return [...acc, item];
        }

        if (item >= result.length - 2) {
          return [...acc, item];
        }

        if (item >= 5 && item <= result.length - 3 && acc[acc.length - 1] !== '...') {
          return [...acc, '...'];
        }

        return acc;
      },
      []
    );
  }, [currentPage, pages]);

  const changePage = (page: number) => {
    setSearchParams(params => {
      params.set('page', page.toString());

      return params;
    });
  };

  const back = () => {
    setSearchParams(params => {
      params.set('page', page === 1 ? '1' : String(page - 1));

      return params;
    });
  };

  const next = () => {
    setSearchParams(params => {
      const pages = Math.ceil(total / pageSize);

      params.set('page', page > pages ? String(pages) : String(page + 1));

      return params;
    });
  };

  const first = () => {
    setSearchParams(params => {
      params.set('page', '1');

      return params;
    });
  };

  const last = () => {
    const pages = Math.ceil(total / pageSize);

    setSearchParams(params => {
      params.set('page', String(pages));

      return params;
    });
  };

  return (
    <div
      className={styles.pagination}
      style={{
        gridColumn: `1 / ${columnsLength + 1}`
      }}
    >
      {items.length > 1 && (
        <>
          {page > 1 && (
            <>
              <div className={classNames(styles.item, styles.isPress)} onClick={back}>
                {'<'}
              </div>

              <div className={classNames(styles.item, styles.isPress)} onClick={first}>
                {'<<'}
              </div>
            </>
          )}

          {items.map((item: any) => {
            const isPress = !isNaN(parseInt(item, 10));

            return (
              <div
                className={classNames(styles.item, {
                  [styles.currentPage]: currentPage === item,
                  [styles.isPress]: isPress
                })}
                key={item}
                onClick={
                  !isPress
                    ? undefined
                    : () => {
                        changePage(item);
                      }
                }
              >
                {item}
              </div>
            );
          })}

          {page < pages && (
            <>
              <div className={classNames(styles.item, styles.isPress)} onClick={next}>
                {'>'}
              </div>

              <div className={classNames(styles.item, styles.isPress)} onClick={last}>
                {'>>'}
              </div>
            </>
          )}
        </>
      )}

      {!!changePageSize && (
        <Select
          className={styles.selectPageSize}
          name={'pageOptions'}
          onChange={changePageSize}
          options={pagination.pageSizeOptions.map(item => ({
            label: String(item),
            value: item
          }))}
          size={'small'}
          value={pagination.pageSize}
        />
      )}
    </div>
  );
}
