/**
 * Module dependencies.
 */

import { useProject } from 'app/hooks/requests/projects/use-project';
import { useParams } from 'react-router-dom';
import React from 'react';

/**
 * Export `RenderIva` component.
 */

export function RenderIva(): JSX.Element {
  const params = useParams();
  const { data, isLoading } = useProject(params.projectId);

  if (!data || isLoading) {
    return <></>;
  }

  return <>{`${data.defaultIVA}%`}</>;
}
