/**
 * Module dependencies.
 */

import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { Checkbox, CheckboxProps } from './checkbox';
import React, { ForwardedRef, forwardRef } from 'react';

/**
 * `Props` type.
 */

type Props<T extends FieldValues> = UseControllerProps<T> &
  CheckboxProps & {
    normalizeValue?: (newValue: string, value: string) => string;
  };

/**
 * `CheckboxFieldComponent` component.
 */

export function CheckboxFieldComponent<T extends FieldValues>(
  props: Props<T>,
  forwardedRef: ForwardedRef<any>
): JSX.Element {
  const { control, defaultValue, name, normalizeValue, rules, shouldUnregister, ...inputProps } = props;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { name, onChange, value }, fieldState: { error, invalid, isDirty, isTouched } }) => {
        return (
          <Checkbox
            invalid={invalid}
            isDirty={isDirty}
            isTouched={isTouched}
            name={name}
            value={value}
            {...inputProps}
            error={error}
            onChange={newValue => {
              const finalValue = normalizeValue
                ? normalizeValue(String(newValue), value)
                : (newValue?.nativeEvent?.target as any).checked;

              onChange(finalValue);
            }}
            ref={forwardedRef}
          />
        );
      }}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  );
}

/**
 * Export `CheckboxField`.
 */

export const CheckboxField = forwardRef<Props<any>, any>(CheckboxFieldComponent);
