/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';

/**
 * Backup.
 */

function backup(client: AxiosInstance): Promise<void> {
  return client.post(resolveAppUrl('backup')).then(({ data }) => data);
}

/**
 * Export `useBackup`.
 */

export function useBackup() {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async () => {
      const result = await backup(client);

      return result;
    }
  });
}
