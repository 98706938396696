/**
 * Module dependencies.
 */

import { Table } from 'app/components/organisms/table/table';
import { formatCurrency } from 'app/core/utils/formatter';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './overview.module.less';

/**
 * `Props` type.
 */

type Props = {
  item: any;
};

/**
 * Currency Options.
 */

const currencyOptions = { locale: 'en', decimalPlacesToDisplay: 6 };

/**
 * Export `ExpandedRow` component.
 */

export function ExpandedRow({ item }: Props): JSX.Element | null {
  const [translate] = useTranslation();
  const columns = useMemo(
    () => [
      {
        title: translate('common.table.columns.provider'),
        key: 'provider'
      },
      {
        title: translate('common.table.columns.wallet'),
        key: 'wallet'
      },
      {
        title: translate('common.table.columns.amount'),
        key: 'amount'
      },
      {
        title: translate('common.table.columns.valueUSDT'),
        style: { justifyContent: 'flex-end' },
        key: 'valueUSDT',
        render: (item: any) => formatCurrency(item.valueUSDT, { currency: 'USD', ...currencyOptions })
      },
      {
        title: translate('common.table.columns.valueEUR'),
        style: { justifyContent: 'flex-end' },
        key: 'valueEUR',
        render: (item: any) => formatCurrency(item.valueEUR, { currency: 'EUR', ...currencyOptions })
      }
    ],
    [translate]
  );

  return (
    <Table
      columns={columns}
      dataSource={item.item.positions}
      rowKey={'name'}
      wrapperClassName={styles.wrapperClassName}
    />
  );
}
