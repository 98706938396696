/**
 * Module dependencies.
 */

import axios, { AxiosInstance } from 'axios';

/**
 * Export `createAxiosClient`.
 */

export function createAxiosClient(): AxiosInstance {
  return axios.create();
}
