/**
 * Module dependencies.
 */

import { InputField } from 'app/components/atoms/forms/fields/input/input-field';
import { Control, FieldValues } from 'react-hook-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { requiredRule } from 'app/core/utils/field-rules';
import styles from './local-connector.module.less';

/**
 * `Props` type.
 */

type Props = {
  showPin: boolean;
  control: Control<FieldValues, any>;
};

/**
 * Export `LocalConnector` component.
 */

export function LocalConnector({ control, showPin }: Props): JSX.Element | null {
  const [translate] = useTranslation();

  return (
    <div className={styles.wrapper}>
      <InputField
        control={control}
        disabled={showPin}
        label={translate('common.labels.username')}
        name={'username'}
        rules={requiredRule(translate)}
      />

      <InputField
        control={control}
        disabled={showPin}
        label={translate('common.labels.password')}
        name={'password'}
        rules={requiredRule(translate)}
        type={'password'}
      />

      {showPin && (
        <InputField
          control={control}
          label={translate('common.labels.pin')}
          name={'pin'}
          rules={requiredRule(translate)}
          type={'pin'}
        />
      )}
    </div>
  );
}
