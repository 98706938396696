/**
 * Module dependencies.
 */

import {
  CrudAddType,
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  FormList,
  ListColumns
} from 'app/components/organisms/crud/form-types';

import { normalizeTemplate } from 'app/components/organisms/crud/utils';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import { requiredRule } from 'app/core/utils/field-rules';
import { formatCurrency } from 'app/core/utils/formatter';
import { useCoins } from 'app/hooks/requests/coins/use-coins';
import { appRoutes } from 'app/routes';
import { BankAccount } from 'app/types/bank-account';
import { Coin } from 'app/types/coin';
import { TFunction } from 'i18next';
import React from 'react';
import { pick } from 'lodash';
import BigNumber from 'bignumber.js';
import { ReconnectControl } from './reconnect-control';
import { createSearchParams } from 'react-router-dom';
import { Wrapper } from './wrapper';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'id',
    size: '30px',
    key: 'id'
  },
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'name',
    size: '1fr',
    key: 'name'
  },
  {
    title: translate('common.table.columns.coin'),
    size: '50px',
    render: (item: BankAccount) => `${item.coin.name}`,
    key: 'coin'
  },
  {
    title: translate('common.table.columns.isCreditCard'),
    style: { justifyContent: 'flex-end' },
    render: (item: BankAccount) => (item.isCreditCard ? '✅' : '❌'),
    key: 'creditCard'
  },
  {
    title: translate('common.table.columns.balance'),
    style: { justifyContent: 'flex-end' },
    styleRow: { fontFamily: 'Oswald' },
    render: (item: BankAccount) => formatCurrency(item.balance),
    key: 'balance'
  }
];

/**
 * Form list.
 */

const formFields = (translate: TFunction, values?: any): FormList => [
  {
    type: 'inputField',
    name: 'name',
    label: translate('common.labels.name'),
    rules: requiredRule(translate)
  },
  {
    type: 'checkBoxField',
    name: 'isCreditCard',
    label: translate('common.labels.isCreditCard'),
    rules: undefined
  },
  {
    type: 'inputNumberField',
    disabled: !!values?.hasConnected,
    name: 'initialBalance',
    label: translate('common.labels.initialBalance'),
    rules: requiredRule(translate)
  },
  {
    type: 'inputNumberField',
    disabled: !!values?.hasConnected,
    name: 'balance',
    label: translate('common.labels.balance'),
    rules: requiredRule(translate)
  },
  {
    type: 'selectField',
    name: 'coinId',
    label: translate('common.labels.coin'),
    rules: requiredRule(translate),
    options: {
      hook: useCoins,
      normalize: (items: Coin[]) =>
        items?.map(item => ({
          value: item.id,
          label: item.name
        }))
    }
  }
];

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType => ({
  formFields: formFields(translate),
  normalizePayload: data => ({
    ...data,
    initialBalance: new BigNumber(data.initialBalance).toString(),
    balance: new BigNumber(data.balance).toString(),
    isCreditCard: !!data.isCreditCard
  })
});

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType => ({
  formFields: values => formFields(translate, values),
  normalizePayload: data => ({
    ...pick(data, ['coinId', 'id', 'name']),
    initialBalance: new BigNumber(data.initialBalance).toString(),
    balance: new BigNumber(data.balance).toString(),
    isCreditCard: !!data.isCreditCard
  })
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType => ({});

/**
 * List.
 */

const list = (translate: TFunction): CrudListType => ({
  columns: listColumns(translate),
  route: appRoutes.dashboard.bankAccounts.accounts.base,
  clickLine: (item: any, params: { navigate: any }) => {
    const query = {
      filter: JSON.stringify({ account: item.id }),
      filterOpened: true
    };

    params.navigate({
      pathname: appRoutes.dashboard.bankAccounts.movementsManager.base,
      search: `?${createSearchParams(query as any)}`
    });
  },
  key: ['bankAccounts'],
  endpoint: apiEndpoints.bankAccount,
  renderActions: (item: any) => {
    return <ReconnectControl item={item} />;
  },
  WrapperComponent: Wrapper
});

/**
 * Config.
 */

export function createBankAccountsTemplate(translate: TFunction): CrudTemplate {
  return normalizeTemplate(translate, {
    add: add(translate),
    edit: edit(translate),
    list: list(translate),
    remove: remove()
  });
}
