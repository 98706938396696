/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useProjectsCacheInvalidator } from './use-projects';

/**
 * Remove project.
 */

function removeProject(client: AxiosInstance, projectId: string): Promise<void> {
  return client.delete(resolveAppUrl('projectById', { interpolations: { projectId } })).then(({ data }) => data);
}

/**
 * Export `useDeleteProject`.
 */

export function useDeleteProject() {
  const client = useRequestProvider();
  const invalidator = useProjectsCacheInvalidator();

  return useMutation({
    mutationFn: async (projectId: string) => {
      const result = await removeProject(client, projectId);

      await invalidator();

      return result;
    }
  });
}
