/**
 * Module dependencies.
 */

import { HookConditionResponse } from 'app/components/organisms/crud/form-types';
import { useBankAccount } from 'app/hooks/requests/bank-accounts/use-bank-account';
import { FieldValues, UseFormReturn } from 'react-hook-form';

/**
 * Export `useCheckAccount`.
 */

export function useCheckAccount(
  form: UseFormReturn<FieldValues, any>,
  formType: 'add' | 'edit'
): HookConditionResponse<'idle' | 'valid' | 'invalid'> {
  const { watch } = form;
  const accountId = watch('accountId');
  const { data } = useBankAccount(accountId);

  if (formType === 'edit') {
    return { data: 'valid' };
  }

  if (!accountId) {
    return { data: 'idle' };
  }

  return {
    // TODO: enable transactions to fix balances.
    data: data?.hasConnected && false ? 'invalid' : 'valid'
  };
}
