/**
 * Module dependencies.
 */

import { HookConditionResponse } from 'app/components/organisms/crud/form-types';
import { useBankAccount } from 'app/hooks/requests/bank-accounts/use-bank-account';
import { FieldValues, UseFormReturn } from 'react-hook-form';

/**
 * Export `useCheckConnectedAccount`.
 */

export function useCheckConnectedAccount(
  form: UseFormReturn<FieldValues, any>
): HookConditionResponse<'idle' | 'valid' | 'invalid'> {
  const { watch } = form;
  const accountId = watch('targetAccountId');
  const { data } = useBankAccount(accountId);

  if (!accountId) {
    return { data: 'idle' };
  }

  return {
    data: data?.hasConnected ? 'valid' : 'invalid'
  };
}
