/**
 * Module dependencies.
 */

import { initReactI18next } from 'react-i18next';
import { resources } from './resources';
import LanguageDetector from 'i18next-browser-languagedetector';
import config from 'config';
import i18n from 'i18next';

/**
 * Localization.
 */

const localization = config.localization;

/**
 * I18n.
 */

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV !== 'production',
    defaultNS: 'common',
    fallbackLng: localization.fallbackLanguage,
    initImmediate: false,
    interpolation: {
      escapeValue: false
    },
    lowerCaseLng: true,
    ns: ['common'],
    react: {
      useSuspense: false
    },
    resources,
    supportedLngs: localization.languages
  });

/**
 * Export `i18n`.
 */

export default i18n;
