/**
 * Module dependencies.
 */

import { Popconfirm } from 'antd';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { useCrudDelete } from 'app/hooks/requests/crud/use-crud-delete';
import { useCrudList } from 'app/hooks/requests/crud/use-crud-list';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CrudTemplate } from './form-types';
import styles from './crud.module.less';
import { Table } from 'app/components/organisms/table/table';
import { Button } from 'app/components/atoms/button/button';
import { CrudFilters } from './form-filters/crud-filters';
import { Wrapper } from 'app/components/atoms/wrapper/wrapper';
import { Loading } from 'app/components/atoms/loading/loading';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useDebounceLoading } from 'app/hooks/use-debounce';
import classnames from 'classnames';

/**
 * `Props` type.
 */

type Props = {
  template: CrudTemplate;
  nestedParams?: any;
};

/**
 * `ExpandedRow` component.
 */

function ExpandedRow({ extras, item, expanded }: { item: any; extras: any; expanded: boolean }) {
  const list = extras.template.list;
  const columns = list.expandedColumns;
  const rowKey = list.expandedRowKey ?? 'id';
  const dataKey = list.expandedDataKey;
  const data = useMemo(() => {
    if (typeof dataKey === 'function') {
      return dataKey(item);
    }

    return item[dataKey];
  }, [dataKey, item]);

  if (!expanded) {
    return null;
  }

  return <Table className={styles.table} columns={columns} dataSource={data} rowKey={rowKey} />;
}

/**
 * `ExpandedRowTable` component.
 */

function ExpandedRowTable({ extras, item, expanded }: { item: any; extras: any; expanded: boolean }) {
  const template = extras.template.list.expandedTemplate;
  const localList = !template.list.endpoint;
  const nestedParams = useMemo(() => {
    return {
      ...(template.list?.nestedParams?.(item) ?? {}),
      ...(localList ? { item } : {})
    };
  }, [item, localList, template.list]);

  if (!expanded) {
    return null;
  }

  if (!localList && !nestedParams) {
    throw new Error('`nestedParams` is required for `expandedTemplate`');
  }

  return (
    <div className={styles.nestedTable}>
      <CrudList nestedParams={nestedParams} template={template} />
    </div>
  );
}

/**
 * Export `CrudList` component.
 */

export function CrudList<T>({ template, nestedParams }: Props): JSX.Element {
  const [translate] = useTranslation();
  const routerParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = useMemo(
    () => ({
      ...routerParams,
      ...(nestedParams ?? {})
    }),
    [nestedParams, routerParams]
  );

  const [pageSize, changePageSize] = useState(template.list?.pagination?.pageSize ?? 10);
  const lastTotal = useRef<number | undefined>();
  const { mutate: deleteAction, isLoading } = useCrudDelete(template, params);
  const [query, setQuery] = useState<any>();
  const { data, refetch, ...restQuery } = useCrudList<T>(template, params, query);
  const appendFilters = useCallback(
    (values: any) => {
      const query = {
        ...(template.list?.append ?? {}),
        ...values
      };

      if (template.list?.pagination) {
        query.page = searchParams.get('page') ?? 1;
        query.pageSize = pageSize;
      }

      query.execute = true;

      setQuery((data: any) => {
        if (JSON.stringify(data) === JSON.stringify(query)) {
          return data;
        }

        return query;
      });
    },
    [pageSize, searchParams, template.list?.append, template.list?.pagination]
  );

  const page = searchParams.get('page') ?? 1;
  const isLoadingData = useDebounceLoading(restQuery.isFetching, 50, 350);

  useEffect(() => {
    if (!(data as any)?.total) {
      return;
    }

    if (!lastTotal.current) {
      lastTotal.current = (data as any).total;

      return;
    }
    if (lastTotal.current !== (data as any)?.total) {
      lastTotal.current = (data as any).total;

      setSearchParams(params => {
        params.set('page', '1');

        return params;
      });
    }
  }, [data, setSearchParams]);

  useEffect(() => {
    setQuery((query: any) => ({
      ...query,
      page
    }));
  }, [page, refetch]);

  useEffect(() => {
    if (query) {
      refetch();
    }
  }, [query, refetch]);

  const columns = useMemo(() => {
    const columns = [...(template.list?.columns ?? [])];

    columns.push({
      title: translate('common.table.columns.actions'),
      key: 'actions',
      style: columns?.[0].style,
      render: (item: any) => {
        const _canEdit =
          typeof template.list?.canEdit === 'function' ? template.list?.canEdit(item) : template.list?.canEdit;
        const _canRemove =
          typeof template.list?.canRemove === 'function' ? template.list?.canRemove(item) : template.list?.canRemove;

        return (
          <div className={styles.actions}>
            {_canEdit && template?.edit && (
              <Link
                onClick={event => {
                  event.stopPropagation();
                }}
                to={resolveAppPath(template.edit?.route as any, { ...(params ?? {}), id: item.id })}
              >
                <Button icon={<EditOutlined />} size={'small'} />
              </Link>
            )}

            {_canRemove && template.remove && (
              <Popconfirm
                okButtonProps={{ loading: isLoading }}
                {...{ onClick: (event: any) => event.stopPropagation() }}
                onConfirm={(event: any) => {
                  event.stopPropagation();
                  deleteAction(item.id);
                }}
                title={translate('removeAction')}
              >
                <Button danger icon={<DeleteOutlined />} size={'small'} />
              </Popconfirm>
            )}

            {template.list?.renderActions?.(item, template, refetch)}
          </div>
        );
      }
    });

    return columns;
  }, [deleteAction, isLoading, params, refetch, template, translate]);

  const WrapperComponent = template.list?.WrapperComponent ?? Wrapper;
  const expandedRowRender = useMemo(() => {
    if (template?.list?.expandedColumns) {
      return ExpandedRow;
    }

    if (template?.list?.expandedTemplate) {
      return ExpandedRowTable;
    }
  }, [template?.list?.expandedColumns, template?.list?.expandedTemplate]);

  const onClickLine = useMemo(() => {
    if (!template.list?.clickLine) {
      return undefined;
    }

    return (item: any) => {
      template?.list?.clickLine?.(item, { navigate });
    };
  }, [navigate, template.list]);

  return (
    <WrapperComponent
      className={styles.wrapper}
      isLoading={restQuery.isLoading || restQuery.isFetching}
      refetch={refetch}
      template={template}
    >
      <CrudFilters
        appendFilters={appendFilters}
        hasNested={!!nestedParams}
        params={params}
        refresh={refetch}
        template={template}
      />

      <Loading isLoading={isLoading}>
        <Table
          changePageSize={changePageSize}
          className={styles.table}
          columns={columns}
          dataSource={(data as any)?.results ?? []}
          expandedExtras={{ template }}
          expandedRowRender={expandedRowRender}
          footer={template?.list?.renderLastLine?.({
            data: (data as any)?.results,
            total: (data as any)?.total,
            columns,
            template,
            refetch
          })}
          isLoading={isLoadingData}
          onClickLine={onClickLine}
          pagination={
            template.list?.pagination && {
              total: (data as any)?.total,
              pageSize: pageSize,
              pageSizeOptions: template.list?.pagination?.pageSizeOptions
            }
          }
          rowKey={'id'}
          style={template.list?.style}
          wrapperClassName={classnames({
            [styles.nestedTable]: !!nestedParams
          })}
        />
      </Loading>
    </WrapperComponent>
  );
}
