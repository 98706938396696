/**
 * Module dependencies.
 */

import { Typography } from 'antd';
import classnames from 'classnames';
import React from 'react';
import styles from './text.module.less';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  children?: React.ReactElement | string;
};

/**
 * Export `Text` component.
 */

export function Text({ children, className }: Props): JSX.Element {
  return <Typography.Text className={classnames(styles.wrapper, className)}>{children}</Typography.Text>;
}

/**
 * Export `textComponents`.
 */

export const textComponents = {
  bold: <Text className={styles.bold} />,
  regular: <Text />
};
