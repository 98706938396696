/**
 * Module dependencies.
 */

import { findIndex, dropWhile, size, takeWhile, toUpper } from 'lodash';
import BigNumber from 'bignumber.js';

/**
 * Maximum decimal places.
 */

const maximumDecimalPlaces = 8;

/**
 * `NumericValue` type.
 */

type NumericValue = number | string | BigNumber;

/**
 * `NullableNumber` type.
 */

type NullableNumber = NumericValue | null | undefined;

/**
 * `NumberOptions` type.
 */

type NumberOptions = Intl.NumberFormatOptions & {
  currency?: string;
  decimalPlacesToDisplay?: number | null;
  locale: string;
  skipTrailingZeros?: boolean | null;
  toRoundUp?: boolean;
};

/**
 * Export `CurrencyOptions` type.
 */

export type CurrencyOptions = NumberOptions & {
  fallbackSymbol?: string | null;
  toRoundUp?: boolean;
};

/**
 * Default options.
 */

const defaultOptions: CurrencyOptions = {
  currency: 'eur',
  locale: 'en'
};

/**
 * Export `roundDown`.
 */

export function roundDown(value: NumericValue, decimalPlaces?: number): string {
  return new BigNumber(value).toFixed(decimalPlaces ?? 2, BigNumber.ROUND_FLOOR);
}

/**
 * Export `roundUp`.
 */

export function roundUp(value: NumericValue | undefined, decimalPlaces?: number): string {
  if (!value) {
    return '0';
  }

  return new BigNumber(value).toFixed(decimalPlaces ?? 2, BigNumber.ROUND_CEIL);
}

/**
 * Convert number to string.
 */

function convertNumberToString(value: NullableNumber): string {
  const parsedValue = new BigNumber(value ?? NaN);

  return parsedValue.isNaN() ? '0' : parsedValue.toString(10);
}

/**
 * Currency formatter.
 */

function currencyFormatter(options: NumberOptions): Intl.NumberFormat {
  const { decimalPlacesToDisplay, locale, skipTrailingZeros, ...rest } = options;

  const maximumFractionDigits = Math.min(decimalPlacesToDisplay ?? 2, maximumDecimalPlaces);

  return new Intl.NumberFormat(locale, {
    ...rest,
    maximumFractionDigits,
    minimumFractionDigits: skipTrailingZeros ? undefined : maximumFractionDigits
  });
}

/**
 * Export `formatNumber`.
 */

export function formatNumber(value: string, options: NumberOptions): Intl.NumberFormatPart[] {
  const formatter = currencyFormatter(options);
  const round = options.toRoundUp ? roundUp : roundDown;
  const [integer, decimal] = value.split('.');
  const integerParts = formatter.formatToParts(parseFloat(integer));
  const decimalParts = formatter.formatToParts(
    parseFloat(round(`0.${decimal ?? '0'}`, options.decimalPlacesToDisplay ?? 2))
  );

  return [
    ...takeWhile<Intl.NumberFormatPart>(integerParts, ({ type }) => type !== 'decimal'),
    ...dropWhile<Intl.NumberFormatPart>(decimalParts, ({ type }) => type !== 'decimal')
  ];
}

/**
 * Organize entities.
 */

const organizeEntities = (data: any[]) => {
  const item = findIndex(data, item => item.type === 'currency');
  const currency = data.splice(item, 1);
  const result = [...data, ...currency];

  return result.map(item => {
    switch (item.type) {
      case 'minusSign':
        item.value = `${item.value} `;
        return item;

      case 'currency':
        item.value = ` ${item.value}`;
        return item;
      default:
        return item;
    }
  });
};

/**
 * Export `formatCurrency`.
 */

export function formatCurrency(rawValue: NullableNumber, options: CurrencyOptions = defaultOptions): string {
  const { currency, decimalPlacesToDisplay, fallbackSymbol, locale, skipTrailingZeros, toRoundUp } = options;
  const value = convertNumberToString(rawValue ?? '0');

  try {
    const result = formatNumber(value, {
      currency,
      decimalPlacesToDisplay,
      locale,
      skipTrailingZeros,
      style: currency ? 'currency' : 'decimal'
    });

    return organizeEntities(result)
      .map(({ value }) => value)
      .join('');
  } catch (error: any) {
    const formatter = new Intl.NumberFormat(locale);
    const formattedValue = formatter.format(Number(value));
    const currencySymbol = fallbackSymbol ?? toUpper(currency);
    const whitespace = size(currencySymbol) > 1 ? ' ' : '';
    const fallback = !currencySymbol ? formattedValue : `${currencySymbol}${whitespace}${formattedValue}`;

    if (!error.toString().includes('currency code')) {
      return fallback;
    }

    try {
      // Unsupported currencies are treated as BTC, then the symbol is swapped.
      const formattedParts = formatNumber(value, {
        currency: 'BTC',
        decimalPlacesToDisplay,
        locale,
        skipTrailingZeros,
        style: 'currency',
        toRoundUp
      });

      return formattedParts
        .map(({ value }) => value)
        .join('')
        .replace(/^BTC */, `${currencySymbol} ${whitespace}`)
        .replace(/^-BTC */, `${currencySymbol} ${whitespace}`)
        .replace(/ *BTC$/, `${whitespace} ${currencySymbol}`);
    } catch (error) {
      return fallback;
    }
  }
}
