/**
 * Module dependencies.
 */

import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { InputFormItem } from '../form-types';
import { CheckboxField } from 'app/components/atoms/forms/fields/checkbox/checkbox-field';

/**
 * `Props` type.
 */

type Props = {
  name: string;
  item: InputFormItem;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * Export `FormCheckBoxField` component.
 */

export function FormCheckBoxField(props: Props): JSX.Element {
  const { form, item } = props;
  const { control } = form;
  const { name, placeholder, disabled, label, rules } = item;

  return (
    <CheckboxField
      control={control}
      disabled={disabled}
      htmlType={'number'}
      label={label}
      name={name}
      placeholder={placeholder ?? placeholder === false ? undefined : label}
      rules={rules}
    />
  );
}
