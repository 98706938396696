/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { RequestOptions } from 'app/types/network';

/**
 * `MovementsOverview` type.
 */

type MovementsOverview = {
  incomeVsExpenses: any;
  budgetGraph: any;
};

/**
 * Get movements overview.
 */

function getMovementsOverview(
  client: AxiosInstance,
  monthIndex: number | undefined,
  options?: RequestOptions
): Promise<MovementsOverview> {
  return client
    .get(
      resolveAppUrl('movementsOverview', {
        params: {
          ...(options ?? {}),
          monthIndex
        }
      })
    )
    .then(({ data }) => data?.data);
}

/**
 * Export `useBudgetsInvalidator`.
 */

export function useBudgetsInvalidator() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(['budgets']);
  }, [queryClient]);
}

/**
 * Export `useMovementsOverview`.
 */

export function useMovementsOverview(monthIndex: number | undefined, options?: RequestOptions) {
  const client = useRequestProvider();

  return useQuery(['movementsOverview'], {
    queryFn: async () => {
      return await getMovementsOverview(client, monthIndex, options);
    }
  });
}
