/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { EndpointKey, EndpointValue, resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { RequestOptions } from 'app/types/network';
import { Transaction } from 'app/types/transaction';
import { CrudTemplate } from 'app/components/organisms/crud/form-types';

/**
 * Get crud by id.
 */

function getCrudById(
  client: AxiosInstance,
  apiEndpoint: EndpointKey | EndpointValue,
  params: any,
  options?: RequestOptions
): Promise<Transaction> {
  return client
    .get(resolveAppUrl(apiEndpoint, { interpolations: params, params: options }))
    .then(({ data }) => data?.data);
}

/**
 * Export `useCrudById`.
 */

export function useCrudById(
  template: CrudTemplate,
  params: any | undefined,
  options?: RequestOptions,
  queryOptions = {}
) {
  const client = useRequestProvider();

  return useQuery([template.list?.key ?? ['temp'], params], {
    queryFn: async () => {
      if (!params || !template.list?.endpointById) {
        throw new Error('Crud By ID ERROR. No endpoint by id.');
      }

      return await getCrudById(client, template.list.endpointById, params, options);
    },
    ...queryOptions
  });
}
