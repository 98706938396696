/**
 * Module dependencies.
 */

import { Popconfirm } from 'antd';
import { Button } from 'app/components/atoms/button/button';
import { Title } from 'app/components/atoms/title/title';
import { CrudForm } from 'app/components/organisms/crud/crud-form';
import { useCrudEdit } from 'app/hooks/requests/crud/use-crud-edit';
import { useDeleteProject } from 'app/hooks/requests/projects/use-delete-project';
import { appRoutes } from 'app/routes';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createProjectsTemplate } from '../../overview/form-config';
import { useCrudById } from 'app/hooks/requests/crud/use-crud-by-id';
import { omit } from 'lodash';

/**
 * Export `ProjectSettings` component.
 */

export function ProjectSettings(): JSX.Element {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const params = useParams();
  const [translate] = useTranslation();
  const template = createProjectsTemplate(translate);
  const { data } = useCrudById(template, { id: params.projectId });
  const { mutate: deleteAction, isLoading } = useDeleteProject();
  const deleteProject = useCallback(() => {
    try {
      if (!projectId) {
        return;
      }

      deleteAction(projectId);

      navigate(appRoutes.dashboard.projects.base);
    } catch (e) {
      // Nothing to do.
    }
  }, [deleteAction, projectId, navigate]);

  const form = useForm({
    values: data,
    mode: 'all'
  });

  const { mutate: edit } = useCrudEdit(template, { id: params.projectId });
  const onSubmit = async (values: any) => {
    await edit(
      omit(
        {
          ...data,
          ...values,
          id: params.projectId
        },
        ['userId']
      ),
      {
        onSuccess: () => null
      }
    );
  };

  return (
    <div>
      <Title>{translate('projects.settings.title')}</Title>

      <CrudForm
        form={form as any}
        formFields={template?.edit?.formFields as any}
        formType={'edit'}
        onSubmit={onSubmit as any}
        submitLabel={translate('common.actions.save')}
      />

      <div style={{ height: 16 }} />

      <Popconfirm
        okButtonProps={{ loading: isLoading }}
        {...{ onClick: (event: any) => event.stopPropagation() }}
        onConfirm={deleteProject}
        title={translate('removeAction')}
      >
        <Button danger>{translate('projects.settings.delete')}</Button>
      </Popconfirm>
    </div>
  );
}
