// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.budgets-module__consumed--KJCst {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.budgets-module__consumed--KJCst .budgets-module__percentage--qJ0ve {
  background-color: white;
  color: #444;
  margin-left: 16px;
  padding: 4px 12px;
  min-width: 80px;
  text-align: center;
  border-radius: 16px;
}
.budgets-module__isIncome--G6cFh {
  color: #82c982;
  font-weight: 500;
}
.budgets-module__budgetLabel--XC6Kl.budgets-module__isCurrency--ES6jS {
  font-family: 'Oswald';
}
.budgets-module__budgetLabel--XC6Kl.budgets-module__noBreak--ku912 {
  white-space: nowrap;
}
.budgets-module__budgetValues--mARfV {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 4px 12px;
  border-radius: 16px;
}
.budgets-module__budgetValues--mARfV .budgets-module__left--xaYYZ,
.budgets-module__budgetValues--mARfV .budgets-module__right--pwFq1 {
  font-weight: 500;
  letter-spacing: 1.2px;
  font-size: 14px;
  flex: 1;
  font-family: 'Oswald';
  text-align: right;
  white-space: nowrap;
}
.budgets-module__budgetValues--mARfV .budgets-module__of--kgKf9 {
  min-width: 100px;
  text-align: center;
  opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./components/pages/bank-accounts/budgets/budgets.module.less"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,WAAA;AAFF;AAFA;EAOI,uBAAA;EACA,WAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;AAFJ;AAMA;EACE,cAAA;EACA,gBAAA;AAJF;AAQE;EACE,qBAAA;AANJ;AASE;EACE,mBAAA;AAPJ;AAWA;EACE,aAAA;EACA,8BAAA;EACA,WAAA;EACA,oCAAA;EACA,iBAAA;EACA,mBAAA;AATF;AAGA;;EAUI,gBAAA;EACA,qBAAA;EACA,eAAA;EACA,OAAA;EACA,qBAAA;EACA,iBAAA;EACA,mBAAA;AATJ;AAPA;EAoBI,gBAAA;EACA,kBAAA;EACA,YAAA;AAVJ","sourcesContent":["\n\n\n.consumed {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  width: 100%;\n\n  .percentage {\n    background-color: white;\n    color: #444;\n    margin-left: 16px;\n    padding: 4px 12px;\n    min-width: 80px;\n    text-align: center;\n    border-radius: 16px;\n  }\n}\n\n.isIncome {\n  color: rgb(130, 201, 130);\n  font-weight: 500;\n}\n\n.budgetLabel {\n  &.isCurrency {\n    font-family: 'Oswald';\n  }\n\n  &.noBreak {\n    white-space: nowrap;\n  }\n}\n\n.budgetValues {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  background-color: rgba(0,0,0,0.1);\n  padding: 4px 12px;\n  border-radius: 16px;\n\n  .left,\n  .right {\n    font-weight: 500;\n    letter-spacing: 1.2px;\n    font-size: 14px;\n    flex: 1;\n    font-family: 'Oswald';\n    text-align: right;\n    white-space: nowrap;\n  }\n\n  .of {\n    min-width: 100px;\n    text-align: center;\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"consumed": `budgets-module__consumed--KJCst`,
	"percentage": `budgets-module__percentage--qJ0ve`,
	"isIncome": `budgets-module__isIncome--G6cFh`,
	"budgetLabel": `budgets-module__budgetLabel--XC6Kl`,
	"isCurrency": `budgets-module__isCurrency--ES6jS`,
	"noBreak": `budgets-module__noBreak--ku912`,
	"budgetValues": `budgets-module__budgetValues--mARfV`,
	"left": `budgets-module__left--xaYYZ`,
	"right": `budgets-module__right--pwFq1`,
	"of": `budgets-module__of--kgKf9`
};
export default ___CSS_LOADER_EXPORT___;
