/**
 * Module dependencies.
 */

import { Typography } from 'antd';
import React from 'react';
import styles from './label.module.less';

/**
 * `Props` type.
 */

type Props = {
  children: string;
};

/**
 * Export `Label` component.
 */

export function Label({ children }: Props): JSX.Element {
  return <Typography.Text className={styles.wrapper}>{children}</Typography.Text>;
}
