/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { BankAccount } from 'app/types/bank-account';

/**
 * Get bank account.
 */

export function getBankAccount(client: AxiosInstance, id: string): Promise<BankAccount> {
  return client.get(resolveAppUrl('bankAccountGet', { interpolations: { id } })).then(({ data }) => data?.data);
}

/**
 * Export `useBankAccount`.
 */

export function useBankAccount(id: string | undefined) {
  const client = useRequestProvider();

  return useQuery(['bankAccount', id], {
    queryFn: async () => {
      if (!id) {
        return null;
      }

      return await getBankAccount(client, id);
    }
  });
}
