/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';

/**
 * `TypeAccount` type.
 */

type TypeAccount = 'refresh' | 'create';

/**
 * Refresh bank account.
 */

export function refreshBankAccount(client: AxiosInstance, id: number, typeAccount: TypeAccount): Promise<any> {
  return client
    .get(resolveAppUrl('bankAccountRefresh', { interpolations: { id }, params: { typeAccount } }))
    .then(({ data }) => data);
}

/**
 * Export `useRefreshBankAccount`.
 */

export function useRefreshBankAccount(typeAccount: TypeAccount) {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await refreshBankAccount(client, id, typeAccount);

      return result;
    }
  });
}
