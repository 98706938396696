/**
 * Module dependencies.
 */

import { Button } from 'app/components/atoms/button/button';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { appRoutes } from 'app/routes';
import React from 'react';
import { Link } from 'react-router-dom';
import { TbPlugConnected, TbListDetails } from 'react-icons/tb';

/**
 * `Props` type.
 */

type Props = {
  item: any;
};

/**
 * Link style.
 */

const linkStyle = {
  marginTop: 2
};

/**
 * Export `ReconnectControl` component.
 */

export function ReconnectControl({ item }: Props): JSX.Element {
  const { id, hasConnected, isCreditCard } = item;

  if (!hasConnected && !isCreditCard) {
    return (
      <Link
        onClick={event => {
          event.stopPropagation();
        }}
        style={linkStyle}
        to={resolveAppPath(appRoutes.dashboard.bankAccounts.accounts.connect, { id })}
      >
        <Button icon={<TbPlugConnected />} size={'small'} />
      </Link>
    );
  }

  return (
    <>
      <Link
        onClick={event => {
          event.stopPropagation();
        }}
        style={linkStyle}
        to={resolveAppPath(appRoutes.dashboard.bankAccounts.accounts.connect, { id })}
      >
        <Button icon={<TbListDetails />} size={'small'} />
      </Link>
    </>
  );
}
