/**
 * Module dependencies.
 */

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChartOutlined } from '@ant-design/icons';
import { Outlet } from 'react-router-dom';
import { Dashboard } from './dashboard';
import { appRoutes } from 'app/routes';
import { compileMenu } from 'app/core/utils/menu';
import { HiOutlineDocumentReport } from 'react-icons/hi';

/**
 * Export `SkinCrypto` component.
 */

export function SkinCrypto(): JSX.Element {
  const [translate] = useTranslation();
  const items = useMemo(
    () =>
      compileMenu([
        {
          key: '1',
          pathname: appRoutes.dashboard.crypto.base,
          icon: <PieChartOutlined />,
          label: translate('crypto.menu.overview')
        },
        {
          key: '2',
          pathname: appRoutes.dashboard.crypto.accounts,
          icon: <PieChartOutlined />,
          label: translate('crypto.menu.accounts')
        },
        {
          key: '3',
          pathname: appRoutes.dashboard.crypto.wallets,
          icon: <HiOutlineDocumentReport />,
          label: translate('crypto.menu.wallets')
        },
        {
          key: '4',
          pathname: appRoutes.dashboard.crypto.assets,
          icon: <HiOutlineDocumentReport />,
          label: translate('crypto.menu.assets')
        }
      ]),
    [translate]
  );

  return (
    <Dashboard menu={items}>
      <Outlet />
    </Dashboard>
  );
}
