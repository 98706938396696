/**
 * Module dependencies.
 */

import React from 'react';
import { Button } from '../../button/button';
import styles from './submit-button.module.less';
import classnames from 'classnames';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  loading?: boolean;
  label: string;
  disabled?: boolean;
  type?: 'ghost';
  onClick?: () => any;
  value?: string;
};

/**
 * Export `SubmitButton` component.
 */

export function SubmitButton({ className, disabled, onClick, loading, label, type, value }: Props): JSX.Element {
  return (
    <div className={classnames(styles.wrapper, className)}>
      <Button
        disabled={disabled}
        htmlType={'submit'}
        loading={loading}
        onClick={onClick}
        type={type ?? 'primary'}
        value={value}
      >
        {label}
      </Button>
    </div>
  );
}
