/**
 * Module dependencies.
 */

import { SelectField } from 'app/components/atoms/forms/fields/select/select-field';
import { searchOnInput } from 'app/core/utils/filter-utils';
import React, { useMemo } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { SelectFieldFormItem } from '../form-types';
import { identity, set } from 'lodash';
import { useParams } from 'react-router-dom';

/**
 * `FormSelectFieldProps` type.
 */

export type FormSelectFieldProps = {
  name: string;
  item: SelectFieldFormItem;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * `ByHook` component.
 */

function ByHook(props: FormSelectFieldProps) {
  const { form, item } = props;
  const { control } = form;
  const { watch } = form;
  const {
    filterOption = searchOnInput,
    showSearch = false,
    options,
    disabled,
    placeholder,
    name,
    label,
    ...rest
  } = item;

  const { hookProps, normalize, hook } = options as any;
  const params = useParams();
  const values = watch();
  const hookPropsParams = hookProps?.(values, params) ?? [];
  const { data } = hook?.(...hookPropsParams) ?? { data: options };
  const normalizer = normalize ?? identity;
  const optionValues = useMemo(() => normalizer(data?.results ?? data, values), [data, normalizer, values]);

  set(form, ['cache', 'hooks', name], {
    data,
    optionValues
  });

  return (
    <SelectField
      {...rest}
      control={control}
      disabled={typeof disabled === 'function' ? disabled(values) : disabled}
      filterOption={filterOption}
      label={label}
      name={name}
      options={optionValues}
      placeholder={placeholder ?? placeholder === false ? undefined : label}
      showSearch={showSearch}
    />
  );
}

/**
 * `ByStatic` component.
 */

function ByStatic(props: FormSelectFieldProps) {
  const { form, item } = props;
  const { control } = form;
  const {
    label,
    placeholder,
    optionLabelProp,
    filterOption = searchOnInput,
    showSearch = false,
    disabled,
    options,
    ...rest
  } = item;

  return (
    <SelectField
      {...rest}
      control={control}
      disabled={typeof disabled === 'function' ? disabled({}) : disabled}
      filterOption={filterOption}
      label={label}
      optionLabelProp={optionLabelProp}
      options={options as any}
      placeholder={placeholder ?? placeholder === false ? undefined : label}
      showSearch={showSearch}
    />
  );
}

/**
 * Export `FormSelectField` component.
 */

export function FormSelectField(props: FormSelectFieldProps): JSX.Element {
  if ((props.item.options as any)?.hook) {
    return <ByHook {...props} />;
  }

  return <ByStatic {...props} />;
}
