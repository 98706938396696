/**
 * Module dependencies.
 */

import { AppRoutes } from 'app/app-routes';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider, TFunction, useTranslation } from 'react-i18next';
import { QueryClientProvider } from '@tanstack/react-query';
import { createAxiosClient } from 'app/core/utils/axios-client';
import { createQueryClient } from 'app/core/utils/react-query';
import React, { useCallback, useMemo } from 'react';
import RequestProvider from 'app/components/providers/request-provider/request-provider';
import i18n from 'app/core/locales/i18n';
import { useHackMobileBrowser } from './hooks/use-hack-mobile-browser';
import { StorageProvider } from './components/providers/storage-provider/storage-provider';
import { ConfigProvider, notification } from 'antd';
import { camelCase } from 'lodash';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CollapseMenuProvider } from './components/providers/collapse-menu-provider/collapse-menu-provider';

/**
 * Axios client.
 */

const axiosClient = createAxiosClient();

/**
 * Resolve error message.
 */

function resolveErrorMessage(translate: TFunction, error: any) {
  const { code, ...params } = error?.response?.data?.data ?? {};
  const messageTranslation = translate('common.errors.network.default.title');
  const message = translate([
    `common.errors.network.${code}.title`,
    `common.errors.network.${error?.status}.title`,
    'common.errors.network.default.title'
  ]);

  const descriptionTranslation = translate('common.errors.network.default.description');
  const description = translate(
    [
      `common.errors.network.${code}.description`,
      `common.errors.network.${error.response?.status}.description`,
      'common.errors.network.default.description'
    ],
    params ?? {}
  );

  if (messageTranslation === message) {
    console.log(
      `Missing Message translation: ${JSON.stringify(
        [
          `common.errors.network.${code}.title`,
          `common.errors.network.${error?.status}.title`,
          'common.errors.network.default.title'
        ],
        null,
        2
      )}`
    );
  }

  if (descriptionTranslation === description) {
    console.log(
      `Missing Description translation: ${JSON.stringify(
        [
          `common.errors.network.${code}.description`,
          `common.errors.network.${error.response?.status}.description`,
          'common.errors.network.default.description'
        ],
        null,
        2
      )}`
    );
  }

  return {
    message,
    description
  };
}

/**
 * Resolve success message.
 */

function resolveSuccessMessage(translate: TFunction, payload: any) {
  const { ui, data } = payload ?? {};
  const { entity, operation, hidden } = ui ?? {};

  if (hidden) {
    return null;
  }

  if (!entity || !operation) {
    console.warn(
      `Missing entity or operation: ${entity} | ${operation} => ${camelCase([entity, operation].join(' '))}`
    );
  }

  translate(`common.success.network.${camelCase([entity, operation].join(' '))}.title`);

  return {
    message: translate([
      `common.success.network.${camelCase([entity, operation].join(' '))}.title`,
      `common.success.network.${entity}.title`,
      'common.success.network.default.title'
    ]),
    description: translate(
      [
        `common.success.network.${camelCase([entity, operation].join(' '))}.description`,
        `common.success.network.${entity}.description`,
        'common.success.network.default.description'
      ],
      data ?? {}
    )
  };
}

/**
 * `QueryClient` component.
 */

function QueryClient({ children }: { children: JSX.Element }) {
  const [api, contextHolder] = notification.useNotification();
  const [translate] = useTranslation();
  const showError = useCallback(
    (error: any) => {
      api.error({
        key: `error-${new Date().getTime()}`,
        ...resolveErrorMessage(translate, error)
      });
    },
    [api, translate]
  );

  const showSuccess = useCallback(
    (data: any) => {
      const message = resolveSuccessMessage(translate, data);

      if (!message) {
        return;
      }

      api.success({
        key: `success-${new Date().getTime()}`,
        ...message
      });
    },
    [api, translate]
  );

  const queryClient = useMemo(() => createQueryClient(showError, showSuccess), [showError, showSuccess]);

  return (
    <div className={'fix_notification'}>
      {contextHolder}

      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </div>
  );
}

/**
 * Export `App` component.
 */

export function App(): JSX.Element {
  useHackMobileBrowser();

  return (
    <>
      <GoogleOAuthProvider clientId={'188150150161-6af4otuc2uljn4d9oooegueqfl0ff9qi.apps.googleusercontent.com'}>
        <ConfigProvider>
          <StorageProvider>
            <I18nextProvider i18n={i18n}>
              <RequestProvider client={axiosClient}>
                <QueryClient>
                  <BrowserRouter>
                    <CollapseMenuProvider>
                      <AppRoutes />
                    </CollapseMenuProvider>
                  </BrowserRouter>
                </QueryClient>
              </RequestProvider>
            </I18nextProvider>
          </StorageProvider>
        </ConfigProvider>
      </GoogleOAuthProvider>
    </>
  );
}
