/**
 * Module dependencies.
 */

import { Button } from 'app/components/atoms/button/button';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { CrudTemplate } from '../form-types';
import styles from '../crud.module.less';
import { FiltersForm } from './filters-form';
import { resolveAppPath } from 'app/core/utils/url-resolver';

/**
 * `Props` type.
 */

type Props = {
  template: CrudTemplate;
  appendFilters: (values: any) => void;
  refresh: () => Promise<any>;
  params: any;
  hasNested: boolean;
};

/**
 * Export `CrudFilters` component.
 */

export function CrudFilters({ template, hasNested, refresh, appendFilters, params }: Props): JSX.Element {
  const list = useMemo(() => template?.list, [template?.list]);
  const [searchParams] = useSearchParams();
  const filters = useMemo(() => list?.filters ?? [], [list?.filters]);
  const handleAppendFilters = useCallback(
    (data: any) => {
      appendFilters(template.list?.normalizeFilters?.(data));
    },
    [appendFilters, template.list]
  );

  useEffect(() => {
    if (hasNested) {
      appendFilters({});
    }
  }, [appendFilters, hasNested]);

  return (
    <div className={styles.header}>
      <FiltersForm
        appendFilters={handleAppendFilters}
        defaultQueryString={template?.list?.defaultQueryString}
        filters={filters}
        hasNested={hasNested}
      >
        <>
          {template?.list?.extraActions?.({ refresh })}

          {!!template?.add?.route && (
            <Link
              to={
                template?.list?.interpolateAddLink?.(template, searchParams) ??
                resolveAppPath(template?.add.route, params)
              }
            >
              <Button type={'primary'}>{template.list?.addButton}</Button>
            </Link>
          )}
        </>
      </FiltersForm>
    </div>
  );
}
