/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Bank } from 'app/types/bank-account';

/**
 * Get bank account.
 */

export function getBankList(client: AxiosInstance, integration: string): Promise<Bank[]> {
  return client({
    url: resolveAppUrl('bankList') + '?integration=' + integration
  }).then(({ data }) => data?.data);
}

/**
 * Export `useBankList`.
 */

export function useBankList(integration: string) {
  const client = useRequestProvider();

  return useQuery(['bankList', integration], {
    queryFn: async () => {
      console.log('integration', integration);

      return await getBankList(client, integration);
    }
  });
}
