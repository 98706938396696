/**
 * Module dependencies.
 */

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import {
  CrudAddType,
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  FormList,
  ListColumns
} from 'app/components/organisms/crud/form-types';
import { normalizeTemplate } from 'app/components/organisms/crud/utils';

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { requiredRule } from 'app/core/utils/field-rules';
import { appRoutes } from 'app/routes';
import { TFunction } from 'i18next';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'id',
    size: '30px',
    key: 'id'
  },
  {
    title: translate('common.table.columns.company'),
    dataIndex: 'company',
    key: 'company'
  },
  {
    title: translate('common.table.columns.address'),
    dataIndex: 'address',
    key: 'address'
  },
  {
    title: translate('common.table.columns.email'),
    dataIndex: 'email',

    key: 'email'
  },
  {
    title: translate('common.table.columns.notes'),
    dataIndex: 'notes',
    size: '1fr',
    key: 'notes',
    style: { minWidth: '300px' }
  }
];

/**
 * Form list.
 */

const formFields = (translate: TFunction): FormList => [
  {
    type: 'inputField',
    name: 'company',
    label: translate('common.labels.company'),
    rules: requiredRule(translate)
  },
  {
    type: 'inputField',
    name: 'address',
    label: translate('common.labels.address'),
    rules: undefined
  },
  {
    type: 'inputField',
    name: 'email',
    label: translate('common.labels.email'),
    rules: undefined
  },
  {
    type: 'inputField',
    name: 'notes',
    label: translate('common.labels.notes'),
    rules: undefined
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: String(translate('common.labels.search'))
  }
];

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType => ({
  formFields: formFields(translate)
});

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType => ({
  formFields: formFields(translate)
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType => ({});

/**
 * List.
 */

const list = (translate: TFunction, suppliesContactsTemplate: CrudTemplate): CrudListType => ({
  columns: listColumns(translate),
  style: { minWidth: 500 },
  route: appRoutes.dashboard.projects.project.suppliers,
  expandedTemplate: suppliesContactsTemplate,
  key: ['projectSuppliers'],
  endpoint: apiEndpoints.projectSuppliers,
  filters: listFilters(translate)
});

/**
 * Config.
 */

export function createProjectSuppliersTemplate(
  translate: TFunction,
  suppliesContactsTemplate: CrudTemplate
): CrudTemplate {
  return normalizeTemplate(translate, {
    add: add(translate),
    edit: edit(translate),
    list: list(translate, suppliesContactsTemplate),
    remove: remove()
  });
}
