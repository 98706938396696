/**
 * Module dependencies.
 */

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChartOutlined } from '@ant-design/icons';
import { Outlet } from 'react-router-dom';
import { Dashboard } from './dashboard';
import { appRoutes } from 'app/routes';
import { compileMenu } from 'app/core/utils/menu';

/**
 * Export `SkinSettings` component.
 */

export function SkinSettings(): JSX.Element {
  const [translate] = useTranslation();
  const items = useMemo(
    () =>
      compileMenu([
        {
          key: '1',
          pathname: appRoutes.dashboard.settings.base,
          icon: <PieChartOutlined />,
          label: translate('settings.menu.overview')
        },
        {
          key: '2',
          pathname: appRoutes.dashboard.settings.profile,
          icon: <PieChartOutlined />,
          label: translate('settings.menu.profile')
        }
      ]),
    [translate]
  );

  return (
    <Dashboard menu={items}>
      <Outlet />
    </Dashboard>
  );
}
