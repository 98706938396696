/**
 * Module dependencies.
 */

import { FormList } from 'app/components/organisms/crud/form-types';
import BigNumber from 'bignumber.js';
import { TFunction } from 'react-i18next';
import { splittedTransactionForm } from './splitted-transaction-form';
import { SplitTransactionStatus } from './split-transaction-status';
import { pick } from 'lodash';

/**
 * Export `splitTransaction`.
 */

export const splitTransaction = (translate: TFunction, data: any): FormList => [
  {
    type: 'inputNumberField',
    name: 'amount',
    disabled: !!data?.account?.hasConnected,
    label: translate('common.labels.amount'),
    onChange: (value: any, form: any) => {
      const { previousBalance } = form.getValues();

      form.setValue('balance', new BigNumber(previousBalance).plus(value ?? '0').toString());
    },
    rules: undefined
  },
  {
    type: 'render',
    name: 'statusSplit',
    renderComponent: SplitTransactionStatus
  },
  {
    type: 'listItemForm',
    name: 'splitTransactions',
    defaultItemsLength: 2,
    formFields: splittedTransactionForm(translate),
    normalizeValues: (values: any) => {
      return pick(values, ['bank_description', 'description', 'bank_date', 'date', 'subCategoryId', 'categoryId']);
    }
  }
];
