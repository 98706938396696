/**
 * Module dependencies.
 */

import {
  CrudAddType,
  CrudEditType,
  CrudListType,
  CrudTemplate,
  FormList
} from 'app/components/organisms/crud/form-types';
import { normalizeTemplate } from 'app/components/organisms/crud/utils';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import { requiredRule } from 'app/core/utils/field-rules';
import { appRoutes } from 'app/routes';
import { TFunction } from 'i18next';

/**
 * Form list.
 */

const formFields = (translate: TFunction): FormList => [
  {
    type: 'inputField',
    name: 'name',
    label: translate('common.labels.name'),
    rules: requiredRule(translate)
  },
  {
    type: 'sliderField',
    name: 'defaultIVA',
    label: translate('common.labels.defaultIVA'),
    rules: requiredRule(translate)
  }
];

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType => ({
  formFields: formFields(translate),
  endpoint: apiEndpoints.projectsAdd
});

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType => ({
  formFields: formFields(translate)
});

/**
 * List.
 */

const list = (): CrudListType => ({
  columns: [],
  route: appRoutes.dashboard.projects.base,
  key: ['projects'],
  endpoint: apiEndpoints.projects
});

/**
 * Config.
 */

export function createProjectsTemplate(translate: TFunction): CrudTemplate {
  return normalizeTemplate(translate, {
    add: add(translate),
    edit: edit(translate),
    list: list()
  });
}
