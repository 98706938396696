/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { RequestOptions } from 'app/types/network';
import { Transaction } from 'app/types/transaction';

/**
 * Get movements by id.
 */

function getMovementsById(
  client: AxiosInstance,
  accountId: number,
  bankDate: string,
  amount: string,
  options?: RequestOptions
): Promise<Transaction> {
  return client
    .post(
      resolveAppUrl('transactionsByRange', {
        params: options
      }),
      {
        accountId,
        bankDate,
        amount
      }
    )
    .then(({ data }) => data?.data);
}

/**
 * Export `useGetTransactionsByDateRange`.
 */

export function useGetTransactionsByDateRange(
  accountId: number,
  bank_date: string,
  amount: string,
  options?: RequestOptions
) {
  const client = useRequestProvider();

  return useQuery(['transactionsByRange', accountId, bank_date, amount], {
    queryFn: async () => {
      if (!accountId) {
        return null;
      }

      return await getMovementsById(client, accountId, bank_date, amount, options);
    }
  });
}
