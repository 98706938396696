/**
 * Module dependencies.
 */

import Cookies from 'universal-cookie';

/**
 * Cookie.
 */

export const cookieStorage = new Cookies();
