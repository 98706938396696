/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useBankAccountsInvalidator } from './use-bank-accounts';

/**
 * `Payload` type.
 */

type Payload = {
  accountId: number;
  integrationId: string;
  transactionsDate: string;
};

/**
 * Refresh Transactions.
 */

function refreshTransactions(
  client: AxiosInstance,
  { accountId, integrationId, transactionsDate }: Payload
): Promise<void> {
  return client
    .post(
      resolveAppUrl('bankAccountConnectFirstRefreshTransactions', {
        interpolations: { accountId, referenceId: integrationId }
      }),
      { date: transactionsDate }
    )
    .then(({ data }) => data);
}

/**
 * Export `useBankAccountFirstRefreshTransactions`.
 */

export function useBankAccountFirstRefreshTransactions() {
  const client = useRequestProvider();
  const invalidator = useBankAccountsInvalidator();

  return useMutation({
    mutationFn: async (payload: Payload) => {
      const result = await refreshTransactions(client, payload);

      invalidator();

      return result;
    }
  });
}
