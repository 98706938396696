/**
 * Module dependencies.
 */

import React, { ReactNode, createContext, useContext } from 'react';
import { CrudTemplate } from './form-types';

/**
 * `Props` type.
 */

type Props = {
  template: CrudTemplate;
  children: ReactNode;
};

/**
 * `CrudTemplateContext` context.
 */

const CrudTemplateContext = createContext<{ template: CrudTemplate }>({
  template: null as any
});

/**
 * Export `useTemplate`.
 */

export function useTemplate() {
  return useContext(CrudTemplateContext);
}

/**
 * Export `CrudTemplateProvider` component.
 */

export function CrudTemplateProvider({ template, children }: Props): JSX.Element {
  return <CrudTemplateContext.Provider value={{ template }}>{children}</CrudTemplateContext.Provider>;
}
