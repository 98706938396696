/**
 * Module dependencies.
 */

import { get } from 'lodash';

/**
 * Config.
 */

const config: any = (window as any).__config;

/**
 * Delete config.
 */

delete (window as any).__config;

/**
 * Export module.
 */

export default {
  get: (path: string) => get(config, path),
  ...config
};
