/**
 * Module dependencies.
 */

import { LastLineProps } from 'app/components/organisms/crud/form-types';
import tableStyles from 'app/components/organisms/table/table.module.less';
import { formatCurrency } from 'app/core/utils/formatter';
import BigNumber from 'bignumber.js';
import { reduce } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './movements.module.less';
import classnames from 'classnames';
import { Field } from 'app/components/organisms/field/field';

/**
 * Export `TableFooter` component.
 */

export function TableFooter({ data, ...rest }: LastLineProps): JSX.Element | null {
  const [translate] = useTranslation();
  const total = useMemo(() => {
    return reduce(
      data,
      (acc: any, obj) => {
        const item = JSON.parse(JSON.stringify(obj));

        if (!acc[item.transactionType]) {
          acc[item.transactionType] = new BigNumber('0');
        }

        acc[item.transactionType] = acc[item.transactionType].plus(item.amount ?? '0');
        acc.total = acc.total.plus(item.amount ?? '0');

        return { ...acc };
      },
      {
        total: new BigNumber('0'),
        normal: new BigNumber('0'),
        splitTransaction: new BigNumber('0'),
        switchAccount: new BigNumber('0')
      }
    );
  }, [data]);

  if (data?.length !== rest.total) {
    return null;
  }

  return (
    <div className={classnames(tableStyles.row, styles.total)}>
      <div className={styles.line}>
        <Field title={translate('common.labels.items')} value={String(data?.length)} />

        {Object.keys(total).map(key => {
          return <Field key={key} title={translate(`common.labels.${key}`)} value={formatCurrency(total[key])} />;
        })}
      </div>
    </div>
  );
}
