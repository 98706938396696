// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overview-module__total--lG7By .overview-module__line--uiq4f {
  padding-right: 50px;
  padding-top: 40px;
  grid-column: 4 / 8;
}
.overview-module__total--lG7By .overview-module__margin--bPKYr {
  padding-top: 40px;
}
.overview-module__table--jtWkq {
  max-width: initial;
}
.overview-module__table--jtWkq .loadingWrapper {
  padding: 16px;
}
.overview-module__wrapperClassName--qNCA_ {
  min-height: initial;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 32px;
}
.overview-module__header--iokiJ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
}
.overview-module__title--QLP7u {
  padding-bottom: 0;
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./components/pages/crypto/overview/overview.module.less"],"names":[],"mappings":"AAGA;EAGI,mBAAA;EACA,iBAAA;EACA,kBAAA;AAJJ;AADA;EASI,iBAAA;AALJ;AAUA;EACE,kBAAA;AARF;AAOA;EAII,aAAA;AARJ;AAYA;EACE,mBAAA;EACA,0CAAA;EACA,aAAA;AAVF;AAaA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,oBAAA;AAXF;AAcA;EACE,iBAAA;EACA,gBAAA;AAZF","sourcesContent":["\n\n\n.total {\n  .line {\n\n    padding-right: 50px;\n    padding-top: 40px;\n    grid-column: 4 / 8;\n  }\n\n  .margin {\n    padding-top: 40px;\n  }\n}\n\n\n.table {\n  max-width: initial;\n\n  :global(.loadingWrapper) {\n    padding: 16px;\n  }\n}\n\n.wrapperClassName {\n  min-height: initial;\n  background-color: rgba(255,255,255,0.1);\n  padding: 32px;\n}\n\n.header {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding-bottom: 24px;\n}\n\n.title {\n  padding-bottom: 0;\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"total": `overview-module__total--lG7By`,
	"line": `overview-module__line--uiq4f`,
	"margin": `overview-module__margin--bPKYr`,
	"table": `overview-module__table--jtWkq`,
	"loadingWrapper": `loadingWrapper`,
	"wrapperClassName": `overview-module__wrapperClassName--qNCA_`,
	"header": `overview-module__header--iokiJ`,
	"title": `overview-module__title--QLP7u`
};
export default ___CSS_LOADER_EXPORT___;
