/**
 * Module dependencies.
 */

import { TFunction } from 'i18next';

/**
 * Export `requiredRule`.
 */

export const requiredRule = (translate: TFunction) => ({
  required: translate('common.errors.form.required')
});
