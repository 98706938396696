/**
 * Module dependencies.
 */

import React, { forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import CanvasJSReact from 'libs/canvasjs/canvasjs.react';
import { merge } from 'lodash';

/**
 * Canvas chart.
 */

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  options: any;
};

/**
 * `Dimension` type.
 */

type Dimension = {
  width: number;
  height: number;
};

/**
 * Default options.
 */

const defaultOptions = {
  backgroundColor: 'transparent',
  animationEnabled: true,
  title: {
    fontColor: '#333',
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: 26
  },
  legend: {
    fontColor: '#888888',
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: 16
  },
  axisY: {
    labelFontFamily: 'Roboto',
    labelFontSize: 14,
    labelFontWeight: 400,
    labelFontColor: '#888888',
    gridColor: '#888',
    tickColor: '#000',
    lineColor: '#000',
    lineThickness: 1.5
  },
  axisY2: {
    labelFontFamily: 'Roboto',
    labelFontSize: 16,
    labelFontWeight: 400,
    labelFontColor: '#888888',
    gridColor: '#888',
    tickColor: '#000',
    lineColor: '#000',
    lineThickness: 1.5
  },
  axisX: {
    labelFontFamily: 'Roboto',
    labelFontSize: 14,
    labelFontWeight: 400,
    labelFontColor: '#888888',
    gridColor: '#888',
    tickColor: '#000',
    lineColor: '#000',
    lineThickness: 1.5
  }
};

/**
 * Export `CanvasChartComponent` component.
 */

function CanvasChartComponent({ className, options }: Props, forwardRef: any): JSX.Element {
  const chartRef = useRef<any>();
  const viewRef = useRef<any>();

  const [viewDimensions, setViewDimensions] = useState<Dimension | undefined>();
  const normalizedOptions = useMemo(
    () =>
      merge(
        {},
        defaultOptions,
        {
          width: viewDimensions?.width
        },
        options
      ),
    [options, viewDimensions?.width]
  );

  useLayoutEffect(() => {
    if (!chartRef.current) {
      return;
    }

    chartRef.current?.render();
  }, [viewDimensions]);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      const computedStyle = getComputedStyle(viewRef.current);
      let elementHeight = viewRef.current.clientHeight;
      let elementWidth = viewRef.current.clientWidth;

      elementHeight -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
      elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

      setViewDimensions({
        height: elementHeight,
        width: elementWidth
      });
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (normalizedOptions && chartRef.current) {
      chartRef.current.render();
    }
  }, [normalizedOptions]);

  return (
    <div className={className} ref={viewRef}>
      {!!viewDimensions && (
        <CanvasJSChart
          containerProps={{ width: '100%' }}
          onRef={(ref: any) => {
            chartRef.current = ref;

            if (forwardRef) {
              forwardRef.current = ref;
            }
          }}
          options={normalizedOptions}
        />
      )}
    </div>
  );
}

/**
 * Export `CanvasChart` component.
 */

export const CanvasChart = forwardRef(CanvasChartComponent);
