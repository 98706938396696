/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Coin } from 'app/types/coin';

/**
 * Get coins.
 */

function getCoins(client: AxiosInstance): Promise<Coin[]> {
  return client.get(resolveAppUrl('coins')).then(({ data }) => data?.data);
}

/**
 * Export `useCoinsInvalidator`.
 */

export function useCoinsInvalidator() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(['coins']);
  }, [queryClient]);
}

/**
 * Export `useCoins`.
 */

export function useCoins() {
  const client = useRequestProvider();

  return useQuery(['coins'], {
    queryFn: async () => {
      return await getCoins(client);
    }
  });
}
