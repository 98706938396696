/**
 * Module dependencies.
 */

import { Tabs } from 'antd';
import { useBankAccounts } from 'app/hooks/requests/bank-accounts/use-bank-accounts';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './movements.module.less';
import { CrudTemplate } from 'app/components/organisms/crud/form-types';

/**
 * `Props` type.
 */

type Props = {
  className: string;
  children: JSX.Element;
  refetch: () => Promise<void>;
  isLoading: boolean;
  template: CrudTemplate;
};

/**
 * Export `Wrapper` component.
 */

export function Wrapper({ template, className, children, refetch }: Props): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams(template?.list?.defaultQueryString);
  const [updateList, setUpdateList] = useState<number>();
  const account = useMemo(() => {
    const result = searchParams.get('filter');
    const data = JSON.parse(result ?? '{}');

    return data.account;
  }, [searchParams]);

  const [activeKey, setActiveKey] = useState<string | undefined>(account ? String(account) : undefined);
  const { data } = useBankAccounts();
  const items = useMemo(
    () => [
      {
        key: String(-1),
        label: 'All'
      },
      ...(data?.results?.map(item => ({
        key: String(item.id),
        label: item.name
      })) ?? [])
    ],
    [data]
  );

  useEffect(() => {
    setSearchParams(searchParams => {
      const filter = searchParams.get('filter');
      const data = JSON.parse(filter ?? '{}');

      if (!activeKey || parseInt(activeKey, 10) < 0) {
        delete data.account;
      } else {
        data.account = parseInt(activeKey ?? '0', 10);
      }

      searchParams.set('filter', JSON.stringify(data));

      return searchParams;
    });
    setUpdateList(Date.now());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, refetch]);

  return (
    <div className={styles.tabsWrapper}>
      <Tabs
        activeKey={activeKey}
        className={styles.tabs}
        defaultActiveKey={activeKey}
        items={items as any}
        onChange={setActiveKey}
      />

      <div className={className} key={updateList}>
        {children}
      </div>
    </div>
  );
}
