/**
 * Module dependencies.
 */

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createProjectsTemplate } from './form-config';
import { CrudAdd } from 'app/components/organisms/crud/crud-add';

/**
 * Export `AddNewProject` component.
 */

export function AddNewProject(): JSX.Element {
  const [translate] = useTranslation();
  const template = useMemo(() => {
    return createProjectsTemplate(translate);
  }, [translate]);

  return <CrudAdd template={template} />;
}
