/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { BankAccountLink } from 'app/types/bank-account';

/**
 * Get bank account links.
 */

function getBankAccountsLinks(client: AxiosInstance): Promise<BankAccountLink[]> {
  return client.get(resolveAppUrl('bankAccountConnectLinks')).then(({ data }) => data?.data);
}

/**
 * Export `useBankAccountLinks`.
 */

export function useBankAccountLinks() {
  const client = useRequestProvider();

  return useQuery(['bankAccountLinks'], {
    queryFn: async () => {
      return await getBankAccountsLinks(client);
    }
  });
}
