/**
 * Module dependencies.
 */

import React from 'react';

/**
 * Export `SettingsOverview` component.
 */

export function SettingsOverview(): JSX.Element {
  return <div>{'settings overview'}</div>;
}
