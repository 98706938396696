/**
 * Module dependencies.
 */

import { FormList } from 'app/components/organisms/crud/form-types';
import { requiredRule } from 'app/core/utils/field-rules';
import { TFunction } from 'react-i18next';
import { sortBy } from 'lodash';

/**
 * Binance account.
 */

const binanceAccount = (translate: TFunction): FormList => [
  {
    type: 'inputField',
    name: 'apiKey',
    label: translate('common.labels.apiKey'),
    rules: requiredRule(translate)
  },
  {
    type: 'inputField',
    name: 'secret',
    label: translate('common.labels.secret'),
    rules: requiredRule(translate)
  }
];

/**
 * Kucoin account.
 */

const kucoinAccount = (translate: TFunction): FormList => [
  {
    type: 'inputField',
    name: 'apiKey',
    label: translate('common.labels.apiKey'),
    rules: requiredRule(translate)
  },
  {
    type: 'inputField',
    name: 'secret',
    label: translate('common.labels.secret'),
    rules: requiredRule(translate)
  },
  {
    type: 'inputField',
    name: 'passphrase',
    label: translate('common.labels.passphrase'),
    rules: requiredRule(translate)
  }
];

/**
 * Exchange Account.
 */

const exchangeAccount = (translate: TFunction): FormList => [
  {
    type: 'selectField',
    name: 'provider',
    label: translate('common.labels.exchange'),
    rules: requiredRule(translate),
    options: [
      {
        value: 'binance',
        label: 'Binance'
      },
      {
        value: 'kucoin',
        label: 'Kucoin'
      }
    ]
  },
  {
    type: 'switch',
    name: 'need_provider_exchange_id',
    watchFields: ['provider'],
    condition: values => {
      return values?.[0];
    },
    switchCases: {
      binance: binanceAccount(translate),
      kucoin: kucoinAccount(translate)
    }
  }
];

/**
 * Normal address field.
 */

const normalAddress = (translate: TFunction): FormList => [
  {
    type: 'inputField',
    name: 'address',
    label: translate('common.labels.address'),
    rules: requiredRule(translate)
  }
];

/**
 * Wallet Address.
 */

const walletAddress = (translate: TFunction): FormList => [
  {
    type: 'selectField',
    name: 'chain',
    showSearch: true,
    label: translate('common.labels.network'),
    rules: requiredRule(translate),
    options: sortBy(
      [
        {
          value: 'ETHEREUM',
          label: translate('common.chains.ethereum')
        },
        {
          value: 'SEPOLIA',
          label: translate('common.chains.sepolia')
        },
        {
          value: 'GOERLI',
          label: translate('common.chains.goerli')
        },
        {
          value: 'POLYGON',
          label: translate('common.chains.polygon')
        },
        {
          value: 'MUMBAI',
          label: translate('common.chains.numbai')
        },
        {
          value: 'BSC',
          label: translate('common.chains.bsc')
        },
        {
          value: 'AVALANCHE',
          label: translate('common.chains.avalanche')
        },
        {
          value: 'FUJI',
          label: translate('common.chains.fuji')
        },
        {
          value: 'FANTOM',
          label: translate('common.chains.fantom')
        },
        {
          value: 'CRONOS',
          label: translate('common.chains.cronos')
        },
        {
          value: 'PALM',
          label: translate('common.chains.palm')
        },
        {
          value: 'ARBITRUM',
          label: translate('common.chains.arbitrum')
        },
        {
          value: 'BITCOIN',
          label: translate('common.chains.bitcoin')
        },
        {
          value: 'CARDANO',
          label: translate('common.chains.cardano')
        }
      ],
      'value'
    )
  },
  {
    type: 'switch',
    name: 'need_chain_id',
    watchFields: ['chain'],
    condition: values => {
      return values?.[0];
    },
    switchCases: {
      default: normalAddress(translate)
    }
  }
];

/**
 * Export `formFields`.
 */

export const formFields = (translate: TFunction): FormList => [
  {
    type: 'selectField',
    name: 'accountType',
    label: translate('common.labels.accountType'),
    rules: undefined,
    options: [
      {
        value: 'walletAddress',
        label: translate('common.labels.walletAddress')
      },
      {
        value: 'exchangeAccount',
        label: translate('common.labels.exchangeAccount')
      }
    ]
  },
  {
    type: 'switch',
    name: 'need_account_id',
    watchFields: ['accountType'],
    condition: values => {
      return values?.[0];
    },
    switchCases: {
      exchangeAccount: exchangeAccount(translate),
      walletAddress: walletAddress(translate)
    }
  }
];
