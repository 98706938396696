/**
 * Module dependencies.
 */

import BigNumber from 'bignumber.js';
import { reduce } from 'lodash';

/**
 * Sum proposals.
 */

export function sumProposals(item: any) {
  if (item.withoutProposal) {
    return new BigNumber(item.totalMovementTax);
  }

  return reduce(
    item.proposals,
    (acc, proposal) => {
      let result = acc;

      if (result === undefined) {
        result = new BigNumber('0');
      }

      result = result.plus(proposal.total);

      console.log('proposal', result.toString(), proposal.total);

      return result;
    },
    undefined as any
  );
}
