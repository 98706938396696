/**
 * Module dependencies.
 */

import { Title } from 'app/components/atoms/title/title';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './dashboard-overview.module.less';
import { CanvasChart } from 'app/components/organisms/canvas-chart/canvas-chart';
import { useDashboardOverview } from 'app/hooks/requests/dashboard/use-dashboard-overview';
import { compact, merge, reduce, sortBy } from 'lodash';
import { Field } from 'app/components/organisms/field/field';
import { formatCurrency } from 'app/core/utils/formatter';
import { Loading } from 'app/components/atoms/loading/loading';
import BigNumber from 'bignumber.js';

/**
 * Export `DashboardOverview` component.
 */

export function DashboardOverview(): JSX.Element {
  const [translate] = useTranslation();
  const { data, isLoading } = useDashboardOverview();
  const options = useMemo(() => {
    console.log(data?.plot);
    if (!data?.plot) {
      return null;
    }

    const cloneData = JSON.parse(JSON.stringify(data?.plot));

    cloneData.data[0].dataPoints = compact(
      data?.plot.data[0].dataPoints.map((item: any) => {
        return item.y === 0 ? null : item;
      })
    );

    return merge({}, cloneData, {
      title: {
        text: translate('dashboard.overview.balances')
      }
    });
  }, [data, translate]);

  const legend = useMemo(() => {
    return sortBy(
      data?.plot?.data?.[0]?.dataPoints?.map((item: any) => {
        return {
          id: item.metadata.id,
          title: item.indexLabel,
          balance: parseFloat(item.metadata.balance)
        };
      }),
      'balance'
    );
  }, [data?.plot?.data]);

  const { totalReport, totalNegative, total } = useMemo(() => {
    return reduce(
      data?.plot?.data?.[0]?.dataPoints,
      (acc, item) => {
        console.log(item.metadata.balance);

        if (new BigNumber(item.metadata.balance).isGreaterThanOrEqualTo(0)) {
          acc.totalReport = acc.totalReport.plus(item.metadata.balance);
        } else {
          acc.totalNegative = acc.totalNegative.plus(item.metadata.balance);
        }

        acc.total = new BigNumber(acc.totalReport).plus(acc.totalNegative);

        return acc;
      },
      {
        totalReport: new BigNumber('0'),
        totalNegative: new BigNumber('0'),
        total: new BigNumber('0')
      }
    );
  }, [data?.plot?.data]);

  return (
    <div>
      <Title>{translate('dashboard.overview.title')}</Title>

      <Loading isLoading={isLoading}>
        {!!data && (
          <div className={styles.header}>
            <div className={styles.plot}>
              <CanvasChart className={styles.yearExpenses} options={options} />
            </div>

            <div className={styles.legend}>
              <div className={styles.content}>
                {legend.map(item => {
                  return (
                    <Field
                      danger={item.balance < 0}
                      key={item.id}
                      title={item.title}
                      value={formatCurrency(item.balance)}
                    />
                  );
                })}
              </div>
            </div>

            <div className={styles.resume}>
              <div className={styles.content}>
                <Field title={translate('common.labels.totalReport')} value={formatCurrency(totalReport)} />

                <Field title={translate('common.labels.totalNegative')} value={formatCurrency(totalNegative)} />

                <Field bold title={translate('common.labels.total')} value={formatCurrency(total)} />
              </div>
            </div>
          </div>
        )}
      </Loading>
    </div>
  );
}
