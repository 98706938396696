/**
 * Module dependencies.
 */

import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ListFilter, ListFilters } from './form-filters-type';
import styles from '../crud.module.less';
import { isUndefined, omitBy } from 'lodash';
import { WrapperField } from './wrapper-field';
import { useSearchParams } from 'react-router-dom';
import { Expanded } from '../../table/expanded';
import { Button } from 'app/components/atoms/button/button';
import { ControlOutlined } from '@ant-design/icons';

/**
 * `Props` type.
 */

type Props = {
  filters: ListFilters;
  appendFilters: (values: any) => void;
  children: JSX.Element;
  hasNested: boolean;
  defaultQueryString: string | undefined;
};

/**
 * Export `FiltersForm` component.
 */

export function FiltersForm({ filters, hasNested, defaultQueryString, appendFilters, children }: Props): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams(defaultQueryString);
  const [initialValues] = useState<any>(() => {
    if (!searchParams.has('filter')) {
      return {};
    }

    const filter = searchParams.get('filter');
    const page = searchParams.get('page');
    const result = JSON.parse(filter ?? '{}');

    return { ...result, page };
  });

  const [showFilters, setShowFilters] = useState(searchParams.get('filterOpened') === 'true');
  const form = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const { watch } = form;
  const [values, setValues] = useState<any>(initialValues);
  const collapsedFilters = useMemo(() => filters.filter(item => !item.inline), [filters]);
  const inlineFilters = useMemo(() => filters.filter(item => !!item.inline), [filters]);

  useEffect(() => {
    const subscription = watch(setValues);

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (hasNested || !initialValues) {
      return;
    }

    for (const key of Object.keys(initialValues)) {
      if (initialValues[key] !== undefined && initialValues[key] !== null) {
        form.setValue(key, initialValues[key]);
      }
    }
  }, [form, hasNested, initialValues]);

  useEffect(() => {
    if (hasNested) {
      return;
    }

    const timer = setTimeout(() => {
      clearTimeout(timer);

      const cleanValues = omitBy(values, value => isUndefined(value));

      setSearchParams(searchParams => {
        searchParams.set('filter', JSON.stringify(cleanValues));
        searchParams.set('filterOpened', String(showFilters));

        return searchParams;
      });

      appendFilters({ filters: cleanValues });
    }, 250);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appendFilters, hasNested, showFilters, values]);

  useEffect(() => {
    if (hasNested) {
      return;
    }

    const data = form.getValues();

    setValues(data);
  }, [form, hasNested]);

  return (
    <>
      <div className={styles.filterForm}>
        <div className={styles.filterWidgets}>
          {!hasNested &&
            inlineFilters.map((item: ListFilter, index: number) => {
              return <WrapperField form={form} item={item} key={index} values={values} />;
            })}
        </div>

        <div className={styles.filterActions}>
          {collapsedFilters.length > 0 && (
            <Button icon={<ControlOutlined />} onClick={() => setShowFilters(!showFilters)} />
          )}

          {children}
        </div>
      </div>

      <Expanded expanded={showFilters}>
        <div className={styles.collapsedWrapper}>
          {!hasNested &&
            collapsedFilters.map((item: ListFilter, index: number) => {
              return <WrapperField form={form} item={item} key={index} values={values} />;
            })}
        </div>
      </Expanded>
    </>
  );
}
