/**
 * Module dependencies.
 */

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * `Props` type.
 */

type Props = {
  children: JSX.Element;
};

/**
 * `CollapseMenu` type.
 */

type CollapseMenu = {
  collapsed: boolean;
  mainMenu: boolean;
  setMainMenu: (mainMenu: boolean) => void;
  setCollapsed: (collapsed: boolean) => void;
};

/**
 * `CollapseMenuContext` context.
 */

const CollapseMenuContext = createContext<CollapseMenu>({
  collapsed: false,
  mainMenu: false,
  setMainMenu: () => null,
  setCollapsed: () => null
});

/**
 * Export `useCollapseMenuProvider`.
 */

export function useCollapseMenuProvider() {
  return useContext(CollapseMenuContext);
}

/**
 * Export `CollapseMenuProvider` component.
 */

export function CollapseMenuProvider({ children }: Props): JSX.Element {
  const [collapsed, setCollapsed] = useState(false);
  const [mainMenu, setMainMenu] = useState(false);
  const loc = useLocation();

  useEffect(() => {
    if (!loc.pathname) {
      return;
    }

    setCollapsed(false);
    setMainMenu(false);
  }, [loc.pathname]);

  useEffect(() => {
    if (mainMenu) {
      setCollapsed(false);
    }

    if (collapsed) {
      setMainMenu(false);
    }
  }, [collapsed, mainMenu]);

  return (
    <CollapseMenuContext.Provider
      value={{
        collapsed,
        mainMenu,
        setMainMenu,
        setCollapsed
      }}
    >
      <div
        onClick={() => {
          setCollapsed(false);
        }}
      >
        {children}
      </div>
    </CollapseMenuContext.Provider>
  );
}
