/**
 * Module dependencies.
 */

import React, { useCallback } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { SliderFormItem } from '../form-types';
import { SliderField } from 'app/components/atoms/forms/fields/slider/slider-field';
import { defaultInputPercentageFormatter, defaultPercentageParser } from 'app/core/utils/currency';

/**
 * `Props` type.
 */

type Props = {
  name: string;
  item: SliderFormItem;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * Export `FormSliderField` component.
 */

export function FormSliderField(props: Props): JSX.Element {
  const { form, item } = props;
  const { control } = form;
  const {
    name,
    placeholder,
    disabled,
    label,
    rules,
    onChange,
    formatter = defaultInputPercentageFormatter,
    parser = defaultPercentageParser
  } = item;
  const handleChange = useCallback(
    (value: any) => {
      if (onChange) {
        onChange(value, form);
      }
    },
    [form, onChange]
  );

  return (
    <SliderField
      control={control}
      disabled={disabled}
      formatter={formatter}
      htmlType={'number'}
      label={label}
      name={name}
      onChange={handleChange}
      parser={parser}
      placeholder={placeholder ?? placeholder === false ? undefined : label}
      rules={rules}
    />
  );
}
