/**
 * Module dependencies.
 */

import { Wrapper as CrudWrapper } from 'app/components/atoms/wrapper/wrapper';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { appRoutes } from 'app/routes';
import React, { useEffect } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

/**
 * `Props` type.
 */

type Props = {
  children: JSX.Element;
  className?: string;
};

/**
 * Export `Wrapper` component.
 */

export function Wrapper({ children, className }: Props): JSX.Element {
  const [searchParams] = useSearchParams();
  const link = searchParams.get('link') ?? '';
  const credentialsId = searchParams.get('credentialsId') ?? '';
  const navigate = useNavigate();

  useEffect(() => {
    if (!link) {
      return;
    }

    navigate({
      pathname: resolveAppPath(appRoutes.dashboard.bankAccounts.accounts.connect, { id: link }),
      search: `?${createSearchParams({ credentialsId })}`
    });
  }, [credentialsId, link, navigate]);

  return <CrudWrapper className={className}>{children}</CrudWrapper>;
}
