/**
 * Module dependencies.
 */

import React from 'react';
import { InputSearch } from '../form-filters-type';
import { FilterOptions } from './types';
import { CheckboxField } from 'app/components/atoms/forms/fields/checkbox/checkbox-field';

/**
 * Export `FilterCheckboxField` component.
 */

export function FilterCheckboxField(props: FilterOptions<InputSearch>): JSX.Element {
  const { disabled, className, fieldClassName, form, item } = props;
  const { placeholder, label, name, style } = item;
  const { control } = form;

  return (
    <div style={{ alignItems: 'center', display: 'flex', paddingTop: 20 }}>
      <CheckboxField
        className={className}
        control={control}
        disabled={disabled}
        fieldClassName={fieldClassName}
        htmlType={'number'}
        label={label}
        name={name}
        placeholder={placeholder ?? placeholder === false ? undefined : label}
        style={style}
      />
    </div>
  );
}
