/**
 * Module dependencies.
 */

import { FormList } from 'app/components/organisms/crud/form-types';
import { requiredRule } from 'app/core/utils/field-rules';
import { useBankAccounts } from 'app/hooks/requests/bank-accounts/use-bank-accounts';
import { BankAccount } from 'app/types/bank-account';
import { formNormal } from './form-normal';
import { useCheckConnectedAccount } from '../hooks/use-check-connected-account';
import { useGetTransactionsByDateRange } from 'app/hooks/requests/transactions/use-get-transactions-by-date-range';
import moment from 'moment';
import { formatCurrency } from 'app/core/utils/formatter';
import { Transaction } from 'app/types/transaction';
import { Alert } from 'antd';
import React from 'react';
import styles from '../movements.module.less';
import { TFunction } from 'react-i18next';

/**
 * Export `switchAccount`.
 */

export const switchAccount = (translate: TFunction, data: any): FormList => [
  {
    type: 'selectField',
    name: 'targetAccountId',
    label: translate('common.labels.targetAccount'),
    rules: requiredRule(translate),
    options: {
      hook: useBankAccounts,
      hookProps: () => [{ append: 'transactions' }],
      normalize: (items: BankAccount[]) => {
        return items?.map(item => ({
          value: item.id,
          label: item.name
        }));
      }
    }
  },
  {
    type: 'if',
    name: 'has_connected_account',
    condition: {
      hook: useCheckConnectedAccount
    },
    thenIf: [
      {
        type: 'if',
        name: 'has_data_to_link',
        condition: (values: any) => values.date && values.amount,
        thenIf: [
          {
            type: 'selectField',
            name: 'targetTransactionId',
            label: translate('common.labels.targetTransaction'),
            rules: requiredRule(translate),
            options: {
              hook: useGetTransactionsByDateRange as any,
              hookProps: (values: any) => [values?.targetAccountId, values?.bank_date, values?.amount],
              normalize: (items: Transaction[]) =>
                items?.map((item: Transaction) => ({
                  value: item.id,
                  label: `${moment(item.date ?? item.bank_date).format('DD-MM-YYYY')}: ${
                    item.description ?? item.bank_description
                  }  -  ${formatCurrency(item.amount)}`
                }))
            }
          }
        ],
        elseIf: [
          {
            type: 'render',
            name: 'invalid_bank_account',
            render: (
              <Alert
                className={styles.warningBox}
                description={translate('bankAccounts.add.missingFields.description')}
                message={translate('bankAccounts.add.missingFields.title')}
                showIcon
                type={'error'}
              />
            )
          }
        ]
      }
    ]
  },
  ...formNormal(translate, data)
];
