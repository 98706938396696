/**
 * Module dependencies.
 */

import React, { useCallback, useState } from 'react';
import styles from './styles.module.less';
import { useBankList } from 'app/hooks/requests/bank-accounts/use-bank-list';
import { Bank } from 'app/types/bank-account';
import { useBankAccountConnectRedirectUrl } from 'app/hooks/requests/bank-accounts/use-bank-account-redirect-url';
import { LocalConnector } from './local-connector';
import { SubmitButton } from 'app/components/atoms/forms/submit-button/submit-button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button } from 'app/components/atoms/button/button';
import { Title } from 'app/components/atoms/title/title';
import { Select } from 'antd';
import { connectBankAccountLocalConnection } from 'app/hooks/requests/bank-accounts/use-bank-account-local-connection';
import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';

/**
 * `Props` type.
 */

type Props = {
  setVisible: (value: boolean) => void;
  isOpen: boolean;
  refresh: () => Promise<void>;
  accountId: string | undefined;
};

/**
 * Export `BankList` component.
 */

export function BankList({ setVisible, accountId, isOpen, refresh }: Props): JSX.Element | null {
  const [translate] = useTranslation();
  const [pinInfo, setPinInfo] = useState<any>(null);
  const [integration, setIntegration] = useState<string>('local');
  const { data: bankList } = useBankList(integration);
  const client = useRequestProvider();
  const [showCredentials, setShowCredentials] = useState<Bank | null>(null);
  const { mutateAsync: getRedirectUrl, isLoading: isLoadingConnectRedirect } = useBankAccountConnectRedirectUrl();
  const createConnection = useCallback(
    async (bank: Bank) => {
      try {
        if (!accountId) {
          return;
        }

        if (bank.provider === 'local') {
          setShowCredentials(bank);
          return;
        }
        const result = await getRedirectUrl({ bank, accountId });

        if (result?.url) {
          window.location.href = result.url;
        }
      } catch (err) {}
    },
    [accountId, getRedirectUrl]
  );

  const { control, handleSubmit, reset } = useForm({
    mode: 'all'
  });

  const onSubmit = useCallback(
    async (data: any) => {
      if (data.username && data.password && !data.pin && showCredentials) {
        const result = await connectBankAccountLocalConnection(client, {
          bank: showCredentials,
          username: data.username,
          password: data.password
        });

        if ((result as any).status === 'connected') {
          await refresh();

          setShowCredentials(null);
          setVisible(false);

          return;
        }

        setPinInfo(result);
        return;
      }

      if (data.username && data.password && data.pin && showCredentials) {
        try {
          await connectBankAccountLocalConnection(client, {
            bank: showCredentials,
            username: data.username,
            password: data.password,
            pin: {
              ...pinInfo,
              pin: data.pin
            }
          });

          await refresh();

          setShowCredentials(null);
          setVisible(false);
        } catch (error) {
          console.log(error);
        }
      }
    },
    [client, pinInfo, refresh, setVisible, showCredentials]
  );

  if (!isOpen) {
    return null;
  }

  if (showCredentials) {
    return (
      <>
        <Title>{'Create connection'}</Title>

        <form onSubmit={handleSubmit(onSubmit)}>
          <img className={styles.bankLogo} src={showCredentials.logo} />
          <LocalConnector control={control as any} showPin={!!pinInfo} />

          <div className={styles.actions}>
            <SubmitButton
              className={styles.submitButton}
              label={translate('bankAccounts.connect.createConnection')}
              loading={false}
            />

            <Button
              className={styles.button}
              isLoading={!!isLoadingConnectRedirect}
              onClick={() => {
                setVisible(false);
                setShowCredentials(null);
                reset();
              }}
            >
              {translate('common.actions.cancel')}
            </Button>
          </div>
        </form>
      </>
    );
  }

  return (
    <>
      <Title>{'Create integration'}</Title>

      <Title level={'h3'}>{'Select integration provider'}</Title>

      <div className={styles.actions}>
        <Select
          className={styles.select}
          defaultValue={'lucy'}
          onChange={item => setIntegration(item)}
          options={[
            { value: 'local', label: 'Local' },
            { value: 'enableBanking', label: 'Enable Banking' },
            { value: 'nordigen', label: 'Nordigen' }
          ]}
          value={integration}
        />

        <Button
          className={styles.button}
          isLoading={!!isLoadingConnectRedirect}
          onClick={() => {
            setPinInfo(null);
            setVisible(false);
            setShowCredentials(null);
            reset();
          }}
        >
          {translate('common.actions.cancel')}
        </Button>
      </div>

      <Title className={styles.integrationTitle} level={'h3'}>
        {'Select Bank connection'}
      </Title>

      <div className={styles.bankList}>
        {bankList?.map(item => (
          <div
            className={styles.bankItem}
            key={item.name}
            onClick={() => {
              createConnection(item);
            }}
          >
            <img className={styles.bankLogo} src={item.logo} />
            <div className={styles.bankName}>{item.name}</div>
          </div>
        ))}
      </div>
    </>
  );
}
