/**
 * Module dependencies.
 */

import React, { ComponentType, Fragment, useEffect, useMemo } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FilterSelectField } from './filter-components/filter-select-field';
import { FormListFilter, ListFilter } from './form-filters-type';
import { FilterCheckboxField } from './filter-components/filter-checkbox-field';
import styles from '../crud.module.less';
import { FilterInputSearchField } from './filter-components/filter-input-seach-field';

/**
 * `Props` type.
 */

type Props = {
  item: ListFilter;
  form: UseFormReturn<FieldValues, any>;
  values: any;
};

/**
 * Components.
 */

const components: Record<FormListFilter, ComponentType<any>> = {
  inputSearch: FilterInputSearchField,
  selectSearch: FilterSelectField,
  checkboxSearch: FilterCheckboxField
};

/**
 * Export `WrapperField` component.
 */

export function WrapperField({ item, form, values }: Props): JSX.Element {
  const Component = components[item.type] ?? Fragment;
  const { watch } = form;
  const { onChange } = item;
  const value = watch(item.name);
  const hasDisabled = useMemo(() => {
    return typeof item.disabled === 'function' ? item.disabled(values ?? {}) : item.disabled;
  }, [item, values]);

  useEffect(() => {
    if (!onChange) {
      return;
    }

    onChange(value, form);
  }, [form, onChange, value]);

  return (
    <Component
      className={styles.formField}
      disabled={hasDisabled}
      fieldClassName={styles.field}
      form={form}
      item={item}
    />
  );
}
