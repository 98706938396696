/**
 * Export `resources`.
 */

export const resources = {
  dev: {
    common: require('app/locales/dev/common.json')
  },
  en: {
    common: require('app/locales/en/common.json')
  }
};
