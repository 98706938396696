/**
 * Module dependencies.
 */

import React, { forwardRef } from 'react';
import { InputNumber as AntdInputNumber, InputNumberProps as AntdInputNumberProps } from 'antd';
import { FormField } from '../../form-field/form-field';
import { ErrorField } from '../../error-field/error-field';
import styles from './input-number.module.less';
import classnames from 'classnames';
import { omit } from 'lodash';

/**
 * `InputNumberProps` type.
 */

export type InputNumberProps = Omit<AntdInputNumberProps, 'error'> & {
  error: any;
  className?: string;
  parser?: (formValues: any) => AntdInputNumberProps['parser'];
  formatter?: (formValues: any) => AntdInputNumberProps['formatter'];
  invalid?: boolean;
  isDirty?: boolean;
  label: string;
  isTouched?: boolean;
};

/**
 * Export `InputNumberComponent` component.
 */

export function InputNumberComponent(props: InputNumberProps, forwardedRef: any): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, invalid, isTouched, isDirty, label, ...rest } = props;
  const { error } = rest;

  return (
    <FormField className={className} label={label}>
      <AntdInputNumber
        className={classnames(styles.wrapper, {
          [styles.disabled]: rest.disabled
        })}
        ref={forwardedRef}
        {...omit(rest, ['htmlType'])}
      />

      <ErrorField error={error} isVisible={invalid || isTouched} />
    </FormField>
  );
}

/**
 * Export `InputNumber` component.
 */

export const InputNumber = forwardRef<any, InputNumberProps>(InputNumberComponent);
