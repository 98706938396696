// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.local-connector-module__wrapper--MRfaa {
  padding-top: 32px;
}
`, "",{"version":3,"sources":["webpack://./components/pages/bank-accounts/bank-accounts/bank-account-connect/components/local-connector.module.less"],"names":[],"mappings":"AACA;EACE,iBAAA;AAAF","sourcesContent":["\n.wrapper {\n  padding-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `local-connector-module__wrapper--MRfaa`
};
export default ___CSS_LOADER_EXPORT___;
