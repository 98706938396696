/**
 * Module dependencies.
 */

import { Button } from 'app/components/atoms/button/button';
import { useLogout } from 'app/hooks/requests/use-logout';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useState } from 'react';
import { Title } from 'app/components/atoms/title/title';
import moment from 'moment';
import { useBackup } from 'app/hooks/requests/settings/use-backup';
import { Field } from 'app/components/organisms/field/field';
import { cookieStorage } from 'app/core/utils/cookie-storage';
import { Input } from 'antd';
import { useRestore } from 'app/hooks/requests/settings/use-restore';

/**
 * Export `SettingsProfile` component.
 */

export function SettingsProfile(): JSX.Element {
  const { mutateAsync: backup } = useBackup();
  const { mutateAsync: restore } = useRestore();
  const handleBackup = useCallback(() => {
    backup().then((data: any) => {
      const blob = new Blob([data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `backup_${moment().toISOString()}.sql`;
      link.click();
    });
  }, [backup]);

  const [translate] = useTranslation();
  const { mutate: logout } = useLogout();
  const user = useMemo(() => {
    return cookieStorage.get('user');
  }, []);

  const [code, setCode] = useState('');
  const handleRestore = useCallback(async () => {
    try {
      await restore(code);

      alert('Done!');
    } catch (error) {
      alert('something bad happened!');
    }
  }, [code, restore]);

  return (
    <>
      <Title>{'Profile'}</Title>

      <div style={{ maxWidth: 500 }}>
        <Field title={translate('settings.profile.items.firstName')} value={user.firstName} />

        <Field title={translate('settings.profile.items.lastName')} value={user.lastName} />

        <Field title={translate('settings.profile.items.email')} value={user.email} />

        <Field title={translate('settings.profile.items.authMethod')} value={user.auth} />
      </div>

      {user.id === 1 && (
        <>
          <Title level={'h3'} style={{ marginTop: 32 }}>
            {'Restore'}
          </Title>

          <Input.TextArea onChange={item => setCode(item.target.value)} rows={8} value={code} />

          <Button disabled={!code} onClick={handleRestore} type={'primary'}>
            {translate('common.actions.restore')}
          </Button>
        </>
      )}

      <div
        style={{
          gap: 8,
          display: 'flex',
          marginTop: 32
        }}
      >
        {user.id === 1 && <Button onClick={handleBackup}>{'backup'}</Button>}

        <Button onClick={() => logout()} type={'primary'}>
          {translate('common.actions.logout')}
        </Button>
      </div>
    </>
  );
}
