/**
 * Module dependencies.
 */

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import {
  CrudAddType,
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  FormList,
  ListColumns
} from 'app/components/organisms/crud/form-types';
import { normalizeTemplate } from 'app/components/organisms/crud/utils';

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { requiredRule } from 'app/core/utils/field-rules';
import { useCrudRequest } from 'app/hooks/requests/crud/use-crud-request';
import { appRoutes } from 'app/routes';
import { Category } from 'app/types/category';
import { SubCategory } from 'app/types/sub-category';
import { TFunction } from 'i18next';
import { pick } from 'lodash';
import moment from 'moment';
import React from 'react';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'id',
    size: '30px',
    key: 'id'
  },
  {
    title: translate('common.table.columns.category'),
    dataIndex: 'category.name',
    key: 'category',
    size: '1fr',
    render: (item: SubCategory) => <div>{item.category?.name}</div>
  },
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'name',
    size: '1fr',
    key: 'name'
  },
  {
    title: translate('common.table.columns.reportsAvailable'),
    dataIndex: 'doNotUseInReports',
    key: 'doNotUseInReports',
    style: { justifyContent: 'center' },
    render: (item: SubCategory) => <div>{!item.doNotUseInReports ? '✅' : '❌'}</div>
  },
  {
    title: translate('common.table.columns.closed'),
    dataIndex: 'closedDate',
    key: 'closedDate',
    style: { justifyContent: 'center' },
    render: (item: SubCategory) => <div>{!!item.closedDate ? moment(item.closedDate).format('YYYY-MM') : ''}</div>
  }
];

/**
 * Form list.
 */

const formFields = (translate: TFunction, edit: boolean): FormList => [
  {
    type: 'selectField',
    name: 'categoryId',
    label: translate('common.labels.category'),
    rules: requiredRule(translate),
    options: {
      hook: useCrudRequest as any,
      hookProps: (values: any, params: any) => {
        return [
          {
            key: ['categories'],
            options: {
              interpolations: params
            },
            endpoint: apiEndpoints.categories
          }
        ];
      },
      normalize: (items: Category[]) => {
        return items?.map(item => ({
          value: item.id,
          label: item.name
        }));
      }
    }
  },
  {
    type: 'if',
    name: 'need_category_id',
    watchFields: ['categoryId'],
    condition: ([categoryId]: any) => !!categoryId,
    thenIf: [
      {
        type: 'inputField',
        name: 'name',
        label: translate('common.labels.name'),
        rules: requiredRule(translate)
      }
    ]
  },
  {
    type: 'checkBoxField',
    name: 'doNotUseInReports',
    label: translate('common.labels.doNotUseInReports'),
    rules: undefined
  },
  ...(!edit
    ? ([] as any)
    : [
        {
          type: 'checkBoxField',
          name: 'closedCategory',
          label: translate('common.labels.closedCategory'),
          rules: undefined
        },
        {
          type: 'datePickerField',
          name: 'closedDate',
          visible: (values: any) => !!values.closedCategory,
          label: translate('common.labels.closedDate'),
          rules: requiredRule(translate)
        }
      ])
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: String(translate('common.labels.search'))
  },
  {
    type: 'selectSearch',
    name: 'category',
    inline: true,
    label: String(translate('common.labels.categories')),
    showSearch: true,
    allowClear: true,
    options: {
      hook: useCrudRequest as any,
      hookProps: (values: any, params: any) => {
        return [
          {
            key: ['categories'],
            options: {
              interpolations: params
            },
            endpoint: apiEndpoints.categories
          }
        ];
      },
      normalize: (items: Category[]) => [
        {
          label: String(translate('common.labels.empty')),
          value: null
        },
        ...(items?.map(item => ({
          value: item.id,
          label: item.name
        })) ?? [])
      ]
    }
  }
];

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType => ({
  formFields: formFields(translate, false),
  normalizePayload: (values: any) => ({
    ...values,
    doNotUseInReports: !!values.doNotUseInReports
  })
});

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType => ({
  formFields: formFields(translate, true),
  normalizeInitialValues: (formkeys, values: any) => {
    return {
      ...(values ?? {}),
      closedCategory: !!values?.closedDate,
      closedDate: !!values?.closedDate ? moment(values.closedDate) : null
    };
  },
  normalizePayload: (values: any) => {
    const result = {
      ...pick(values, ['id', 'categoryId', 'type', 'name']),
      doNotUseInReports: !!values.doNotUseInReports,
      closedDate: !!values.closedCategory ? values.closedDate.format('YYYY-MM-DD HH:mm:ss') : null
    };

    //data.date.format('YYYY-MM-DD HH:mm:ss')
    console.log('>>', result);

    return result;
  }
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType => ({});

/**
 * List.
 */

const list = (translate: TFunction): CrudListType => ({
  columns: listColumns(translate),
  route: appRoutes.dashboard.bankAccounts.subCategories.base,
  key: ['subCategories'],
  endpoint: apiEndpoints.subCategories,
  filters: listFilters(translate)
});

/**
 * Config.
 */

export function createSubCategoriesTemplate(translate: TFunction): CrudTemplate {
  return normalizeTemplate(translate, {
    add: add(translate),
    edit: edit(translate),
    list: list(translate),
    remove: remove()
  });
}
