/**
 * Module dependencies.
 */

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import {
  CrudAddType,
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  FormList,
  ListColumns
} from 'app/components/organisms/crud/form-types';
import { normalizeTemplate } from 'app/components/organisms/crud/utils';

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { requiredRule } from 'app/core/utils/field-rules';
import { formatCurrency } from 'app/core/utils/formatter';
import { useCrudRequest } from 'app/hooks/requests/crud/use-crud-request';
import { appRoutes } from 'app/routes';
import { ProjectBudget, ProjectSupplier } from 'app/types/project';
import { TableFooter } from './table-footer';
import { TFunction } from 'i18next';
import { omit } from 'lodash';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import React from 'react';
import { useGetTransactionsByDateRange } from 'app/hooks/requests/transactions/use-get-transactions-by-date-range';
import { Transaction } from 'app/types/transaction';
import { TransactionItem } from './transaction-item';
import styles from './transaction-item/styles.module.less';
import { useFormLoadProject } from 'app/hooks/use-form-load-project';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'id',
    size: '30px',
    key: 'id'
  },
  {
    title: translate('common.table.columns.date'),
    dataIndex: 'date',
    key: 'date',
    render: item => moment(item.date).format('DD-MM-YYYY')
  },
  {
    title: translate('common.table.columns.description'),
    dataIndex: 'description',
    size: '1fr',
    style: { whiteSpace: 'nowrap' },
    key: 'description'
  },
  {
    title: translate('common.table.columns.budgetName'),
    dataIndex: 'budget',
    key: 'budget',
    render: item => item.budget.name
  },

  {
    title: translate('common.table.columns.amountWithoutTax'),
    dataIndex: 'amount',
    key: 'amount',
    style: { justifyContent: 'flex-end' },
    styleRow: { fontFamily: 'Oswald' },
    render: item => formatCurrency(item.amount)
  },
  {
    title: translate('common.table.columns.taxAmount'),
    dataIndex: 'hasTax',
    key: 'hasTax',
    style: { justifyContent: 'center' },
    render: item => (item.hasTax ? '✅' : '')
  },
  {
    title: translate('common.table.columns.total'),
    dataIndex: 'total',
    key: 'total',
    style: { justifyContent: 'flex-end' },
    styleRow: { fontFamily: 'Oswald' },
    render: item => formatCurrency(new BigNumber(item.total).toFixed(2))
  },
  {
    title: translate('common.table.columns.paid'),
    dataIndex: 'paid',
    key: 'paid',
    style: { justifyContent: 'center' },
    render: item => (item.paid ? '✅' : '❌')
  },
  {
    title: translate('common.table.columns.paymentDate'),
    dataIndex: 'paymentDate',
    key: 'paymentDate',
    style: { justifyContent: 'flex-end' },
    render: item => (!item.paymentDate ? 'N/A' : moment(item.paymentDate).format('DD-MM-YYYY'))
  },
  {
    title: translate('common.table.columns.paymentType'),
    dataIndex: 'paymentType',
    key: 'paymentType',
    style: { justifyContent: 'flex-end' },
    render: item => item.paymentType?.name ?? 'N/A'
  }
];

/**
 * Form list.
 */

const formFields = (translate: TFunction): FormList => [
  {
    type: 'columns',
    name: 'mainWire',
    columns: [
      {
        column: 8,
        children: [
          {
            type: 'selectField',
            name: 'budgetId',
            showSearch: true,
            label: translate('common.labels.budgetItem'),
            rules: requiredRule(translate),
            options: {
              hook: useCrudRequest as any,
              hookProps: (values, params) => {
                return [
                  {
                    key: ['movements', params.projectId],
                    options: { interpolations: params },
                    endpoint: apiEndpoints.projectBudgets
                  }
                ];
              },
              normalize: (items: ProjectBudget[]) => {
                return (
                  items?.map(item => ({
                    value: item.id,
                    label: item.name
                  })) ?? []
                );
              }
            }
          },
          {
            type: 'datePickerField',
            name: 'date',
            label: translate('common.labels.date'),
            rules: requiredRule(translate)
          },
          {
            type: 'inputField',
            name: 'description',
            label: translate('common.labels.description'),
            rules: requiredRule(translate)
          },
          {
            type: 'columns',
            name: 'amountWire',
            columns: [
              {
                column: 8,
                children: [
                  {
                    type: 'loadHooks',
                    hooks: [
                      {
                        hookName: 'project',
                        hookProps: [],
                        hook: useFormLoadProject
                      }
                    ],
                    name: 'loadProject',
                    formFields: [
                      {
                        type: 'inputNumberField',
                        name: 'amount',
                        label: translate('common.labels.amount'),
                        rules: requiredRule(translate),
                        watchChange: {
                          watchFields: ['amount', 'hasTax'],
                          onChange: (values: any, form: any) => {
                            const iva = form?.cache?.hooks?.project?.defaultIVA;
                            const [amount, hasTax] = values;
                            const ivaPerc = hasTax ? iva ?? '0' : '0';
                            const total = new BigNumber(amount).multipliedBy(ivaPerc).dividedBy(100).plus(amount);

                            form.setValue('total', total.isNaN() ? undefined : total.toFixed(2));
                          }
                        }
                      }
                    ]
                  }
                ]
              },
              {
                column: 8,
                children: [
                  {
                    type: 'inputNumberField',
                    name: 'total',
                    disabled: true,
                    label: translate('common.labels.total'),
                    rules: requiredRule(translate)
                  }
                ]
              }
            ]
          },
          {
            type: 'columns',
            name: 'checkboxWire',
            columns: [
              {
                column: 12,
                children: [
                  {
                    type: 'checkBoxField',
                    name: 'hasTax',
                    label: translate('common.labels.withTax'),
                    rules: undefined,
                    watchChange: {
                      watchFields: ['hasTax'],
                      onChange: (values: any, form: any) => {
                        const amountFieldState = form.getFieldState('amount');
                        const hasTaxFieldState = form.getFieldState('hasTax');

                        if (!amountFieldState.isDirty && !hasTaxFieldState.isDirty) {
                          return;
                        }

                        const amount = form.getValues()?.amount;
                        const totalForm = form.getValues()?.total;
                        const [hasTax] = values;
                        const iva = form?.cache?.hooks?.project?.defaultIVA;
                        let amountResult = new BigNumber(amount);
                        let total = new BigNumber(amount);
                        const ivaCalc = new BigNumber(iva).dividedBy(100).plus(1);

                        if (hasTax) {
                          amountResult = new BigNumber(amount).dividedBy(ivaCalc);
                          total = new BigNumber(amount);
                        } else {
                          amountResult = new BigNumber(totalForm).multipliedBy(ivaCalc);
                          total = new BigNumber(totalForm).multipliedBy(ivaCalc);
                        }

                        form.setValue('total', total.isNaN() ? undefined : total.toFixed(2));
                        form.setValue('amount', amountResult.isNaN() ? undefined : amountResult.toFixed(2));

                        console.log(amount, totalForm, hasTax, amountResult.toFixed(2), ivaCalc.toFixed(2));
                      }
                    }
                  }
                ]
              },
              {
                column: 12,
                children: [
                  {
                    type: 'checkBoxField',
                    name: 'paid',
                    label: translate('common.labels.hasPaid'),
                    rules: undefined
                  }
                ]
              }
            ]
          },

          {
            type: 'if',
            name: 'need_category_id',
            watchFields: ['paid'],
            condition: ([paid]: any) => !!paid,
            thenIf: [
              {
                type: 'datePickerField',
                name: 'paymentDate',
                label: translate('common.labels.paymentDate'),
                rules: requiredRule(translate)
              },
              {
                type: 'selectField',
                name: 'paymentTypeId',
                showSearch: true,
                label: translate('common.labels.paymentType'),
                rules: requiredRule(translate),
                options: {
                  hook: useCrudRequest as any,
                  hookProps: (values, params) => {
                    return [
                      {
                        key: ['movements', 'paymentType', params.projectId],
                        options: { interpolations: params },
                        endpoint: apiEndpoints.projectPaymentTypes
                      }
                    ];
                  },
                  normalize: (items: ProjectBudget[]) => {
                    return (
                      items?.map(item => ({
                        value: item.id,
                        label: item.name
                      })) ?? []
                    );
                  }
                }
              },
              {
                type: 'selectField',
                className: styles.selectBankAccount,
                name: 'transactionId',
                optionLabelProp: 'minimal',
                label: translate('common.labels.targetTransaction'),
                rules: requiredRule(translate),
                options: {
                  hook: useGetTransactionsByDateRange as any,
                  hookProps: (values: any) => {
                    const total = new BigNumber(values?.total).toFixed(2);

                    return [-1, values?.paymentDate, total];
                  },
                  normalize: (items: Transaction[]) =>
                    items?.map((item: Transaction) => ({
                      value: item.id,
                      minimal: `${moment(item.date ?? item.bank_date).format('DD-MM-YYYY')}: ${
                        item.description ?? item.bank_description
                      }:  ${formatCurrency(item.amount)}`,
                      label: <TransactionItem item={item} />
                    }))
                }
              }
            ]
          }
        ]
      }
    ]
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'selectSearch',
    name: 'budgetId',
    inline: true,
    style: {
      minWidth: 250
    },
    label: translate('common.labels.budgetItem'),
    showSearch: true,
    allowClear: true,
    options: {
      hook: useCrudRequest as any,
      hookProps: (values, params) => {
        return [
          {
            key: ['budgets', params.projectId],
            options: { interpolations: params },
            endpoint: apiEndpoints.projectBudgets
          }
        ];
      },
      normalize: (items: ProjectBudget[]) => {
        return (
          items?.map(item => ({
            value: item.id,
            label: item.name
          })) ?? []
        );
      }
    }
  },
  {
    type: 'selectSearch',
    name: 'supplierId',
    inline: true,
    style: {
      minWidth: 250
    },
    label: translate('common.labels.supplier'),
    showSearch: true,
    allowClear: true,
    options: {
      hook: useCrudRequest as any,
      hookProps: (values, params) => {
        return [
          {
            key: ['supplier', params.projectId],
            options: { interpolations: params },
            endpoint: apiEndpoints.projectSuppliers
          }
        ];
      },
      normalize: (items: ProjectSupplier[]) => {
        return (
          items?.map(item => ({
            value: item.id,
            label: item.company
          })) ?? []
        );
      }
    }
  }
];

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType => ({
  formFields: formFields(translate),
  normalizePayload: (values: any) => {
    return {
      ...values,
      amount: new BigNumber(values.amount).toString(),
      date: values.date.format('YYYY-MM-DD HH:mm:ss'),
      ...(!values.paymentDate
        ? {}
        : {
            paymentDate: values.paymentDate.format('YYYY-MM-DD HH:mm:ss')
          })
    };
  }
});

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType => ({
  formFields: formFields(translate),
  normalizeInitialValues: (keys: any, values: any) => {
    return {
      ...values,
      date: values?.date ? moment(values.date) : undefined,
      paymentDate: values?.paymentDate ? moment(values.paymentDate) : undefined
    };
  },
  normalizePayload: (values: any) => {
    return {
      ...omit(values, ['userId', 'projectId', 'project', 'budget', 'paymentType']),
      amount: new BigNumber(values.amount).toString(),
      paid: !!values.paid,
      hasTax: !!values.hasTax,
      date: values.date.format('YYYY-MM-DD HH:mm:ss'),
      ...(!values.paymentDate
        ? {}
        : {
            paymentDate: values.paymentDate.format('YYYY-MM-DD HH:mm:ss')
          })
    };
  }
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType => ({});

/**
 * List.
 */

const list = (translate: TFunction): CrudListType => ({
  columns: listColumns(translate),
  style: { minWidth: 500 },
  route: appRoutes.dashboard.projects.project.movements,
  key: ['projectMovements'],
  canRemove: item => !item.selectedBudget,
  endpoint: apiEndpoints.projectMovements,
  filters: listFilters(translate),
  renderLastLine: props => <TableFooter {...props} />
});

/**
 * Config.
 */

export function createProjectMovementsTemplate(translate: TFunction): CrudTemplate {
  return normalizeTemplate(translate, {
    add: add(translate),
    edit: edit(translate),
    list: list(translate),
    remove: remove()
  });
}
