/**
 * Module dependencies.
 */

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import {
  CrudAddType,
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  ListColumns
} from 'app/components/organisms/crud/form-types';

import { normalizeTemplate } from 'app/components/organisms/crud/utils';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import { appRoutes } from 'app/routes';
import { TFunction } from 'i18next';
import { formFields } from './form-fields';
import { omit, toLower } from 'lodash';
import BigNumber from 'bignumber.js';
import { formatCurrency } from 'app/core/utils/formatter';
import { TableFooter } from './table-footer';
import React from 'react';
import styles from './assets.module.less';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.wallet'),
    dataIndex: 'wallet',
    size: '1fr',
    key: 'wallet',
    render: (item: any) => item.wallet.name
  },

  {
    title: translate('common.table.columns.coin'),
    dataIndex: 'coin',
    key: 'coin',
    size: '1fr',
    render: item => {
      const src = `https://coinicons-api.vercel.app/api/icon/${toLower(item.coin)}`;

      return (
        <div className={styles.coinWrapper}>
          <img alt={item.name} height={'24'} src={src} width={'24'} />

          <span className={styles.label}>{item.coin}</span>
        </div>
      );
    }
  },
  {
    title: translate('common.table.columns.amount'),
    dataIndex: 'amount',
    style: { justifyContent: 'flex-end' },
    styleRow: { fontFamily: 'Oswald' },
    key: 'amount'
  },
  {
    title: translate('common.table.columns.priceUSDT'),
    style: { justifyContent: 'flex-end' },
    styleRow: { fontFamily: 'Oswald', whiteSpace: 'nowrap' },
    dataIndex: 'usdPrice',
    key: 'usdPrice',
    render: (item: any) => formatCurrency(item.usdPrice, { currency: 'USD', locale: 'en' })
  },
  {
    title: translate('common.table.columns.priceUSDT'),
    style: { justifyContent: 'flex-end' },
    styleRow: { fontFamily: 'Oswald', whiteSpace: 'nowrap' },
    dataIndex: 'valueUSD',
    key: 'valueUSD',
    render: (item: any) => {
      const value = new BigNumber(item.amount).multipliedBy(item.usdPrice).toString();

      return formatCurrency(value, { currency: 'USD', locale: 'en' });
    }
  },
  {
    title: translate('common.table.columns.priceEUR'),
    style: { justifyContent: 'flex-end' },
    styleRow: { fontFamily: 'Oswald', whiteSpace: 'nowrap' },
    dataIndex: 'eurPrice',
    key: 'eurPrice',
    render: (item: any) => formatCurrency(item.eurPrice, { currency: 'EUR', locale: 'en' })
  },
  {
    title: translate('common.table.columns.priceEUR'),
    style: { justifyContent: 'flex-end' },
    styleRow: { fontFamily: 'Oswald', whiteSpace: 'nowrap' },
    dataIndex: 'valueEUR',
    key: 'valueEUR',
    render: (item: any) => {
      const value = new BigNumber(item.amount).multipliedBy(item.eurPrice).toString();

      return formatCurrency(value, { currency: 'EUR', locale: 'en' });
    }
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: String(translate('common.labels.search'))
  }
];

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType => ({
  formFields: formFields(translate),
  normalizePayload: (values: any) => ({
    ...values,
    amount: new BigNumber(values.amount).toString()
  })
});

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType => ({
  formFields: formFields(translate),
  normalizePayload: (values: any) => ({
    ...omit(values, ['userId', 'wallet']),
    amount: new BigNumber(values.amount).toString()
  })
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType => ({});

/**
 * List.
 */

const list = (translate: TFunction): CrudListType => ({
  columns: listColumns(translate),
  route: appRoutes.dashboard.crypto.assets,
  key: ['cryptoAssets'],
  renderLastLine: props => <TableFooter {...props} />,
  endpoint: apiEndpoints.cryptoAssets,
  filters: listFilters(translate)
});

/**
 * Config.
 */

export function createCryptoAssetsTemplate(translate: TFunction): CrudTemplate {
  return normalizeTemplate(translate, {
    add: add(translate),
    edit: edit(translate),
    list: list(translate),
    remove: remove()
  });
}
