/**
 * Module dependencies.
 */

import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

/**
 * Export `useHackMobileBrowser`.
 */

export function useHackMobileBrowser() {
  useEffect(() => {
    if (!isMobile) {
      document.documentElement.style.setProperty('--vh', 'initial');

      return;
    }

    const vh = window.innerHeight - 1;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    function resize() {
      // We execute the same script as before
      const vh = window.innerHeight - 1;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // We listen to the resize event
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);
}
