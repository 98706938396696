/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';

/**
 * Connect bank account delete connection.
 */

function connectBankAccountDeleteConnection(
  client: AxiosInstance,
  bankAccount: string,
  soft: boolean
): Promise<{ url: string; showLocalCredentials: boolean }> {
  return client({
    method: 'delete',
    url: resolveAppUrl('bankAccountConnectDeleteConnection', { interpolations: { bankAccount } }),
    params: { soft }
  }).then(({ data }) => {
    return data?.data;
  });
}

/**
 * Export `useBankAccountDeleteConnection`.
 */

export function useBankAccountDeleteConnection() {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async ({ bankAccount, soft }: { bankAccount: string; soft: boolean }) => {
      const result = await connectBankAccountDeleteConnection(client, bankAccount, soft);

      return result;
    },
    onSuccess: () => {
      // Nothing to do.
    }
  });
}
