/**
 * Module dependencies.
 */

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import styles from './home.module.less';
import { useGoogleLogin } from 'app/hooks/requests/use-google-login';
import { appRoutes } from 'app/routes';
import { cookieStorage } from 'app/core/utils/cookie-storage';

/**
 * Export `Home` component.
 */

export function Home(): JSX.Element {
  const navigate = useNavigate();
  const { mutate: login } = useGoogleLogin({
    onSuccess: () => {
      navigate(appRoutes.dashboard.home.base);
    }
  });

  useEffect(() => {
    const user = cookieStorage.get('user');

    if (user) {
      navigate(appRoutes.dashboard.home.base);
    }
  }, [navigate]);

  return (
    <div className={styles.login}>
      <GoogleLogin
        onError={() => {
          console.log('Login Failed');
        }}
        onSuccess={credentialResponse => {
          login(credentialResponse);
        }}
      />
    </div>
  );
}
