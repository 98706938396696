// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__closed--dwqzM:after {
  content: 'closed';
  background-color: red;
  padding: 3px 6px;
  margin-left: 12px;
  border-radius: 8px;
  font-size: 12px;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./components/pages/projects/project/budgets/components/styles.module.less"],"names":[],"mappings":"AAIE;EACE,iBAAA;EACA,qBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;AAHJ","sourcesContent":["\n\n.closed {\n\n  &:after {\n    content: 'closed';\n    background-color: red;\n    padding: 3px 6px;\n    margin-left: 12px;\n    border-radius: 8px;\n    font-size: 12px;\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"closed": `styles-module__closed--dwqzM`
};
export default ___CSS_LOADER_EXPORT___;
