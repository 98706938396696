/**
 * Module dependencies.
 */

import { Layout, Menu } from 'antd';
import { MenuItem } from 'app/types/navigation';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { resolveSelectedKeys } from './dashboard-header';
const { Content, Footer, Sider } = Layout;
import styles from './dashboard.module.less';
import { Trans } from 'react-i18next';
import { useCollapseMenuProvider } from 'app/components/providers/collapse-menu-provider/collapse-menu-provider';
import classnames from 'classnames';
import useBreakpoints from '@thebiltheory/usebreakpoints';

/**
 * `Props` type.
 */

type Props = {
  menu: MenuItem[];
  children: JSX.Element;
};

/**
 * Export `Dashboard` component.
 */

export function Dashboard({ children, menu }: Props): JSX.Element | null {
  const { pathname } = useLocation();
  const [, currentBreakpoint] = useBreakpoints(['mobile', 'tablet', 'desktop', 'screen'], [576, 768, 992, 1200]);
  const { collapsed } = useCollapseMenuProvider();
  const selectedItem = useMemo(() => resolveSelectedKeys(pathname, menu), [menu, pathname]);
  const openKeys = useMemo(() => {
    return selectedItem.parent ? [String(selectedItem.parent.key)] : undefined;
  }, [selectedItem.parent]);

  const isMobile = parseFloat(String(currentBreakpoint)) < 1200;

  return currentBreakpoint === 0 ? null : (
    <Layout className={styles.layout}>
      <Sider
        breakpoint={'lg'}
        className={classnames(styles.sider, {
          [styles.isMobile]: isMobile
        })}
        collapsed={isMobile && !collapsed}
        collapsedWidth={'0'}
        onClick={event => {
          event.stopPropagation();
        }}
        trigger={null}
        width={280}
      >
        <div className={'logo'} />

        <Menu
          className={styles.menu}
          items={menu}
          mode={'inline'}
          openKeys={openKeys}
          selectedKeys={[(selectedItem?.key as string) ?? '']}
          theme={'light'}
        />
      </Sider>

      <Layout>
        <Content
          className={classnames(styles.contentWrapper, {
            [styles.isDesktop]: !isMobile
          })}
        >
          <div className={styles.content}>{children}</div>
        </Content>

        <Footer className={styles.footer}>
          <Trans
            i18nKey={'copyright'}
            values={{
              year: new Date().getFullYear()
            }}
          />
        </Footer>
      </Layout>
    </Layout>
  );
}
