/**
 * Module dependencies.
 */

import { Title } from 'app/components/atoms/title/title';
import { StatsItem } from 'app/components/organisms/stats-item/stats-item';
import { formatCurrency } from 'app/core/utils/formatter';
import BigNumber from 'bignumber.js';
import { reduce } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * `Props` type.
 */

type Props = {
  incomeVsExpenses: any;
  yearExpenses: any;
  yearIncomes: any;
};

/**
 * Export `YearStats` component.
 */

export function YearStats({ incomeVsExpenses }: Props): JSX.Element {
  const [translate] = useTranslation();
  const stats = useMemo(() => {
    const income = reduce(
      incomeVsExpenses.data[0].dataPoints,
      (acc, value) => {
        return acc.plus(value.y ?? '0');
      },
      new BigNumber('0')
    );

    const expense = reduce(
      incomeVsExpenses.data[1].dataPoints,
      (acc, value) => {
        return acc.plus(value.y ?? '0');
      },
      new BigNumber('0')
    );

    const diff = new BigNumber(income).minus(expense);
    const perc = new BigNumber(expense).multipliedBy(100).dividedBy(income);

    return { income, expense, diff, perc: new BigNumber(100).minus(perc) };
  }, [incomeVsExpenses.data]);

  return (
    <>
      <Title>{translate('bankAccounts.overview.stats.details.year.title')}</Title>

      <StatsItem label={translate('common.labels.income')} value={formatCurrency(stats.income)} />

      <StatsItem label={translate('common.labels.expenses')} value={formatCurrency(stats.expense)} />

      <StatsItem label={translate('common.labels.savings')} value={formatCurrency(stats.diff)} />

      <StatsItem label={translate('common.labels.savingsPerc')} value={`${stats.perc.toFixed(2)}%`} />
    </>
  );
}
