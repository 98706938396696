/**
 * Module dependencies.
 */

import { Button as AntdButton, ButtonProps } from 'antd';
import React, { useCallback, useState } from 'react';
import isPromise from 'is-promise';
import classNames from 'classnames';
import styles from './button.module.less';

/**
 * `Props` type.
 */

type Props = ButtonProps & {
  isLoading?: boolean;
  setLoading?: (value: boolean) => void;
};

/**
 * Export `Button` component.
 */

export function Button(props: Props): JSX.Element {
  const [isInternalLoading, setInternalLoading] = useState<boolean>(false);
  const {
    className,
    isLoading = isInternalLoading,
    loading,
    icon,
    onClick,
    setLoading = setInternalLoading,
    ...rest
  } = props;
  const handleClick = useCallback(
    async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!onClick) {
        return;
      }

      try {
        const result = onClick(event);

        if (isPromise(result)) {
          setLoading(true);

          if (setInternalLoading) {
            setInternalLoading(true);
          }

          await (result as unknown as Promise<unknown>);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);

        if (setInternalLoading) {
          setInternalLoading(false);
        }
      }
    },
    [onClick, setLoading]
  );

  return (
    <AntdButton
      className={classNames(styles.wrapper, className, {
        [styles.icon]: !!icon,
        [styles.href]: !!rest.href
      })}
      icon={icon}
      loading={loading || isLoading}
      onClick={handleClick}
      {...rest}
    />
  );
}
