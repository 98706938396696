/**
 * Module dependencies.
 */

import { Button } from 'app/components/atoms/button/button';
import React, { useCallback } from 'react';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { CrudTemplate } from '../crud/form-types';
import { useCrudEdit } from 'app/hooks/requests/crud/use-crud-edit';
import { useParams } from 'react-router-dom';

/**
 * `Props` type.
 */

type Props = {
  template: CrudTemplate;
  item: any;
  refetch: any;
};

/**
 * Export `PositioningWidget` component.
 */

export function PositioningWidget({ item, template, refetch }: Props): JSX.Element {
  const params = useParams();
  const { mutateAsync: edit } = useCrudEdit(template, { ...params, id: item.id });
  const handleUp = useCallback(async () => {
    edit({
      id: item.id,
      __crud: {
        positioning: 'up'
      }
    });
    refetch();
  }, [edit, item.id, refetch]);

  const handleDown = useCallback(async () => {
    edit({
      id: item.id,
      __crud: {
        positioning: 'down'
      }
    });
    refetch();
  }, [edit, item.id, refetch]);

  return (
    <>
      <Button
        icon={<CaretUpOutlined />}
        onClick={event => {
          event.stopPropagation();
          handleUp();
        }}
        size={'small'}
      />

      <Button
        icon={<CaretDownOutlined />}
        onClick={event => {
          event.stopPropagation();
          handleDown();
        }}
        size={'small'}
      />
    </>
  );
}
