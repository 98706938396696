/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { AxiosInstance } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { CredentialResponse } from '@react-oauth/google';
import { resolveAppUrl } from 'app/core/utils/url-resolver';

/**
 * Google login.
 */

function googleLogin(client: AxiosInstance, payload: CredentialResponse): Promise<void> {
  return client.post(resolveAppUrl('loginGoogle'), payload).then(({ data }) => data);
}

/**
 * `Options` type.
 */

type Options = UseMutationOptions<void, unknown, CredentialResponse, unknown>;

/**
 * Export `useGoogleLogin`.
 */

export function useGoogleLogin(options: Options) {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async (payload: CredentialResponse) => {
      return await googleLogin(client, payload);
    },
    ...options
  });
}
