/**
 * Module dependencies.
 */

/**
 * Export `defaultInputCurrencyFormatter`.
 */

export const defaultInputCurrencyFormatter = (value: any) => {
  return `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '');
};

/**
 * Export `defaultInputCurrencyParser`.
 */

export const defaultInputCurrencyParser = (value: any) => {
  value = value.replace(',', '.');

  return value!.replace(/\€\s?|(,*)/g, '');
};

/**
 * Export `defaultInputPercentageFormatter`.
 */

export const defaultInputPercentageFormatter = (value: any) => {
  return `${value}%`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Export `defaultPercentageParser`.
 */

export const defaultPercentageParser = (value: any) => value!.replace(/\%\s?|(,*)/g, '');
