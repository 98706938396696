/**
 * Module dependencies.
 */

import { useDebounce } from 'app/hooks/use-debounce';
import React, { ReactNode } from 'react';
import ReactLoading from 'react-loading';
import styles from './loading.module.less';

/**
 * `Props` type.
 */

type Props = {
  isLoading?: boolean;
  debounceToLoading?: number;
  debounceToRender?: number;
  children: ReactNode;
};

/**
 * Export `Loading` component.
 */

export function Loading(props: Props): JSX.Element {
  const { children, isLoading, debounceToLoading = 0, debounceToRender = 1000 } = props;

  const debouncedLoading = useDebounce(isLoading, isLoading ? debounceToLoading : debounceToRender);

  if (debouncedLoading) {
    return (
      <div className={styles.wrapper}>
        <ReactLoading color={'currentColor'} type={'spinningBubbles'} width={50} />
      </div>
    );
  }
  return <>{children}</>;
}
