/**
 * Module dependencies.
 */

import { RenderProps } from 'app/components/organisms/crud/form-widgets/render';
import React, { useCallback, useEffect } from 'react';
import styles from './styles.module.less';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'app/core/utils/formatter';
import BigNumber from 'bignumber.js';
import { reduce } from 'lodash';
import classnames from 'classnames';
import { FormInputField } from 'app/components/organisms/crud/form-components/form-input-field';
import { requiredRule } from 'app/core/utils/field-rules';

/**
 * Export `SplitTransactionStatus` component.
 */

export function SplitTransactionStatus({ form, item }: RenderProps): JSX.Element {
  const [translate] = useTranslation();
  const { name } = item;
  const splitTransactions = form.watch('splitTransactions');
  const amount = form.watch('amount');
  const consumed = reduce(
    splitTransactions,
    (acc: any, item: any) => {
      const amount = !isNaN(item?.amount) ? item?.amount : '0';

      return acc.plus(amount);
    },
    new BigNumber(0)
  ).toString();

  const handleRules = useCallback(() => {
    return {
      ...requiredRule(translate),
      validate: (value: string) => {
        return new BigNumber(value).isEqualTo('0') || 'error';
      }
    };
  }, [translate]);

  const isExpense = new BigNumber(amount).isLessThan('0');

  useEffect(() => {
    form.setValue(name, new BigNumber(consumed).minus(amount).toString());
  }, [amount, consumed, form, name]);

  const bnConsumed = new BigNumber(consumed);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.item}>
          <div className={styles.title}>{translate('common.labels.availableAmount')}</div>

          <div className={styles.value}>{formatCurrency(amount)}</div>
        </div>

        {consumed.toString() === amount.toString() ? (
          <div className={styles.success}>{translate('common.labels.valid')}</div>
        ) : (
          <>
            <div className={styles.item}>
              <div className={styles.title}>{translate('common.labels.consumed')}</div>

              <div
                className={classnames(styles.value, {
                  [styles.danger]: amount.toString() !== consumed.toString()
                })}
              >
                {formatCurrency(consumed)}
              </div>
            </div>

            {bnConsumed.isGreaterThan(amount) && (
              <div className={styles.item}>
                <div className={styles.title}>
                  {isExpense ? translate('common.labels.add') : translate('common.labels.remove')}
                </div>

                <div className={classnames(styles.value, styles.danger)}>
                  {formatCurrency(bnConsumed.minus(amount))}
                </div>
              </div>
            )}

            {bnConsumed.isLessThan(amount) && (
              <div className={styles.item}>
                <div className={styles.title}>
                  {isExpense ? translate('common.labels.remove') : translate('common.labels.add')}
                </div>

                <div className={classnames(styles.value, styles.danger)}>
                  {formatCurrency(bnConsumed.minus(amount))}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className={styles.hiddenInput}>
        <FormInputField form={form} item={{ name, label: '', rules: handleRules(), type: 'inputField' }} name={name} />
      </div>
    </>
  );
}
