/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { Bank } from 'app/types/bank-account';

/**
 * `Payload` type.
 */

type Payload = {
  bank: Bank;
  username: string;
  password: string;
  pin?: any | undefined;
};

/**
 * Connect bank account local connection.
 */

export function connectBankAccountLocalConnection(
  client: AxiosInstance,
  payload: Payload
): Promise<{ url: string; showLocalCredentials: boolean }> {
  return client({
    method: 'post',
    url: resolveAppUrl('bankAccountConnectLocalConnection'),
    data: payload
  }).then(({ data }) => {
    console.log(data);

    return data?.data;
  });
}

/**
 * Export `useBankAccountLocalConnection`.
 */

export function useBankAccountLocalConnection() {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async (payload: Payload) => {
      const result = await connectBankAccountLocalConnection(client, payload);

      return result;
    },
    onSuccess: () => {
      // Nothing to do.
    }
  });
}
