/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';

/**
 * bank account clean transactions.
 */

function bankAccountCleanTransactions(client: AxiosInstance, accountId: number): Promise<void> {
  return client
    .post(
      resolveAppUrl('bankAccountCleanTransactions', {
        interpolations: { accountId }
      })
    )
    .then(({ data }) => data?.data);
}

/**
 * Export `useBankAccountCleanTransactions`.
 */

export function useBankAccountCleanTransactions() {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async (accountId: number | undefined) => {
      if (!accountId) {
        return null;
      }

      const result = await bankAccountCleanTransactions(client, accountId);

      return result;
    },
    onSuccess: () => {
      // Nothing to do.
    }
  });
}
