// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assets-module__total--aiO6V .assets-module__line--nGO4c {
  padding-right: 50px;
  padding-top: 40px;
  grid-column: 6 / 9;
}
.assets-module__total--aiO6V .assets-module__margin--bV2rm {
  padding-top: 40px;
}
.assets-module__coinWrapper--yP6Xn .assets-module__label--drxb5 {
  margin-left: 8px;
}
.assets-module__table--Z4aLX {
  max-width: 1200px;
}
.assets-module__table--Z4aLX .loadingWrapper {
  padding: 16px;
}
.assets-module__wrapperClassName--KvqjO {
  min-height: initial;
}
`, "",{"version":3,"sources":["webpack://./components/pages/crypto/assets/assets.module.less"],"names":[],"mappings":"AAGA;EAGI,mBAAA;EACA,iBAAA;EACA,kBAAA;AAJJ;AADA;EASI,iBAAA;AALJ;AASA;EAEI,gBAAA;AARJ;AAaA;EACE,iBAAA;AAXF;AAUA;EAII,aAAA;AAXJ;AAeA;EACE,mBAAA;AAbF","sourcesContent":["\n\n\n.total {\n  .line {\n\n    padding-right: 50px;\n    padding-top: 40px;\n    grid-column: 6 / 9;\n  }\n\n  .margin {\n    padding-top: 40px;\n  }\n}\n\n.coinWrapper {\n  .label {\n    margin-left: 8px;\n  }\n}\n\n\n.table {\n  max-width: 1200px;\n\n  :global(.loadingWrapper) {\n    padding: 16px;\n  }\n}\n\n.wrapperClassName {\n  min-height: initial;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"total": `assets-module__total--aiO6V`,
	"line": `assets-module__line--nGO4c`,
	"margin": `assets-module__margin--bV2rm`,
	"coinWrapper": `assets-module__coinWrapper--yP6Xn`,
	"label": `assets-module__label--drxb5`,
	"table": `assets-module__table--Z4aLX`,
	"loadingWrapper": `loadingWrapper`,
	"wrapperClassName": `assets-module__wrapperClassName--KvqjO`
};
export default ___CSS_LOADER_EXPORT___;
