/**
 * Module dependencies.
 */

import React, { forwardRef } from 'react';
import { Input as AntdInput, InputProps as AntdInputProps } from 'antd';
import { FormField } from '../../form-field/form-field';
import { ErrorField } from '../../error-field/error-field';
import styles from './input.module.less';
import classnames from 'classnames';
import { omit } from 'lodash';

/**
 * `InputType` type.
 */

type InputType = 'input' | 'textArea';

/**
 * `InputTypeProps` type.
 */

type InputTypeProps = Omit<AntdInputProps, 'error'> & {
  inputType: 'input';
};

/**
 * `TextAreaTypeProps` type.
 */

type TextAreaTypeProps = Omit<AntdInputProps, 'error'> & {
  inputType: 'textArea';
};

/**
 * `TypeProps` type.
 */

type TypeProps = InputTypeProps | TextAreaTypeProps;

/**
 * `Props` type.
 */

export type InputProps = TypeProps & {
  error: any;
  className?: string;
  fieldClassName?: string;
  invalid?: boolean;
  isDirty?: boolean;
  label: string;
  isTouched?: boolean;
};

/**
 * Resolve component.
 */

function resolveComponent(type: InputType) {
  switch (type) {
    case 'textArea':
      return AntdInput.TextArea;

    case 'input':
    default:
      return AntdInput;
  }
}

/**
 * Export `InputComponent` component.
 */

export function InputComponent(props: InputProps, forwardedRef: any): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, invalid, fieldClassName, isTouched, isDirty, inputType = 'input', label, ...rest } = props;
  const { error } = rest;
  const Component = resolveComponent(inputType);

  return (
    <FormField className={className} label={label}>
      <Component
        className={classnames(styles.wrapper, fieldClassName, {
          [styles.disabled]: rest.disabled
        })}
        ref={forwardedRef}
        {...(omit(rest, ['htmlType']) as any)}
      />

      <ErrorField error={error} isVisible={invalid || isTouched} />
    </FormField>
  );
}

/**
 * Export `Input` component.
 */

export const Input = forwardRef<any, InputProps>(InputComponent);
