/**
 * Module dependencies.
 */

import { FormList } from 'app/components/organisms/crud/form-types';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import { requiredRule } from 'app/core/utils/field-rules';
import { useCrudRequest } from 'app/hooks/requests/crud/use-crud-request';
import { Category } from 'app/types/category';
import { SubCategory } from 'app/types/sub-category';
import BigNumber from 'bignumber.js';
import { compact, get } from 'lodash';
import moment from 'moment';
import { TFunction } from 'react-i18next';

/**
 * Export `formNormal`.
 */

export const formNormal = (translate: TFunction, data: any): FormList => [
  {
    type: 'selectField',
    name: 'categoryId',
    watchChange: {
      watchFields: ['categoryId'],
      onChange: (values, form) => {
        form.setValue('subCategoryId', null);
      }
    },
    label: translate('common.labels.category'),
    rules: requiredRule(translate),
    options: {
      hook: useCrudRequest as any,
      hookProps: (values: any, params: any) => {
        return [
          {
            key: ['categories'],
            options: {
              interpolations: params
            },
            endpoint: apiEndpoints.categories
          }
        ];
      },
      normalize: (items: Category[]) => {
        return items?.map(item => ({
          value: item.id,
          label: item.name
        }));
      }
    }
  },
  {
    type: 'selectField',
    name: 'subCategoryId',
    disabled: values => !values?.categoryId,
    label: translate('common.labels.subCategory'),
    rules: requiredRule(translate),
    options: {
      hook: useCrudRequest as any,
      hookProps: (values: any, params: any) => {
        return [
          {
            key: ['subCategories', values.categoryId],
            options: {
              interpolations: params,
              params: { categoryId: get(values, ['categoryId']) }
            },
            endpoint: apiEndpoints.subCategories
          }
        ];
      },
      normalize: (items: SubCategory[], values: any) => {
        return compact(
          items?.map(item => {
            if (!!item.closedDate && !!values.date && moment(item.closedDate).isBefore(values.date)) {
              return null;
            }

            return {
              value: item.id,
              label: item.name
            };
          })
        );
      }
    }
  },
  {
    type: 'datePickerField',
    name: 'bank_date',
    visible: values => !!values.bank_date,
    disabled: true,
    label: translate('common.labels.bank_date'),
    rules: undefined
  },
  {
    type: 'datePickerField',
    name: 'date',
    label: translate('common.labels.date'),
    rules: undefined
  },
  {
    type: 'inputField',
    name: 'bank_description',
    visible: values => !!values.bank_date,
    disabled: true,
    label: translate('common.labels.bankDescription'),
    rules: undefined
  },
  {
    type: 'inputField',
    name: 'description',
    label: translate('common.labels.description'),
    rules: undefined
  },
  {
    type: 'inputNumberField',
    name: 'amount',
    disabled: !!data?.account?.hasConnected,
    label: translate('common.labels.amount'),
    onChange: (value: any, form: any) => {
      const { previousBalance } = form.getValues();

      form.setValue('balance', new BigNumber(previousBalance).plus(value ?? '0').toString());
    },
    rules: undefined
  },
  ...((!data?.previousBalance
    ? []
    : [
        {
          type: 'inputNumberField',
          name: 'previousBalance',
          disabled: true,
          label: translate('common.labels.previousBalance'),
          rules: undefined
        },
        {
          type: 'inputNumberField',
          name: 'balance',
          disabled: true,
          label: translate('common.labels.balance'),
          rules: undefined
        }
      ]) as any)
];
