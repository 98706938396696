/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';

/**
 * `AccountSync` type.
 */

export type AccountSync = {
  reconnectState: boolean;
  name: string;
  country: string;
  bic: string;
  iban: string;
  currency: string;
  balance: number;
  transactionsSize: number;
  id: string;
  image: string;
};

/**
 * Get bank account links.
 */

function getBankAccountsLinks(client: AxiosInstance, integrationId: string): Promise<AccountSync> {
  return client
    .get(resolveAppUrl('bankAccountConnectStatus', { interpolations: { integrationId } }))
    .then(({ data }) => data?.data);
}

/**
 * Export `useBankAccountLinkStatus`.
 */

export function useBankAccountLinkStatus(integrationId: string | undefined) {
  const client = useRequestProvider();

  return useQuery(['bankAccountFirstStatus', integrationId], {
    queryFn: async () => {
      if (!integrationId) {
        return null;
      }

      return await getBankAccountsLinks(client, integrationId);
    }
  });
}
