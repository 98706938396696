/**
 * Module dependencies.
 */

import tableStyles from 'app/components/organisms/table/table.module.less';
import { formatCurrency } from 'app/core/utils/formatter';
import BigNumber from 'bignumber.js';
import { reduce } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Field } from 'app/components/organisms/field/field';
import styles from './overview.module.less';
import { CryptoAsset } from 'app/hooks/requests/crypto/use-crypto-overview';

/**
 * `Props` type.
 */

type Props = {
  data: CryptoAsset[];
};

/**
 * Export `TableFooter` component.
 */

export function TableFooter({ data }: Props): JSX.Element {
  const [translate] = useTranslation();
  const { totalUSDT, totalEUR } = useMemo(() => {
    return reduce(
      data,
      (acc, obj) => {
        if (obj.valueUSDT) {
          acc.totalUSDT = acc.totalUSDT.plus(obj.valueUSDT);
        }

        if (obj.valueEUR) {
          acc.totalEUR = acc.totalEUR.plus(obj.valueEUR);
        }

        return { ...acc };
      },
      {
        totalUSDT: new BigNumber('0'),
        totalEUR: new BigNumber('0')
      }
    );
  }, [data]);

  return (
    <div className={classnames(tableStyles.row, styles.total)}>
      <div className={styles.line}>
        <Field title={translate('common.labels.items')} value={String(data?.length)} />

        <Field title={translate('common.labels.totalUSDT')} value={formatCurrency(totalUSDT)} />

        <Field title={translate('common.labels.totalEUR')} value={formatCurrency(totalEUR)} />
      </div>
    </div>
  );
}
