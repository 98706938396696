/**
 * Module dependencies.
 */

import { RenderProps } from 'app/components/organisms/crud/form-widgets/render';
import { Field } from 'app/components/organisms/field/field';
import { formatCurrency } from 'app/core/utils/formatter';
import BigNumber from 'bignumber.js';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './stats.module.less';
import { useCrudRequest } from 'app/hooks/requests/crud/use-crud-request';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import { useParams } from 'react-router-dom';
import { ProjectProposal } from 'app/types/project';
import { useProject } from 'app/hooks/requests/projects/use-project';

/**
 * Export `Stats` component.
 */

export function Stats({ form: { watch } }: RenderProps): JSX.Element | null {
  const [translate] = useTranslation();
  const params = useParams();
  const { data: project, isLoading: isProjectLoading } = useProject(params.projectId);
  const values = watch();
  const { data: proposals, isLoading } = useCrudRequest<ProjectProposal[]>({
    endpoint: values.proposalIds ? (apiEndpoints.projectProposalsByIds as any) : null,
    options: {
      params: {
        ids: values.proposalIds
      },
      interpolations: {
        projectId: params.projectId,
        proposalId: values.proposalId
      }
    },
    key: ['stats']
  });

  const { budget, targetIvaAmount, total, iva } = useMemo(() => {
    if (!proposals || !project) {
      return {};
    }

    let budget = new BigNumber('0');
    let targetIvaAmount = new BigNumber('0');
    let iva = new BigNumber('0');
    let total = new BigNumber('0');

    for (const proposal of proposals) {
      const _targetIvaAmount = new BigNumber(proposal.budget).multipliedBy(proposal.ivaRange).dividedBy(100);
      const _iva = new BigNumber(targetIvaAmount).multipliedBy(project.defaultIVA).dividedBy(100);
      const _total = new BigNumber(proposal.budget).plus(iva);

      budget = budget.plus(proposal.budget);
      targetIvaAmount = targetIvaAmount.plus(_targetIvaAmount);
      iva = iva.plus(_iva);
      total = total.plus(_total);
    }

    return {
      budget,
      targetIvaAmount,
      iva,
      total
    };
  }, [project, proposals]);

  if (!values.proposalIds || !proposals || isLoading || isProjectLoading) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {!values.withoutProposal && (
        <div className={styles.iva}>
          <div className={styles.title}>{'IVA'}</div>
          <Field title={translate('common.labels.baseValue')} value={formatCurrency(budget)} />

          <Field title={translate('common.labels.targetIvaAmount')} value={formatCurrency(targetIvaAmount)} />

          <Field title={translate('common.labels.ivaValue')} value={formatCurrency(iva)} />

          <Field title={translate('common.labels.totalBudget')} value={formatCurrency(total)} />
        </div>
      )}

      {values.prevision && total && (
        <div className={styles.estimate}>
          <div className={styles.title}>{'Estimate'}</div>
          <Field title={translate('common.labels.prevision')} value={formatCurrency(values.prevision)} />
          {!values.withoutProposal && (
            <>
              <Field title={translate('common.labels.budget')} value={formatCurrency(total)} />
              <Field
                title={translate('common.labels.saved')}
                value={formatCurrency(new BigNumber(values.prevision).minus(total))}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}
