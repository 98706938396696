/**
 * Module dependencies.
 */

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChartOutlined } from '@ant-design/icons';
import { Outlet } from 'react-router-dom';
import { Dashboard } from './dashboard';
import { appRoutes } from 'app/routes';
import { compileMenu } from 'app/core/utils/menu';

/**
 * Export `SkinBankAccounts` component.
 */

export function SkinBankAccounts(): JSX.Element {
  const [translate] = useTranslation();
  const items = useMemo(
    () =>
      compileMenu([
        {
          key: 'overview',
          pathname: appRoutes.dashboard.bankAccounts.base,
          icon: <PieChartOutlined />,
          label: translate('bankAccounts.menu.overview')
        },
        {
          key: 'accounts',
          pathname: appRoutes.dashboard.bankAccounts.accounts.base,
          icon: <PieChartOutlined />,
          label: translate('bankAccounts.menu.accounts')
        },
        {
          key: 'categories',
          pathname: appRoutes.dashboard.bankAccounts.categories.base,
          icon: <PieChartOutlined />,
          label: translate('bankAccounts.menu.categories')
        },
        {
          key: 'subCategories',
          pathname: appRoutes.dashboard.bankAccounts.subCategories.base,
          icon: <PieChartOutlined />,
          label: translate('bankAccounts.menu.subCategories')
        },
        {
          key: 'budgets',
          pathname: appRoutes.dashboard.bankAccounts.budgets.base,
          icon: <PieChartOutlined />,
          label: translate('bankAccounts.menu.budgets')
        },
        {
          key: 'movements',
          pathname: appRoutes.dashboard.bankAccounts.movementsManager.base,
          icon: <PieChartOutlined />,
          label: translate('bankAccounts.menu.movements')
        }
      ]),
    [translate]
  );

  return (
    <Dashboard menu={items}>
      <Outlet />
    </Dashboard>
  );
}
