/**
 * Module dependencies.
 */

import { BarsOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { appRoutes } from 'app/routes';
import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

/**
 * `Props` type.
 */

type Props = {
  item: any;
};

/**
 * Export `MovementsButton` component.
 */

export function MovementsButton({ item }: Props): JSX.Element {
  const navigate = useNavigate();

  return (
    <Button
      icon={<BarsOutlined />}
      onClick={event => {
        event.stopPropagation();

        const budgetId = item.id;

        if (!budgetId) {
          return;
        }

        const query = {
          filter: JSON.stringify({ budgetId })
        };

        const path = resolveAppPath(appRoutes.dashboard.projects.project.movements, {
          projectId: item.projectId
        });

        navigate({
          pathname: path,
          search: `?${createSearchParams(query as any)}`
        });
      }}
      size={'small'}
    />
  );
}
