/**
 * Module dependencies.
 */

import React from 'react';
import { InputSearch } from '../form-filters-type';
import { FilterOptions } from './types';
import classNames from 'classnames';
import styles from '../../crud.module.less';
import { FilterInputField } from './filter-input-field';

/**
 * Export `FilterInputSearchField` component.
 */

export function FilterInputSearchField({ className, ...rest }: FilterOptions<InputSearch>): JSX.Element {
  return <FilterInputField className={classNames(className, styles.searchField)} {...rest} />;
}
