/**
 * Module dependencies.
 */

import React, { forwardRef } from 'react';
import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd';
import { FormField } from '../../form-field/form-field';
import { ErrorField } from '../../error-field/error-field';
import styles from './select.module.less';
import classnames from 'classnames';
import { omit } from 'lodash';
/**
 * Export `SelectProps` type.
 */

export type SelectProps = AntdSelectProps & {
  error?: any;
  className?: string;
  fieldClassName?: string;
  invalid?: boolean;
  isDirty?: boolean;
  label?: string;
  isTouched?: boolean;
  name: string;
};

/**
 * `SelectComponent` component.
 */

function SelectComponent(props: SelectProps, forwardedRef: any): JSX.Element {
  const { invalid, isTouched, fieldClassName, className, label, ...rest } = props;
  const { error } = rest;

  return (
    <FormField className={className} label={label}>
      <AntdSelect
        className={classnames(styles.wrapper, fieldClassName)}
        ref={forwardedRef}
        {...omit(rest, ['isDirty'])}
      />

      <ErrorField error={error} isVisible={invalid || isTouched} />
    </FormField>
  );
}

/**
 * Export `Select` component.
 */

export const Select = forwardRef<any, SelectProps>(SelectComponent);
