/**
 * Module dependencies.
 */

import { Button } from 'app/components/atoms/button/button';
import { Title } from 'app/components/atoms/title/title';
import { ExtraActionsProps } from 'app/components/organisms/crud/form-types';
import { Field } from 'app/components/organisms/field/field';
import { Modal } from 'app/components/organisms/modal/modal';
import { useBankAccounts } from 'app/hooks/requests/bank-accounts/use-bank-accounts';
import { useRefreshBankAccount } from 'app/hooks/requests/bank-accounts/refresh-bank-account';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { appRoutes } from 'app/routes';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from 'app/core/utils/formatter';
import BigNumber from 'bignumber.js';
import { Alert } from 'antd';

/**
 * `ConnectionState` type.
 */

type ConnectionState = 'QUEUED' | 'UPDATED' | 'AUTHENTICATING' | 'REFRESHING' | 'ERROR' | 'TEMPORARY_ERROR';

/**
 * `Value` type.
 */

type Value = {
  state: ConnectionState;
  metadata?: any;
};

/**
 * `State` type.
 */

type State = {
  id: number;
  title: string;
  value: Value;
};

/**
 * Export `ExtraActions` component.
 */

export function ExtraActions({ refresh }: ExtraActionsProps): JSX.Element {
  const { data } = useBankAccounts();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const [state, setState] = useState<State[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const bankAccounts = useMemo(() => {
    return data?.results?.filter(item => !!item.hasConnected) ?? [];
  }, [data]);

  const { mutateAsync: refreshBankAccount } = useRefreshBankAccount('refresh');
  const handleClick = useCallback(async () => {
    setOpen(true);
  }, []);

  const handleReconnect = useCallback(
    (data: any) => {
      const route = resolveAppPath(appRoutes.dashboard.bankAccounts.accounts.connect, { id: data.id });

      navigate(route);
    },
    [navigate]
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    async function refreshBankAccounts() {
      setLoading(true);
      const data = [];

      for (const bankAccount of bankAccounts) {
        data.push({
          id: bankAccount.id,
          title: bankAccount.name,
          value: {
            state: 'QUEUED'
          }
        } as State);
      }

      setState(data);
      let hasError = false;

      for (const item of data) {
        item.value = {
          state: 'REFRESHING'
        };

        setState(data);

        try {
          const result = await refreshBankAccount(item.id);

          if (result.status === 'UPDATED') {
            item.value.state = 'UPDATED';
            item.value.metadata = result;
          } else {
            hasError = true;
            item.value.state = 'ERROR';
            item.value.metadata = {
              ...result,
              bankAccountId: item.id
            };
          }
        } catch (error: any) {
          hasError = true;
          item.value.state = 'ERROR';
          item.value.metadata = error.response?.data?.data?.data;
        } finally {
          setState(data);
        }
      }

      setLoading(false);

      if (hasError) {
        return;
      }

      await refresh();

      setOpen(false);
    }

    refreshBankAccounts();
  }, [bankAccounts, isOpen, refresh, refreshBankAccount, translate]);

  const resolveValue = useCallback(
    (state: State) => {
      switch (state.value.state) {
        case 'ERROR':
          if (state.value.metadata?.error === 'invalid_import_balances') {
            return {
              value: (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end'
                  }}
                >
                  <div style={{ color: 'red' }}>{translate('updateAccounts.labels.invalidBalances')}</div>
                </div>
              ),
              note: (
                <Alert
                  description={translate('updateAccounts.labels.invalidBalancesDescription', {
                    networkBalance: formatCurrency(state.value.metadata?.networkBalance),
                    appBalance: formatCurrency(state.value.metadata?.appBalance),
                    diff: formatCurrency(
                      new BigNumber(state.value.metadata?.networkBalance)
                        .minus(state.value.metadata?.appBalance)
                        .toFixed(2)
                    )
                  })}
                  message={translate('updateAccounts.labels.invalidBalances')}
                  showIcon
                  style={{ marginBottom: 32 }}
                  type={'error'}
                />
              )
            };
          }

          if (!state.value.metadata) {
            return {
              value: (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <div style={{ paddingRight: 16 }}>{translate('updateAccounts.labels.internalError')}</div>

                  <Button danger onClick={() => handleReconnect(state)}>
                    {translate('common.actions.goToBankConfig')}
                  </Button>
                </div>
              )
            };
          }

          return {
            value: (
              <Button danger href={state.value.metadata?.updateConsentLink}>
                {translate('common.actions.reconnect')}
              </Button>
            )
          };

        case 'UPDATED':
          return { value: translate('updateAccounts.labels.done') };
        case 'REFRESHING':
          return { value: translate('updateAccounts.labels.refreshing') };
        default:
          return { value: translate('updateAccounts.labels.queued') };
      }
    },
    [handleReconnect, translate]
  );

  return (
    <>
      <Button isLoading={isOpen} onClick={handleClick}>
        {translate('common.actions.refresh')}
      </Button>

      <Modal
        buttons={[]}
        isLoading={isLoading}
        isOpen={isOpen}
        maskClosable={!isLoading}
        onClose={() => setOpen(false)}
      >
        <>
          <Title>{translate('updateAccounts.title')}</Title>

          {state.map(item => {
            const { value, note } = resolveValue(item);

            return (
              <>
                <Field key={item.id} last={!!note} title={item.title} value={value} />

                {note}
              </>
            );
          })}
        </>
      </Modal>
    </>
  );
}
