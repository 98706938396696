/**
 * Module dependencies.
 */

import { Text } from 'app/components/atoms/text/text';
import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.less';

/**
 * `Props` type.
 */

type Props = {
  item: any;
};

/**
 * Export `Name` component.
 */

export function Name({ item }: Props): JSX.Element {
  return (
    <Text
      className={classNames({
        [styles.closed]: item.closedBudget
      })}
    >
      {item.name}
    </Text>
  );
}
