/**
 * Module dependencies.
 */

import {
  CrudAddType,
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  ListColumns
} from 'app/components/organisms/crud/form-types';
import { normalizeTemplate } from 'app/components/organisms/crud/utils';

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { formatCurrency } from 'app/core/utils/formatter';
import { appRoutes } from 'app/routes';
import { omit } from 'lodash';
import BigNumber from 'bignumber.js';
import { Transaction } from 'app/types/transaction';
import moment from 'moment';
import styles from './movements.module.less';
import React from 'react';
import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import { SubCategory } from 'app/types/sub-category';
import { useCrudRequest } from 'app/hooks/requests/crud/use-crud-request';
import { ExtraActions } from './extra-actions';
import { TFunction } from 'react-i18next';
import { formFields } from './form-config';
import qs from 'qs';
import { TableFooter } from './table-footer';
import { Wrapper } from './wrapper';

/**
 * Field width.
 */

const fieldWidth = 230;

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'id',
    key: 'id',
    render: (item: Transaction) => {
      return (
        <div className={styles.idWidget}>
          <div className={styles.id}>{item.id}</div>
          <div className={styles.date}>{!item.bank_date ? '' : moment(item.bank_date).format('DD-MM-YYYY')}</div>
          <div className={styles.bankName}>{item.account?.name}</div>
        </div>
      );
    }
  },
  {
    title: translate('common.table.columns.date'),
    dataIndex: 'date',
    key: 'date',
    render: item => moment(item.date).format('DD-MMM')
  },
  {
    title: translate('common.table.columns.category'),
    dataIndex: 'subCategory.name',
    key: 'category',
    render: (item: Transaction) => {
      if (!item.subCategory) {
        return <div className={styles.missingCategory}>{translate('common.labels.missingCategory')}</div>;
      }

      return (
        <div className={styles.categoryWidget}>
          <div className={styles.category}>{item.subCategory?.category?.name}</div>
          <div className={styles.subCategory}>{item.subCategory?.name}</div>
        </div>
      );
    }
  },
  {
    title: translate('common.table.columns.description'),
    dataIndex: 'description',
    size: '1fr',
    mobile: { direction: 'column' },
    key: 'description',
    render: (item: Transaction) => {
      return (
        <div className={styles.description}>
          <span>{item.description}</span>

          {(!!item.ownerSwitchTransaction || item.transactionType === 'switchAccount') && (
            <span className={styles.switchTransaction}>{translate('common.labels.switchTransaction')}</span>
          )}

          {(item.splitTransactions.length > 0 || item.transactionType === 'splitTransaction') && (
            <span className={styles.splitTransaction}>{translate('common.labels.splitTransaction')}</span>
          )}
        </div>
      );
    }
  },
  {
    title: translate('common.table.columns.previousBalance'),
    dataIndex: 'previousBalance',
    style: { justifyContent: 'flex-end' },
    styleRow: { fontFamily: 'Oswald' },
    key: 'previousBalance',
    render: (item: Transaction) => formatCurrency(item.previousBalance)
  },
  {
    title: translate('common.table.columns.amount'),
    dataIndex: 'amount',
    style: { justifyContent: 'flex-end', minWidth: '32px' },
    styleRow: { fontFamily: 'Oswald' },
    key: 'amount',
    render: (item: Transaction) => {
      const isSplitTransaction = item.splitTransactions.length > 0 || item.transactionType === 'splitTransaction';

      return (
        <span style={!isSplitTransaction ? {} : { textDecoration: 'line-through' }}>{formatCurrency(item.amount)}</span>
      );
    }
  },

  {
    title: translate('common.table.columns.balance'),
    dataIndex: 'balance',
    key: 'balance',
    style: { justifyContent: 'flex-end' },
    styleRow: { fontFamily: 'Oswald' },
    render: (item: Transaction) => formatCurrency(item.balance)
  }
];

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType => ({
  formFields: formFields(translate, undefined),
  acceptQueryFields: [
    {
      name: 'accountId',
      parser: value => (!!value ? parseInt(value, 10) : undefined)
    }
  ],
  normalizePayload: data => ({
    ...omit(data, ['categoryId', 'balance']),
    amount: new BigNumber(data.amount).toString(),
    date: data.date.format('YYYY-MM-DD HH:mm:ss')
  })
});

/**
 * Normalize edit initial values.
 */

function normalizeEditInitialValues(formKeys: any, data: any) {
  const result = {
    ...data,
    targetTransactionId: data?.switchTransaction?.id,
    bank_date: data?.bank_date ? moment(data.bank_date) : undefined,
    date: data?.date ? moment(data.date) : undefined,
    categoryId: data?.subCategory?.categoryId
  };

  if (data?.transactionType === 'switchAccount') {
    result.targetAccountId = data.switchTransaction?.accountId;
  }

  if (result.splitTransactions?.length > 0) {
    result.splitTransactions = result.splitTransactions.map((item: any) => {
      return normalizeEditInitialValues(formKeys, item);
    });

    result.transactionType = 'splitTransaction';
  }

  return result;
}

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType => ({
  formFields: (data: any) => {
    if (data?.ownerSwitchTransaction) {
      data.transactionType = 'normal';
    }

    return formFields(translate, data);
  },
  normalizeInitialValues: normalizeEditInitialValues,
  normalizePayload: data => {
    if (data.transactionType === 'normal' && data?.ownerSwitchTransaction) {
      data.transactionType = 'switchAccount';
    }

    return {
      ...omit(data, [
        'userId',
        'coin',
        'switchTransaction',
        'ownerSwitchTransaction',
        'balance',
        'statusSplit',
        'id',
        'subCategory',
        'account',
        'previousBalance',
        'categoryId',
        'bank_id',
        'source',
        'bank_date',
        'bank_description',
        'hash'
      ]),
      amount: new BigNumber(data.amount).toString(),
      date: data.date.format('YYYY-MM-DD HH:mm:ss')
    };
  }
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType => ({});

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: translate('common.labels.search')
  },
  {
    type: 'selectSearch',
    name: 'category',
    style: {
      minWidth: fieldWidth
    },
    label: translate('common.labels.categories'),
    showSearch: true,
    allowClear: true,
    options: {
      hook: useCrudRequest as any,
      hookProps: (values: any, params: any) => {
        return [
          {
            key: ['subCategories'],
            options: {
              interpolations: params
            },
            endpoint: apiEndpoints.subCategories
          }
        ];
      },
      normalize: (items: SubCategory[]) => [
        {
          label: translate('common.labels.empty'),
          value: null
        },
        ...(items?.map(item => ({
          value: item.id,
          label: `${item.category.name}: ${item.name}`
        })) ?? [])
      ]
    }
  },
  {
    type: 'selectSearch',
    name: 'year',
    style: {
      minWidth: fieldWidth
    },
    label: translate('common.labels.year'),
    showSearch: true,
    onChange: (value: any, { resetField }) => {
      if (value) {
        return;
      }

      resetField('month');
    },
    allowClear: true,
    options: {
      hook: useCrudRequest as any,
      hookProps: () => [
        {
          key: 'transactions-year',
          endpoint: apiEndpoints.transactionsFiltersYear
        }
      ],
      normalize: (items: string[]) => {
        return (
          items?.map(item => ({
            value: item,
            label: item
          })) ?? []
        );
      }
    }
  },
  {
    type: 'selectSearch',
    name: 'month',
    style: {
      minWidth: fieldWidth
    },
    disabled: (values: any) => !values.year,
    label: translate('common.labels.month'),
    showSearch: true,
    allowClear: true,
    options: {
      hook: useCrudRequest as any,
      hookProps: (values: any) => {
        return [
          {
            params: {
              year: values?.year
            },
            key: 'transactions-month',
            endpoint: apiEndpoints.transactionsFiltersMonth
          }
        ];
      },
      normalize: (items: string[]) => {
        return (
          items?.map(item => ({
            value: item,
            label: moment()
              .month(parseInt(item, 10) - 1)
              .format('MMMM')
          })) ?? []
        );
      }
    }
  },
  {
    type: 'selectSearch',
    name: 'orderBy',
    style: {
      minWidth: fieldWidth
    },
    label: translate('common.labels.orderBy'),
    showSearch: true,
    allowClear: true,
    options: [
      {
        label: translate('common.order.dateAsc'),
        value: 'bank_date#asc'
      },
      {
        label: translate('common.order.dateDesc'),
        value: 'bank_date#desc'
      }
    ]
  },
  {
    type: 'selectSearch',
    name: 'movementType',
    style: {
      minWidth: fieldWidth
    },
    label: translate('common.labels.movementType'),
    showSearch: true,
    allowClear: true,
    options: [
      {
        label: translate('common.movement.incomes'),
        value: 'incomes'
      },
      {
        label: translate('common.movement.expenses'),
        value: 'expenses'
      }
    ]
  },
  {
    type: 'selectSearch',
    name: 'transactionType',
    style: {
      minWidth: fieldWidth
    },
    label: translate('common.labels.transactionType'),
    showSearch: true,
    allowClear: true,
    options: [
      {
        label: translate('common.labels.normal'),
        value: 'normal'
      },
      {
        label: translate('common.labels.splitTransaction'),
        value: 'splitTransaction'
      },
      {
        label: translate('common.labels.switchTransaction'),
        value: 'switchAccount'
      }
    ]
  }
];

/**
 * Interpolate add link.
 */

function interpolateAddLink(template: CrudTemplate, searchParams: URLSearchParams) {
  const resolvedRoute = [
    template.add?.route,
    '?',
    qs.stringify({
      accountId: JSON.parse(searchParams.get('filter') as any)?.account
    })
  ].join('');

  return resolvedRoute;
}

/**
 * List.
 */

const list = (translate: TFunction): CrudListType => ({
  WrapperComponent: Wrapper,
  columns: listColumns(translate),
  route: appRoutes.dashboard.bankAccounts.movementsManager.base,
  key: ['transactions'],
  endpoint: apiEndpoints.transactions,
  interpolateAddLink,
  canRemove: (values: any) => {
    return !values.bank_date && values.transactionType === 'normal' && !values.ownerSwitchTransaction;
  },
  filters: listFilters(translate),
  defaultQueryString: 'filter=%7B"orderBy"%3A"bank_date%23desc"%7D&filterOpened=true',
  extraActions: props => <ExtraActions {...props} />,
  normalizeFilters: (data: any) => {
    const { orderBy, ...filters } = data.filters;
    const result: any = { filters, order: [] };

    if (orderBy) {
      const parts = orderBy.split('#');

      result.order.push({ key: parts[0], direction: parts[1] });
    }

    result.order.push({ key: 'id', direction: 'desc' });

    return result;
  },
  pagination: {
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100, 500, 1000]
  },
  renderLastLine: props => <TableFooter {...props} />
});

/**
 * Config.
 */

export function createTransactionsTemplate(translate: TFunction): CrudTemplate {
  return normalizeTemplate(translate, {
    list: list(translate),
    add: add(translate),
    edit: edit(translate),
    remove: remove()
  });
}
