/**
 * Module dependencies.
 */

import { FormList } from 'app/components/organisms/crud/form-types';
import { requiredRule } from 'app/core/utils/field-rules';
import { TFunction } from 'react-i18next';

/**
 * Export `formFields`.
 */

export const formFields = (translate: TFunction): FormList => [
  {
    type: 'inputField',
    name: 'name',
    label: translate('common.labels.name'),
    rules: requiredRule(translate)
  },
  {
    type: 'inputField',
    name: 'address',
    label: translate('common.labels.address'),
    rules: requiredRule(translate)
  }
];
