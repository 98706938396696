/**
 * Module dependencies.
 */

import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import React, { ForwardedRef, forwardRef } from 'react';
import { Select } from './select';
import { SelectProps as AntdSelectProps } from 'antd';

/**
 * `SelectFieldProps` type.
 */

export type SelectFieldProps<T extends FieldValues> = UseControllerProps<T> &
  AntdSelectProps & {
    label: string;
    fieldClassName?: string;
    normalizeValue?: (newValue: string, value: string) => string;
  };

/**
 * `SelectFieldComponent` component.
 */

export function SelectFieldComponent<T extends FieldValues>(
  props: SelectFieldProps<T>,
  forwardedRef: ForwardedRef<any>
): JSX.Element {
  const { control, defaultValue, name, normalizeValue, rules, shouldUnregister, ...inputProps } = props;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { name, onBlur, onChange, value }, fieldState: { error, invalid, isDirty, isTouched } }) => {
        return (
          <Select
            invalid={invalid}
            isDirty={isDirty}
            isTouched={isTouched}
            name={name}
            onBlur={onBlur as any}
            value={value}
            {...inputProps}
            error={error}
            onChange={newValue => {
              const finalValue = normalizeValue ? normalizeValue(String(newValue), value) : newValue;

              onChange(!finalValue ? null : finalValue);
            }}
            ref={forwardedRef}
          />
        );
      }}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  );
}

/**
 * Export `SelectField`.
 */

export const SelectField = forwardRef<SelectFieldProps<any>, SelectFieldProps<any>>(SelectFieldComponent);
