/**
 * Module dependencies.
 */

import { FormList } from 'app/components/organisms/crud/form-types';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import { requiredRule } from 'app/core/utils/field-rules';
import { useCrudRequest } from 'app/hooks/requests/crud/use-crud-request';
import { TFunction } from 'react-i18next';

/**
 * Export `defaultInputCurrencyFormatter`.
 */

export const defaultInputCurrencyFormatter = (value: any) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '');
};

/**
 * Export `defaultInputCurrencyParser`.
 */

export const defaultInputCurrencyParser = (value: any) => {
  value = value.replace(',', '.');

  return value!.replace(/\€\s?|(,*)/g, '');
};

/**
 * Export `formFields`.
 */

export const formFields = (translate: TFunction): FormList => [
  {
    type: 'selectField',
    name: 'walletId',
    label: translate('common.labels.wallet'),
    rules: requiredRule(translate),
    options: {
      hook: useCrudRequest as any,
      hookProps: (values: any, params: any) => {
        return [
          {
            key: ['subCategories', values.categoryId],
            options: {
              interpolations: params,
              params: {}
            },
            endpoint: apiEndpoints.cryptoWallets
          }
        ];
      },
      normalize: (items: any) => {
        return items?.map((item: any) => ({
          value: item.id,
          label: item.name
        }));
      }
    }
  },

  {
    type: 'inputField',
    name: 'coin',
    label: translate('common.labels.coin'),
    rules: requiredRule(translate)
  },
  {
    type: 'inputNumberField',
    name: 'amount',
    parser: defaultInputCurrencyParser,
    formatter: defaultInputCurrencyFormatter,
    label: translate('common.labels.amount'),
    rules: requiredRule(translate)
  }
];
