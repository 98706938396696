/**
 * Module dependencies.
 */

import { FieldValues, UseFormReturn } from 'react-hook-form';
import styles from './styles.module.less';
import React, { useCallback, useMemo, useState } from 'react';
import { CrudFormRender, CrudFormRenderProps } from '../../crud-form';
import { ListItemFormType, NestedFormList } from '../../form-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'app/components/atoms/button/button';
import { range } from 'lodash';
import { Popconfirm } from 'antd';

/**
 * `Props` type.
 */

type Props = {
  formType: 'add' | 'edit';
  name: string;
  item: ListItemFormType;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * `NestedFormProps` type.
 */

type NestedFormProps = Omit<CrudFormRenderProps, 'formFields'> & {
  index: number;
  name: string;
  formFields: NestedFormList;
};

/**
 * `NestedForm` component.
 */

function NestedForm(props: NestedFormProps): JSX.Element {
  const { form, name, index, formFields, formType } = props;
  const nestedFormFields = useMemo(() => {
    return formFields(name, index).map(item => ({
      ...item,
      name: `${props.name}.${props.index}.${item.name}`
    }));
  }, [formFields, index, name, props.index, props.name]);

  return <CrudFormRender form={form} formFields={nestedFormFields} formType={formType} />;
}

/**
 * Export `ListItemForm` component.
 */

export function ListItemForm(props: Props): JSX.Element | null {
  const [translate] = useTranslation();
  const { form, formType, item } = props;
  const { formFields, name, defaultItemsLength } = item;
  const [items, setItems] = useState(() => {
    const values = form.getValues();

    if (values?.[item.name]?.length > 0) {
      return range(values?.[item.name].length);
    }

    const list = range(defaultItemsLength);

    for (const index of list) {
      if (item.normalizeValues) {
        form.setValue(`${item.name}.${index}`, item.normalizeValues(form.getValues()));
      }
    }

    return list;
  });

  const removeItem = useCallback(
    (index: number) => {
      form.setValue(`${item.name}.${index}`, undefined);

      setItems(items => {
        return items.filter(item => item !== index);
      });
    },
    [form, item.name]
  );

  return (
    <>
      <div className={styles.wrapper}>
        {items.map(index => (
          <div className={styles.formItem} key={index}>
            <NestedForm form={form} formFields={formFields} formType={formType} index={index} name={name} />

            <Popconfirm
              {...{ onClick: (event: any) => event.stopPropagation() }}
              onConfirm={(event: any) => {
                event.stopPropagation();
                removeItem(index);
              }}
              title={translate('removeAction')}
            >
              <Button danger>{'remove'}</Button>
            </Popconfirm>
          </div>
        ))}
      </div>

      <Button
        onClick={() => {
          setItems(items => {
            const lastItem = (items.length > 0 ? items[items.length - 1] : -1) + 1;

            if (item.normalizeValues) {
              form.setValue(`${item.name}.${lastItem}`, item.normalizeValues(form.getValues()));
            }

            return [...items, lastItem];
          });
        }}
      >
        {translate('common.actions.addNewItem')}
      </Button>
    </>
  );
}
