/**
 * Module dependencies.
 */

import { Loading } from 'app/components/atoms/loading/loading';
import { Title } from 'app/components/atoms/title/title';
import { useProject } from 'app/hooks/requests/projects/use-project';
import { useParams } from 'react-router-dom';
import React from 'react';

/**
 * Export `Project` component.
 */

export function Project(): JSX.Element | null {
  const { projectId } = useParams();
  const { data, isLoading } = useProject(projectId);

  return <Loading isLoading={isLoading}>{data && <Title>{data.name}</Title>}</Loading>;
}
