/**
 * Module dependencies.
 */

import { formatCurrency } from 'app/core/utils/formatter';
import BigNumber from 'bignumber.js';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './table-footer.module.less';
import { Field } from 'app/components/organisms/field/field';
import { reduce } from 'lodash';
import { sumProposals } from './utils';
import { SubTitle } from 'app/components/atoms/sub-title/sub-title';
import { Text } from 'app/components/atoms/text/text';

/**
 * `Props` type.
 */

type Props = {
  data: any[] | undefined;
};

/**
 * Export `GlobalPrevision` component.
 */

export function GlobalPrevision({ data }: Props): JSX.Element {
  const [translate] = useTranslation();
  const { initialPrevision, prevision } = useMemo(() => {
    return reduce(
      data,
      (acc, item) => {
        const totalProposals = sumProposals(item);

        acc.initialPrevision = acc.initialPrevision.plus(item.prevision ?? '0');
        acc.prevision = acc.prevision.plus(totalProposals ?? item.prevision ?? '0');

        return {
          ...acc
        };
      },
      {
        initialPrevision: new BigNumber('0'),
        prevision: new BigNumber('0')
      }
    );
  }, [data]);

  const budgetDiff = initialPrevision.minus(prevision);

  return (
    <div className={styles.line}>
      <SubTitle className={styles.subTitle}>{translate('common.labels.proposal')}</SubTitle>

      <Text className={styles.info}>
        {
          'Difference between the initial forecast and proposals provided for execute the work. For items for which there are no proposals, the initial value is assumed.'
        }
      </Text>

      <Field
        title={translate('common.labels.initialPrevision')}
        value={formatCurrency(new BigNumber(initialPrevision).toFixed(2))}
      />

      <Field title={translate('common.labels.prevision')} value={formatCurrency(new BigNumber(prevision).toFixed(2))} />

      <Field
        title={translate('common.labels.budgetDifference')}
        value={formatCurrency(new BigNumber(budgetDiff).toFixed(2))}
      />
    </div>
  );
}
