/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';

/**
 * Restore.
 */

function restore(client: AxiosInstance, code: string): Promise<void> {
  return client({
    method: 'post',
    url: resolveAppUrl('restore'),
    data: {
      code
    }
  }).then(({ data }) => data);
}

/**
 * Export `useRestore`.
 */

export function useRestore() {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async (code: string) => {
      const result = await restore(client, code);

      return result;
    }
  });
}
