/**
 * Module dependencies.
 */

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { requiredRule } from 'app/core/utils/field-rules';
import { appRoutes } from 'app/routes';
import { Budget } from 'app/types/budget';
import { Category } from 'app/types/category';
import { get, omit, pick, sortBy } from 'lodash';
import {
  CrudAddType,
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  FormList,
  ListColumns
} from '../../../organisms/crud/form-types';

import { TFunction } from 'i18next';
import { formatCurrency } from 'app/core/utils/formatter';
import { Transaction } from 'app/types/transaction';
import BigNumber from 'bignumber.js';
import React from 'react';
import moment from 'moment';
import styles from './budgets.module.less';
import { BudgetLabel } from './budget-label';
import { useCrudRequest } from 'app/hooks/requests/crud/use-crud-request';

/**
 * Process budget.
 */

function processBudget(item: Budget): string[] {
  switch (item.type) {
    case 'month':
      return [formatCurrency(item.amount), formatCurrency(new BigNumber(item.amount).multipliedBy(12).toFixed(2))];
    case 'year':
      return [formatCurrency(new BigNumber(item.amount).dividedBy(12).toFixed(2)), formatCurrency(item.amount)];
    default:
      return [];
  }
}

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.id'),
    size: '30px',
    dataIndex: 'id',
    key: 'id',
    render: (item: Budget) => <BudgetLabel isIncome={item.isIncome}>{item.id}</BudgetLabel>
  },
  {
    title: translate('common.table.columns.category'),
    dataIndex: 'subCategory.name',
    key: 'subCategory',
    size: '1fr',
    render: (item: Budget) => {
      return (
        <BudgetLabel isIncome={item.isIncome} noBreak>
          {`${item.subCategory?.category?.name}: ${item.subCategory?.name}`}
        </BudgetLabel>
      );
    }
  },
  {
    title: translate('common.table.columns.type'),
    render: (item: Budget) => {
      return (
        <BudgetLabel isIncome={item.isIncome}>{translate(`common.types.${item.type as string}`) as string}</BudgetLabel>
      );
    },
    key: 'type'
  },
  {
    title: translate('common.table.columns.budget'),
    style: { justifyContent: 'flex-end' },
    mobile: {
      direction: 'column'
    },
    render: (item: Budget) => {
      const [left, right] = processBudget(item);

      return (
        <BudgetLabel className={styles.budgetValues} isIncome={item.isIncome}>
          <div className={styles.left}>{left}</div>

          <div className={styles.of}>{String(translate('common.labels.of'))}</div>

          <div className={styles.right}>{right}</div>
        </BudgetLabel>
      );
    },
    key: 'budgetMonth'
  },
  {
    title: translate('common.table.columns.available'),
    style: { justifyContent: 'flex-end' },
    render: (item: Budget) => {
      const total = item.type === 'year' ? item.amount : new BigNumber(item.amount).multipliedBy(12).toFixed(2);

      const result = (item.transactions ?? []).reduce((acc: any, item: Transaction) => {
        return new BigNumber(acc).minus(item.amount).toFixed(2);
      }, '0');

      return (
        <BudgetLabel isCurrency isIncome={item.isIncome}>
          {formatCurrency(new BigNumber(total).minus(result))}
        </BudgetLabel>
      );
    },
    key: 'available'
  },
  {
    title: translate('common.table.columns.consumed'),
    style: { justifyContent: 'flex-end' },
    render: (item: Budget) => {
      const total = item.type === 'year' ? item.amount : new BigNumber(item.amount).multipliedBy(12).toFixed(2);
      const result = (item.transactions ?? []).reduce((acc: any, item: Transaction) => {
        return new BigNumber(acc).minus(item.amount).toFixed(2);
      }, '0');

      const percentage = new BigNumber(result).multipliedBy(100).dividedBy(total);

      return (
        <BudgetLabel className={styles.consumed} isCurrency isIncome={item.isIncome}>
          <span>{formatCurrency(result)}</span>

          <span className={styles.percentage}>{percentage.isFinite() ? percentage.toFixed(2) + '%' : 'N/A'}</span>
        </BudgetLabel>
      );
    },
    key: 'consumed'
  }
];

/**
 * List extended columns.
 */

const listExtendedColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.id'),
    size: '30px',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: translate('common.table.columns.date'),
    dataIndex: 'date',
    size: '150px',
    key: 'date',
    render: (item: Transaction) => moment(item.date).format('DD-MM-YYYY')
  },
  {
    title: translate('common.table.columns.description'),
    dataIndex: 'description',
    size: '3fr',
    key: 'description'
  },
  {
    title: translate('common.table.columns.amount'),
    dataIndex: 'amount',
    key: 'amount',
    style: { justifyContent: 'flex-end' },
    render: (item: Transaction) => formatCurrency(item.amount)
  }
];

/**
 * Form list.
 */

const formFields = (translate: TFunction): FormList => [
  {
    type: 'selectField',
    name: 'categoryId',
    label: translate('common.labels.category'),
    rules: requiredRule(translate),
    options: {
      hook: useCrudRequest as any,
      hookProps: (values: any, params: any) => {
        return [
          {
            key: ['categories'],
            options: {
              interpolations: params
            },
            endpoint: apiEndpoints.categories
          }
        ];
      },
      normalize: (items: Category[]) => {
        return items?.map(item => ({
          value: item.id,
          label: item.name
        }));
      }
    }
  },
  {
    type: 'if',
    name: 'need_category_id',
    watchFields: ['categoryId'],
    condition: ([categoryId]: any) => !!categoryId,
    thenIf: [
      {
        type: 'selectField',
        name: 'subCategoryId',
        label: translate('common.labels.subCategory'),
        rules: requiredRule(translate),
        options: {
          hook: useCrudRequest as any,
          hookProps: (values: any, params: any) => {
            return [
              {
                key: ['subCategories', values.categoryId],
                options: {
                  interpolations: params,
                  params: { categoryId: get(values, ['categoryId']) }
                },
                endpoint: apiEndpoints.subCategories
              }
            ];
          },
          normalize: (items: Category[]) => {
            return items?.map(item => ({
              value: item.id,
              label: item.name
            }));
          }
        }
      },
      {
        type: 'selectField',
        name: 'type',
        label: translate('common.labels.type'),
        rules: requiredRule(translate),
        options: [
          {
            value: 'month',
            label: String(translate('common.labels.month'))
          },
          {
            value: 'year',
            label: String(translate('common.labels.year'))
          }
        ]
      },
      {
        type: 'checkBoxField',
        name: 'isIncome',
        label: translate('common.labels.isIncome'),
        rules: undefined
      },
      {
        type: 'inputNumberField',
        name: 'amount',
        label: translate('common.labels.budget'),
        rules: requiredRule(translate)
      }
    ]
  }
];

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType => ({
  formFields: formFields(translate),
  endpoint: apiEndpoints.budgetsAdd,
  normalizePayload: (values: any) => ({
    ...omit(values, ['categoryId']),
    amount: new BigNumber(values.amount).toFixed(2),
    isIncome: !!values.isIncome
  }),
  redirect: appRoutes.dashboard.bankAccounts.budgets.base,
  submitLabel: translate('common.actions.add'),
  route: appRoutes.dashboard.bankAccounts.budgets.add
});

/**
 * List.
 */

const list = (translate: TFunction): CrudListType => ({
  append: { append: 'transactions' },
  columns: listColumns(translate),
  expandedColumns: listExtendedColumns(translate),
  expandedDataKey: item => sortBy(item.transactions, 'date').reverse(),
  route: appRoutes.dashboard.bankAccounts.budgets.base,
  addButton: translate('common.actions.add'),
  canEdit: true,
  canRemove: true,
  key: ['budgets'],
  normalizeData: (result: any) => {
    for (const item of result.results) {
      item.category = item.subCategory.category;
    }

    result.results = sortBy(result.results, item => item.category.name);

    return result;
  },
  endpoint: apiEndpoints.budgets,
  endpointById: apiEndpoints.budgetsById
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType => ({
  endpoint: apiEndpoints.budgetsDelete
});

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType => ({
  formFields: formFields(translate),
  endpoint: apiEndpoints.budgetsEdit,
  route: appRoutes.dashboard.bankAccounts.budgets.edit,
  submitLabel: translate('common.actions.edit'),
  normalizePayload: (values: any) => {
    return {
      ...omit(values, ['categoryId']),
      amount: new BigNumber(values.amount).toFixed(2),
      isIncome: !!values.isIncome
    };
  },
  normalizeInitialValues: (keys: string[], payload: Budget) => {
    if (!payload) {
      return undefined;
    }

    return {
      categoryId: payload.subCategory.category.id,
      ...pick(payload, keys)
    };
  }
});

/**
 * Config.
 */

export function createBudgetsTemplate(translate: TFunction): CrudTemplate {
  return {
    add: add(translate),
    edit: edit(translate),
    list: list(translate),
    remove: remove()
  };
}
