/**
 * Module dependencies.
 */

import classnames from 'classnames';
import React, { ReactNode } from 'react';
import styles from './budgets.module.less';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  isIncome: boolean;
  noBreak?: boolean;
  isCurrency?: boolean;
  children: ReactNode;
};

/**
 * Export `BudgetLabel` component.
 */

export function BudgetLabel({ className, isCurrency, noBreak, isIncome, children }: Props): JSX.Element {
  return (
    <span
      className={classnames(className, styles.budgetLabel, {
        [styles.isIncome]: isIncome,
        [styles.isCurrency]: isCurrency,
        [styles.noBreak]: noBreak
      })}
    >
      {children}
    </span>
  );
}
