/**
 * Module dependencies.
 */

import { Alert } from 'antd';
import { requiredRule } from 'app/core/utils/field-rules';
import { useBankAccounts } from 'app/hooks/requests/bank-accounts/use-bank-accounts';
import { BankAccount } from 'app/types/bank-account';
import { useCheckAccount } from '../hooks/use-check-account';
import { FormList } from 'app/components/organisms/crud/form-types';
import React from 'react';
import styles from '../movements.module.less';
import { TFunction } from 'react-i18next';
import { formFieldsAccountSelected } from './form-fields-account-selected';

/**
 * Form list.
 */

export const formFields = (translate: TFunction, data: any): FormList => [
  {
    type: 'selectField',
    name: 'accountId',
    label: translate('common.labels.account'),
    rules: requiredRule(translate),
    options: {
      hook: useBankAccounts,
      hookProps: () => [{ append: 'transactions' }],
      normalize: (items: BankAccount[]) =>
        items?.map(item => ({
          value: item.id,
          label: item.name
        }))
    }
  },
  {
    type: 'if',
    name: 'need_account_id',
    condition: {
      hook: useCheckAccount
    },
    elseIf: [
      {
        type: 'render',
        name: 'invalid_bank_account',
        render: (
          <Alert
            className={styles.warningBox}
            description={translate('bankAccounts.add.invalidAccount.description')}
            message={translate('bankAccounts.add.invalidAccount.title')}
            showIcon
            type={'error'}
          />
        )
      }
    ],
    thenIf: formFieldsAccountSelected(translate, data)
  }
];
