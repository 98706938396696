/**
 * Module dependencies.
 */

import React from 'react';
import styles from './stats-item.module.less';

/**
 * `Props` type.
 */

type Props = {
  label: string;
  value: string;
};

/**
 * Export `StatsItem` component.
 */

export function StatsItem({ label, value }: Props): JSX.Element {
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>{label}</div>

      <div className={styles.value}>{value}</div>
    </div>
  );
}
