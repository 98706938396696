/**
 * Module dependencies.
 */

import { useEffect, useState } from 'react';

/**
 * Export `useDebounce`.
 */

export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
}

/**
 * Export `useDebounceLoading`.
 */

export function useDebounceLoading(value: boolean, delayEnter: number, delayExit: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    let handler: any;

    if (value) {
      handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delayEnter);
    } else {
      handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delayExit);
    }

    return () => {
      clearTimeout(handler);
    };
  }, [delayEnter, delayExit, value]);

  return debouncedValue;
}
