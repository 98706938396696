/**
 * Export `template` util.
 *
 * @param url endpoint to interpolate.
 * @param params interpolation parameters.
 * @returns interpolated endpoint.
 */

export function template(url: string, params: any) {
  let urlResult = url;

  for (const key in params) {
    urlResult = urlResult.replace(`\${${key}}`, params[key]);
  }

  return urlResult;
}

/**
 * Cast function.
 */

export function castFunction(item: any, ...rest: any[]) {
  return typeof item === 'function' ? item(...rest) : item;
}
