// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__total--ZEIhc .styles-module__line--oB_vq {
  padding-right: 50px;
  padding-top: 40px;
  grid-column: 8 / 12;
}
.styles-module__total--ZEIhc .styles-module__margin--ME_ym {
  padding-top: 40px;
}
`, "",{"version":3,"sources":["webpack://./components/pages/projects/project/movements/styles.module.less"],"names":[],"mappings":"AAGA;EAGI,mBAAA;EACA,iBAAA;EACA,mBAAA;AAJJ;AADA;EASI,iBAAA;AALJ","sourcesContent":["\n\n\n.total {\n  .line {\n\n    padding-right: 50px;\n    padding-top: 40px;\n    grid-column: 8 / 12;\n  }\n\n  .margin {\n    padding-top: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"total": `styles-module__total--ZEIhc`,
	"line": `styles-module__line--oB_vq`,
	"margin": `styles-module__margin--ME_ym`
};
export default ___CSS_LOADER_EXPORT___;
