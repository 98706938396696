/**
 * Module dependencies.
 */

import React, { ReactNode, createContext, useContext } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  client: any;
};

/**
 * `RequestContextType` type.
 */

type RequestContextType = any;

/**
 * `RequestContext` context.
 */

const RequestContext = createContext<RequestContextType>(null);

/**
 * Export `useRequestProvider`.
 */

export function useRequestProvider() {
  return useContext(RequestContext);
}

/**
 * `RequestProvider` container.
 */

function RequestProvider({ children, client }: Props): JSX.Element {
  return <RequestContext.Provider value={client}>{children}</RequestContext.Provider>;
}

/**
 * Export `RequestProvider` container.
 */

export default RequestProvider;
