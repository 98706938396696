/**
 * Module dependencies.
 */

import classnames from 'classnames';
import React, { useLayoutEffect, useRef, useState } from 'react';
import styles from './table.module.less';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  children: JSX.Element;
  style?: any;
  expanded: boolean;
};

/**
 * Export `Expanded` component.
 */

export function Expanded({ children, expanded, className, style }: Props): JSX.Element {
  const ref = useRef<any>();
  const [height, setHeight] = useState<number | undefined>();

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    if (expanded) {
      setHeight(ref.current.offsetHeight);
    }
  }, [expanded]);

  return (
    <div
      className={classnames(styles.collapseRow, className)}
      style={{
        ...(style ? style : {}),
        height: expanded ? height : 0
      }}
    >
      <div ref={ref}>{children}</div>
    </div>
  );
}
