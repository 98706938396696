/**
 * Module dependencies.
 */

import { Loading } from 'app/components/atoms/loading/loading';
import { Title } from 'app/components/atoms/title/title';
import { Column, Table } from 'app/components/organisms/table/table';
import React, { useMemo } from 'react';
import { ExpandedRow } from './expanded-row';
import { TableFooter } from './table-footer';
import BigNumber from 'bignumber.js';
import { formatCurrency } from 'app/core/utils/formatter';
import { useTranslation } from 'react-i18next';
import { CryptoAsset, useCryptoOverview } from 'app/hooks/requests/crypto/use-crypto-overview';
import { compact, reduce, toLower } from 'lodash';
import styles from './overview.module.less';
import { Button } from 'app/components/atoms/button/button';

/**
 * Export `CryptoOverview` component.
 */

export function CryptoOverview(): JSX.Element {
  const [translate] = useTranslation();
  const { data, isLoading } = useCryptoOverview();
  const columns = useMemo(
    (): Column<CryptoAsset>[] => [
      {
        title: translate('common.table.columns.empty'),
        key: 'icon',
        render: item => {
          const src = `/api/crypto/icon/${toLower(item.name)}`;

          return <img alt={item.name} height={'24'} src={src} width={'24'} />;
        }
      },
      {
        title: translate('common.table.columns.name'),
        key: 'name',
        size: '1fr'
      },
      {
        title: translate('common.table.columns.total'),
        style: { justifyContent: 'flex-end' },
        styleRow: { fontFamily: 'Oswald' },
        key: 'total'
      },
      {
        title: translate('common.table.columns.priceUSDT'),
        style: { justifyContent: 'flex-end' },
        styleRow: { fontFamily: 'Oswald' },
        key: 'priceUSDT',
        render: item => formatCurrency(item.priceUSDT, { currency: 'USD', locale: 'en', decimalPlacesToDisplay: 6 })
      },
      {
        title: translate('common.table.columns.valueUSDT'),
        style: { justifyContent: 'flex-end' },
        styleRow: { fontFamily: 'Oswald' },
        key: 'valueUSDT',
        render: item => formatCurrency(item.valueUSDT, { currency: 'USD', locale: 'en', decimalPlacesToDisplay: 6 })
      },
      {
        title: translate('common.table.columns.priceEUR'),
        style: { justifyContent: 'flex-end' },
        styleRow: { fontFamily: 'Oswald' },
        key: 'priceEUR',
        render: item => formatCurrency(item.priceEUR, { currency: 'EUR', locale: 'en', decimalPlacesToDisplay: 6 })
      },
      {
        title: translate('common.table.columns.valueEUR'),
        style: { justifyContent: 'flex-end' },
        styleRow: { fontFamily: 'Oswald' },
        key: 'valueEUR',
        render: item => formatCurrency(item.valueEUR, { currency: 'EUR', locale: 'en', decimalPlacesToDisplay: 6 })
      }
    ],
    [translate]
  );

  const dataSource = useMemo(() => {
    const result = compact(Object.values(data ?? {}))
      .map(obj => {
        const { item } = reduce(
          obj.positions,
          (acc: any, position) => {
            position.valueUSDT = new BigNumber(position.priceUSDT).multipliedBy(position.amount).toString();
            position.valueEUR = new BigNumber(position.priceEUR).multipliedBy(position.amount).toString();

            if (acc.price.isGreaterThan(position.priceUSDT)) {
              return {
                price: new BigNumber(position.priceUSDT),
                item: position
              };
            }

            return { ...acc };
          },
          {
            price: new BigNumber(Infinity),
            item: null
          }
        );

        obj.priceUSDT = item.priceUSDT;
        obj.priceEUR = item.priceEUR;

        if (obj.priceUSDT) {
          obj.valueUSDT = new BigNumber(obj.priceUSDT).times(obj.total).toString();
        }

        if (obj.priceEUR) {
          obj.valueEUR = new BigNumber(obj.priceEUR).times(obj.total).toString();
        }

        return obj;
      })
      .sort((itemA, itemB) => {
        const valueA = new BigNumber((itemA as any).valueUSDT);
        const valueB = new BigNumber((itemB as any).valueUSDT);

        if (valueA.isGreaterThan(valueB)) {
          return -1;
        }

        if (valueA.isLessThan(valueB)) {
          return 1;
        }

        return 0;
      });

    return result;
  }, [data]);

  return (
    <div>
      <div className={styles.header}>
        <Title className={styles.title}>{translate('crypto.assets.title')}</Title>

        <Button disabled>{'Refresh'}</Button>
      </div>

      <Loading isLoading={isLoading}>
        <Table
          className={styles.table}
          columns={columns}
          dataSource={dataSource}
          expandedRowRender={item => {
            if (!item.expanded) {
              return null;
            }

            return <ExpandedRow item={item} />;
          }}
          footer={<TableFooter data={dataSource} />}
          rowKey={'name'}
        />
      </Loading>
    </div>
  );
}
