/**
 * Module dependencies.
 */

import React, { createContext, useCallback, useContext, useRef, useState } from 'react';

/**
 * Storage key.
 */

const storageKey = 'STORAGE';

/**
 * `Props` type.
 */

type Props = {
  children: JSX.Element;
};

/**
 * `StorageContext` type.
 */

type StorageContext = {
  data: any;
  getData: () => any;
  saveData: (data: any) => void;
};

/**
 * Storage context.
 */

const StorageProviderContext = createContext<StorageContext>({
  data: null,
  getData: () => null,
  saveData: () => null
});

/**
 * Initial data.
 */

const initialData = {
  basket: []
};

/**
 * Get store.
 */

function getStore() {
  const data = localStorage.getItem(storageKey);

  try {
    return JSON.parse(data as string) ?? initialData;
  } catch (error) {
    return initialData;
  }
}

/**
 * Save store.
 */

function saveStore(store: any) {
  localStorage.setItem(storageKey, JSON.stringify(store));
}

/**
 * Export `useStorage`.
 */

export function useStorage() {
  return useContext(StorageProviderContext);
}

/**
 * Export `StorageProvider` component.
 */

export function StorageProvider({ children }: Props): JSX.Element {
  const [data, setData] = useState(() => {
    return getStore();
  });

  const dataRef = useRef(data);

  dataRef.current = data;

  const saveData = useCallback(async (data: any) => {
    let payload = data;
    if (typeof data === 'function') {
      payload = data(dataRef.current);
    }
    await saveStore(payload);

    setData(JSON.parse(JSON.stringify(payload)));
  }, []);

  const getData = useCallback(() => {
    return dataRef.current;
  }, []);

  return (
    <StorageProviderContext.Provider value={{ data, saveData, getData }}>{children}</StorageProviderContext.Provider>
  );
}
