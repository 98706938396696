/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { Bank } from 'app/types/bank-account';

/**
 * Connect bank account redirect url.
 */

function connectBankAccountRedirectUrl(
  client: AxiosInstance,
  bank: Bank,
  accountId: string
): Promise<{ url: string; showLocalCredentials: boolean }> {
  return client({
    method: 'post',
    url: resolveAppUrl('bankAccountConnectRedirectUrl'),
    data: {
      bank: bank,
      accountId
    }
  }).then(({ data }) => {
    return data?.data;
  });
}

/**
 * Export `useBankAccountConnectRedirectUrl`.
 */

export function useBankAccountConnectRedirectUrl() {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async ({ bank, accountId }: { bank: Bank; accountId: string }) => {
      const result = await connectBankAccountRedirectUrl(client, bank, accountId);

      return result;
    },
    onSuccess: () => {
      // Nothing to do.
    }
  });
}
