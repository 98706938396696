/**
 * Module dependencies.
 */

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import {
  CrudAddType,
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  ListColumns
} from 'app/components/organisms/crud/form-types';

import { normalizeTemplate } from 'app/components/organisms/crud/utils';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import { appRoutes } from 'app/routes';
import { TFunction } from 'i18next';
import { formFields } from './form-fields';
import { omit } from 'lodash';
import React from 'react';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'id',
    size: '30px',
    key: 'id'
  },
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: translate('common.table.columns.name'),
    size: '1fr',
    dataIndex: 'address',
    key: 'address',
    render: (item: any) => (
      <div
        style={{
          maxWidth: '50vw',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 12
        }}
      >
        {item.address}
      </div>
    )
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: String(translate('common.labels.search'))
  }
];

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType => ({
  formFields: formFields(translate),
  normalizePayload: (values: any) => ({
    ...values,
    automatic: !!values.automatic
  })
});

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType => ({
  formFields: formFields(translate),
  normalizePayload: (values: any) => ({
    ...omit(values, ['userId']),
    automatic: !!values.automatic
  })
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType => ({});

/**
 * List.
 */

const list = (translate: TFunction): CrudListType => ({
  columns: listColumns(translate),
  route: appRoutes.dashboard.crypto.wallets,
  key: ['cryptoWallets'],
  endpoint: apiEndpoints.cryptoWallets,
  filters: listFilters(translate)
});

/**
 * Config.
 */

export function createCryptoWalletsTemplate(translate: TFunction): CrudTemplate {
  return normalizeTemplate(translate, {
    add: add(translate),
    edit: edit(translate),
    list: list(translate),
    remove: remove()
  });
}
