/**
 * Module dependencies.
 */

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import {
  CrudAddType,
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  FormList,
  ListColumns
} from 'app/components/organisms/crud/form-types';
import { normalizeTemplate } from 'app/components/organisms/crud/utils';

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { requiredRule } from 'app/core/utils/field-rules';
import { useCrudRequest } from 'app/hooks/requests/crud/use-crud-request';
import { appRoutes } from 'app/routes';
import { ProjectBudget, ProjectSupplier } from 'app/types/project';
import { TFunction } from 'i18next';
import { Stats } from './stats';
import BigNumber from 'bignumber.js';
import { formatCurrency } from 'app/core/utils/formatter';
import React from 'react';
import { RenderIva } from 'app/components/molecules/render-iva/render-iva';

/**
 * Has selected proposal.
 */

export function hasSelectedProposal(item: any, append?: any) {
  if (item && item.selectedBudget) {
    return {
      color: '#116811',
      backgroundColor: 'rgba(255,255,255,0.1)',
      ...(append ?? {})
    };
  }

  return append;
}

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'id',
    size: '30px',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start'
      }),
    key: 'id'
  },
  {
    title: translate('common.table.columns.budgetName'),
    dataIndex: 'budget',
    key: 'budget',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start'
      }),
    render: item => item.budgetItem.name
  },
  {
    title: translate('common.table.columns.supplier'),
    dataIndex: 'supplier',
    key: 'supplier',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start'
      }),
    render: item => item.supplier.company
  },
  {
    title: translate('common.table.columns.subject'),
    dataIndex: 'title',
    key: 'title',
    size: '1fr',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        flex: 1
      }),
    render: item => item.title
  },
  {
    title: translate('common.table.columns.description'),
    dataIndex: 'description',
    size: '1fr',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        minWidth: '300px'
      }),
    key: 'description'
  },
  {
    title: translate('common.table.columns.ivaPerc'),
    dataIndex: 'ivaPerc',
    key: 'ivaPerc',
    size: '50px',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      }),
    styleRow: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        fontFamily: 'Oswald',
        justifyContent: 'flex-end'
      }),
    render: () => <RenderIva />
  },
  {
    title: translate('common.table.columns.ivaRange'),
    dataIndex: 'ivaRange',
    key: 'ivaRange',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      }),
    styleRow: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        fontFamily: 'Oswald',
        justifyContent: 'flex-end'
      }),
    render: item => `${item.ivaRange}%`
  },
  {
    title: translate('common.table.columns.amount'),
    dataIndex: 'amount',
    key: 'amount',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      }),
    styleRow: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        fontFamily: 'Oswald',
        justifyContent: 'flex-end'
      }),
    render: item => formatCurrency(item.budget)
  },
  {
    title: translate('common.table.columns.ivaTotalCharged'),
    dataIndex: 'ivaTotalCharged',
    key: 'ivaTotalCharged',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      }),
    styleRow: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        fontFamily: 'Oswald',
        justifyContent: 'flex-end'
      }),
    render: item => formatCurrency(item.ivaTotalCharged)
  },
  {
    title: translate('common.table.columns.ivaAmountApplied'),
    dataIndex: 'notivaAmountAppliedes',
    key: 'ivaAmountApplied',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      }),
    styleRow: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        fontFamily: 'Oswald',
        justifyContent: 'flex-end'
      }),
    render: item => formatCurrency(item.ivaAmountApplied)
  },
  {
    title: translate('common.table.columns.total'),
    dataIndex: 'total',
    key: 'total',
    style: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        paddingLeft: 64
      }),
    styleRow: (item: any) =>
      hasSelectedProposal(item, {
        alignItems: 'flex-start',
        fontFamily: 'Oswald',
        justifyContent: 'flex-end'
      }),
    render: item => formatCurrency(item.total)
  }
];

/**
 * Form list.
 */

const formFields = (translate: TFunction): FormList => [
  {
    type: 'columns',
    name: 'mainWire',
    columns: [
      {
        column: 5,
        children: [
          {
            type: 'selectField',
            name: 'budgetId',
            showSearch: true,
            label: translate('common.labels.budgetItem'),
            rules: requiredRule(translate),
            options: {
              hook: useCrudRequest as any,
              hookProps: (values, params) => {
                return [
                  {
                    key: ['budgets', params.projectId],
                    options: { interpolations: params },
                    endpoint: apiEndpoints.projectBudgets
                  }
                ];
              },
              normalize: (items: ProjectBudget[]) => {
                return (
                  items?.map(item => ({
                    value: item.id,
                    label: item.name
                  })) ?? []
                );
              }
            }
          },
          {
            type: 'selectField',
            name: 'supplierId',
            showSearch: true,
            label: translate('common.labels.supplier'),
            rules: requiredRule(translate),
            options: {
              hook: useCrudRequest as any,
              hookProps: (values, params) => {
                return [
                  {
                    key: ['supplier', params.projectId],
                    options: { interpolations: params },
                    endpoint: apiEndpoints.projectSuppliers
                  }
                ];
              },
              normalize: (items: ProjectSupplier[]) => {
                return (
                  items?.map(item => ({
                    value: item.id,
                    label: item.company
                  })) ?? []
                );
              }
            }
          },
          {
            type: 'inputNumberField',
            name: 'budget',
            label: translate('common.labels.amount'),
            rules: undefined
          },
          {
            type: 'inputField',
            name: 'title',
            label: translate('common.labels.subject'),
            rules: requiredRule(translate)
          },
          {
            type: 'inputField',
            name: 'description',
            inputType: 'textArea',
            label: translate('common.labels.notes'),
            rules: undefined
          },
          {
            type: 'sliderField',
            name: 'ivaRange',
            label: translate('common.labels.ivaRange'),
            rules: requiredRule(translate)
          }
        ]
      },
      {
        column: 5,
        children: [
          {
            type: 'render',
            name: 'stats',
            renderComponent: Stats
          }
        ]
      }
    ]
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'selectSearch',
    name: 'budgetId',
    inline: true,
    style: {
      minWidth: 250
    },
    label: translate('common.labels.budgetItem'),
    showSearch: true,
    allowClear: true,
    options: {
      hook: useCrudRequest as any,
      hookProps: (values, params) => {
        return [
          {
            key: ['budgets', params.projectId],
            options: { interpolations: params },
            endpoint: apiEndpoints.projectBudgets
          }
        ];
      },
      normalize: (items: ProjectBudget[]) => {
        return (
          items?.map(item => ({
            value: item.id,
            label: item.name
          })) ?? []
        );
      }
    }
  },
  {
    type: 'selectSearch',
    name: 'supplierId',
    inline: true,
    style: {
      minWidth: 250
    },
    label: translate('common.labels.supplier'),
    showSearch: true,
    allowClear: true,
    options: {
      hook: useCrudRequest as any,
      hookProps: (values, params) => {
        return [
          {
            key: ['supplier', params.projectId],
            options: { interpolations: params },
            endpoint: apiEndpoints.projectSuppliers
          }
        ];
      },
      normalize: (items: ProjectSupplier[]) => {
        return (
          items?.map(item => ({
            value: item.id,
            label: item.company
          })) ?? []
        );
      }
    }
  }
];

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType => ({
  formFields: formFields(translate),
  normalizeInitialValues: (formkeys, values: any) => {
    return {
      ivaRange: 100,
      ...(values ?? {})
    };
  },
  normalizePayload: (values: any) => ({
    budget: !values.budget ? null : new BigNumber(values.budget).toString(),
    supplierId: values.supplierId,
    budgetId: values.budgetId,
    title: values.title,
    description: values.description,
    proposalLink: values.proposalLink,
    notes: values.notes,
    ivaRange: new BigNumber(values.ivaRange).toString()
  })
});

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType => ({
  formFields: formFields(translate),
  normalizeInitialValues: (keys: string[], values: any) => {
    return {
      ...values,
      ivaRange: values?.ivaRange ? parseFloat(values?.ivaRange) : 0
    };
  },
  normalizePayload: (values: any) => ({
    id: values.id,
    budget: !values.budget ? null : new BigNumber(values.budget).toString(),
    supplierId: values.supplierId,
    budgetId: values.budgetId,
    title: values.title,
    description: values.description,
    proposalLink: values.proposalLink,
    notes: values.notes,
    ivaRange: new BigNumber(values.ivaRange).toString()
  })
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType => ({});

/**
 * List.
 */

const list = (translate: TFunction): CrudListType => ({
  columns: listColumns(translate),
  style: { minWidth: 500 },
  route: appRoutes.dashboard.projects.project.proposals,
  key: ['projectProposals'],
  canRemove: item => !item.selectedBudget,
  endpoint: apiEndpoints.projectProposals,
  filters: listFilters(translate),
  defaultQueryString: 'filter=%7B"orderBy"%3A"budgetId%23asc"%7D&filterOpened=true',
  normalizeFilters: (data: any) => {
    const { orderBy, ...filters } = data.filters;
    const result: any = { filters, order: [] };

    if (orderBy) {
      const parts = orderBy.split('#');

      result.order.push({ key: parts[0], direction: parts[1] });
    }

    result.order.push({ key: 'budgetId', direction: 'asc' });

    return result;
  }
});

/**
 * Config.
 */

export function createProjectProposalsTemplate(translate: TFunction): CrudTemplate {
  return normalizeTemplate(translate, {
    add: add(translate),
    edit: edit(translate),
    list: list(translate),
    remove: remove()
  });
}
