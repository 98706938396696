/**
 * Module dependencies.
 */

import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import React, { ForwardedRef, forwardRef } from 'react';
import { Slider, SliderProps } from './slider';

/**
 * `Props` type.
 */

type Props<T extends FieldValues> = UseControllerProps<T> &
  SliderProps & {
    normalizeValue?: (newValue: string, value: string) => string;
  };

/**
 * `SliderFieldComponent` component.
 */

export function SliderFieldComponent<T extends FieldValues>(
  props: Props<T>,
  forwardedRef: ForwardedRef<any>
): JSX.Element {
  const { control, defaultValue, name, normalizeValue, rules, shouldUnregister, ...inputProps } = props;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { name, onBlur, onChange, value }, fieldState: { error, invalid, isDirty, isTouched } }) => {
        return (
          <Slider
            invalid={invalid}
            isDirty={isDirty}
            isTouched={isTouched}
            name={name}
            onBlur={onBlur as any}
            value={value}
            {...inputProps}
            error={error}
            onChange={newValue => {
              const finalValue = normalizeValue ? normalizeValue(String(newValue), value) : newValue;

              onChange(parseFloat(finalValue as any));

              if (inputProps.onChange) {
                inputProps.onChange(finalValue);
              }
            }}
            ref={forwardedRef}
          />
        );
      }}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  );
}

/**
 * Export `SliderField`.
 */

export const SliderField = forwardRef<Props<any>, any>(SliderFieldComponent);
