/**
 * Module dependencies.
 */

import { useCrudAdd } from 'app/hooks/requests/crud/use-crud-add';
import { identity, reduce } from 'lodash';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CrudForm } from './crud-form';
import { CrudAddType, CrudTemplate } from './form-types';
import { resolveFormKeys } from './crud-edit';
import { CrudTemplateProvider } from './crud-template-provider';

/**
 * `Props` type.
 */

type Props = {
  template: CrudTemplate;
};

/**
 * Default normalize inicial values.
 */

function defaultNormalizeInitialValues(formKeys: string[], initialValues: any): any {
  return initialValues;
}

/**
 * Export `CrudAdd` component.
 */

export function CrudAdd({ template }: Props): JSX.Element {
  const {
    formFields,
    redirect,
    normalizePayload = identity,
    submitLabel,
    normalizeInitialValues = defaultNormalizeInitialValues
  } = template.add as CrudAddType;

  const routeList = template?.list?.route;
  const params = useParams();
  const [searchParams] = useSearchParams();
  const initialValues = useMemo(() => {
    const result = reduce(
      template.add?.acceptQueryFields,
      (acc: any, field: any) => {
        const { name, parser } =
          typeof field !== 'string'
            ? field
            : {
                name: field,
                parser: identity
              };

        return {
          [name]: parser(searchParams.get(name))
        };
      },
      {}
    );

    return result;
  }, [searchParams, template.add?.acceptQueryFields]);

  const formKeys = useMemo(() => {
    if (!template.add?.formFields) {
      return [];
    }

    return resolveFormKeys(formFields);
  }, [formFields, template.add?.formFields]);

  const { mutate: add } = useCrudAdd(template, params);
  const navigate = useNavigate();
  const form = useForm({
    values: normalizeInitialValues?.(formKeys, initialValues),
    mode: 'all'
  });

  const onSubmit = async (values: any) => {
    add(normalizePayload(values), {
      onSuccess: () => {
        navigate(redirect ?? routeList ?? '');
      }
    });
  };

  return (
    <CrudTemplateProvider template={template}>
      <CrudForm
        form={form}
        formFields={formFields}
        formType={'add'}
        onSubmit={onSubmit as any}
        submitLabel={submitLabel}
      />
    </CrudTemplateProvider>
  );
}
