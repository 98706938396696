/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { EndpointKey, EndpointValue, resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { CrudTemplate } from 'app/components/organisms/crud/form-types';
import { useCrudRequestInvalidator } from './use-crud-list';

/**
 * Remove item.
 */

function removeItem(
  client: AxiosInstance,
  endpoint: EndpointKey | EndpointValue,
  params: any,
  id: string
): Promise<void> {
  return client.delete(resolveAppUrl(endpoint, { interpolations: { ...params, id: id } })).then(({ data }) => data);
}

/**
 * Export `useCrudDelete`.
 */

export function useCrudDelete(template: CrudTemplate, params: any) {
  const client = useRequestProvider();
  const invalidator = useCrudRequestInvalidator(template.list?.key);

  return useMutation({
    mutationFn: async (id: string) => {
      if (!template?.remove?.endpoint) {
        console.error('invalid config REMOVE', id, template);
        throw new Error('invalid config REMOVE');
      }

      const result = await removeItem(client, template?.remove?.endpoint, params, id);

      await invalidator();

      return result;
    }
  });
}
