/**
 * Module dependencies.
 */

import { SubTitle } from 'app/components/atoms/sub-title/sub-title';
import { Field } from 'app/components/organisms/field/field';
import { formatCurrency } from 'app/core/utils/formatter';
import React, { useMemo } from 'react';
import styles from './table-footer.module.less';
import BigNumber from 'bignumber.js';
import { reduce } from 'lodash';
import { Text } from 'app/components/atoms/text/text';
import { sumProposals } from './utils';
import { useTranslation } from 'react-i18next';

/**
 * `Props` type.
 */

type Props = {
  data: any[] | undefined;
};

/**
 * Export `OpenClose` component.
 */

export function OpenClose({ data }: Props): JSX.Element {
  const [translate] = useTranslation();
  const { closed, open, initialClosed } = useMemo(() => {
    return reduce(
      data,
      (acc, item) => {
        const totalProposals = sumProposals(item);

        acc.closed = totalProposals ? acc.closed.plus(totalProposals) : acc.closed;
        acc.initialClosed = totalProposals ? acc.initialClosed.plus(item.prevision) : acc.initialClosed;
        acc.open = item.proposals.length === 0 && !item.withoutProposal ? acc.open.plus(item.prevision) : acc.open;

        return {
          ...acc
        };
      },
      {
        closed: new BigNumber('0'),
        initialClosed: new BigNumber('0'),
        open: new BigNumber('0')
      }
    );
  }, [data]);

  return (
    <div className={styles.line}>
      <SubTitle className={styles.subTitle}>{translate('common.labels.budget')}</SubTitle>

      <Text className={styles.info}>{'Indicação de valores em divida a fornecedores.'}</Text>

      <Field
        info={'soma de todas as propostas fechadas com o valor final já com IVA previsto'}
        title={'Fechado'}
        value={formatCurrency(new BigNumber(closed).toFixed(2))}
      />

      <Field
        info={'Soma da previsão de todos as propostas fechadas.'}
        title={'Fechado (Budget)'}
        value={formatCurrency(new BigNumber(initialClosed).toFixed(2))}
      />

      <Field
        info={'Diferença entre budget inicial com propostas já fechadas.'}
        title={'Diferença Budget'}
        value={formatCurrency(new BigNumber(initialClosed).minus(closed).toFixed(2))}
      />

      <Field
        info={'Soma de todas as previsões que ainda não contém propostas fechadas'}
        title={'Aberto'}
        value={formatCurrency(new BigNumber(open).toFixed(2))}
      />
    </div>
  );
}
