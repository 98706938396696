// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__wrapper--MI3UG {
  display: grid;
}
.styles-module__wrapper--MI3UG .styles-module__bank--WyZyx {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.styles-module__wrapper--MI3UG .styles-module__bank--WyZyx .styles-module__logo--Cvs36 {
  width: 32px;
  margin-right: 16px;
}
.styles-module__wrapper--MI3UG .styles-module__bank--WyZyx .styles-module__title--MJzr4 {
  font-size: 16px;
}
.styles-module__wrapper--MI3UG .styles-module__bank--WyZyx .styles-module__amountWrapper--u4tgT {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.styles-module__wrapper--MI3UG .styles-module__transaction--kodDg {
  margin-top: 8px;
  padding: 6px 12px;
  border-radius: 12px;
  background-color: #ddd;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
`, "",{"version":3,"sources":["webpack://./components/pages/projects/project/movements/transaction-item/styles.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;AAAA;EAII,aAAA;EACA,mBAAA;EACA,mBAAA;AADJ;AALA;EASM,WAAA;EACA,kBAAA;AADN;AATA;EAcM,eAAA;AAFN;AAZA;EAkBM,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAHN;AAlBA;EA0BI,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AALJ","sourcesContent":["\n\n.wrapper {\n  display: grid;\n\n  .bank {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n    .logo {\n      width: 32px;\n      margin-right: 16px;\n    }\n\n    .title {\n      font-size: 16px;\n    }\n\n    .amountWrapper {\n      width: 100%;\n      display: flex;\n      flex-direction: row;\n      justify-content: space-between;\n    }\n  }\n\n  .transaction {\n    margin-top: 8px;\n    padding: 6px 12px;\n    border-radius: 12px;\n    background-color: #ddd;\n    display: flex;\n    justify-content: space-between;\n    flex-direction: row;\n\n\n\n  }\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles-module__wrapper--MI3UG`,
	"bank": `styles-module__bank--WyZyx`,
	"logo": `styles-module__logo--Cvs36`,
	"title": `styles-module__title--MJzr4`,
	"amountWrapper": `styles-module__amountWrapper--u4tgT`,
	"transaction": `styles-module__transaction--kodDg`
};
export default ___CSS_LOADER_EXPORT___;
