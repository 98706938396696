/**
 * Module dependencies.
 */

import { QueryCache, QueryClient } from '@tanstack/react-query';

/**
 * Export `createQueryClient`.
 */

export function createQueryClient(showError: any, showSuccess: any) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        suspense: false,
        retry: 0
      },
      mutations: {
        useErrorBoundary: false,
        onError: showError as any,
        onSuccess: showSuccess as any,
        retry: 0
      }
    },
    queryCache: new QueryCache({
      onError: showError as any
    })
  });

  return queryClient;
}
