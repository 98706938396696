/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Project } from 'app/types/project';

/**
 * Get project.
 */

function getProject(client: AxiosInstance, id: string | number): Promise<Project> {
  return client.get(resolveAppUrl('projectById', { interpolations: { id } })).then(({ data }) => data?.data);
}

/**
 * Export `useProjectsCacheInvalidator`.
 */

export function useProjectsCacheInvalidator() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(['projectById']);
  }, [queryClient]);
}

/**
 * Export `useProject`.
 */

export function useProject(id: string | number | undefined) {
  const client = useRequestProvider();

  return useQuery(['project', id], {
    queryFn: async () => {
      if (id === undefined) {
        return null;
      }

      return await getProject(client, id);
    }
  });
}
