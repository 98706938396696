/**
 * Module dependencies.
 */

import React from 'react';
import styles from './error-field.module.less';

/**
 * `FormError` type.
 */

type FormError = {
  type: string;
  message: string;
};

/**
 * `Props` type.
 */

type Props = {
  error: FormError;
  isVisible: boolean | undefined;
};

/**
 * Export `ErrorField` component.
 */

export function ErrorField({ error, isVisible }: Props): JSX.Element | null {
  if (!isVisible) {
    return null;
  }

  return <span className={styles.wrapper}>{error?.message}</span>;
}
