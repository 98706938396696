/**
 * Module dependencies.
 */

import { SubmitButton } from 'app/components/atoms/forms/submit-button/submit-button';
import React, { ComponentType, useEffect, useMemo, useRef } from 'react';
import { FieldValues, SubmitHandler, UseFormReturn, useWatch } from 'react-hook-form';
import { FormDatePickerField } from './form-components/form-date-picker-field';
import { FormInputField } from './form-components/form-input-field';
import { FormInputNumber } from './form-components/form-input-number';
import { FormSelectField } from './form-components/form-select-field';
import { FormFieldType, FormList, FormListField } from './form-types';
import { IfCondition } from './form-widgets/if-condition';
import { Render } from './form-widgets/render';
import { FormCheckBoxField } from './form-components/form-checkbox-field';
import { SwitchCondition } from './form-widgets/switch-condition';
import { FormSliderField } from './form-components/form-slider-field';
import { Columns } from './form-widgets/columns';
import { ListItemForm } from './form-widgets/list-item-form';
import { LoadHooks } from './form-widgets/load-hooks';

/**
 * `Props` type.
 */

type Props = {
  formType: 'add' | 'edit';
} & (
  | {
      onSubmit: SubmitHandler<FieldValues>;
      formFields: FormList;
      form: UseFormReturn<FieldValues, any>;
      submitLabel: string;
    }
  | {
      onSubmit?: undefined;
      submitLabel?: undefined;
      formFields: FormList;
      form: UseFormReturn<FieldValues, any>;
    }
);

/**
 * Components.
 */

export const components: Record<FormFieldType, ComponentType<any>> = {
  // Forms
  selectField: FormSelectField,
  checkBoxField: FormCheckBoxField,
  sliderField: FormSliderField,
  inputField: FormInputField,
  inputNumberField: FormInputNumber,
  render: Render,
  datePickerField: FormDatePickerField,

  // Widgets
  switch: SwitchCondition,
  if: IfCondition,
  loadHooks: LoadHooks,
  columns: Columns,
  listItemForm: ListItemForm
};

/**
 * `FormProps` type.
 */

type FormProps = {
  form: Props['form'];
  onSubmit?: SubmitHandler<FieldValues>;
  submitLabel: Props['submitLabel'];
  children: JSX.Element;
};

/**
 * `Form` component.
 */

function Form({ form, onSubmit, children, submitLabel }: FormProps): JSX.Element {
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = form;

  if (!onSubmit || !submitLabel) {
    return children;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {children}

      <SubmitButton disabled={isSubmitting} label={submitLabel} />
    </form>
  );
}

/**
 * Export `CrudFormRenderProps` type.
 */

export type CrudFormRenderProps = {
  formFields: FormList;
  formType: 'add' | 'edit';
  form: UseFormReturn<FieldValues, any>;
};

/**
 * `CrudFormItemRenderProps` type.
 */

type CrudFormItemRenderProps = Pick<CrudFormRenderProps, 'formType' | 'form'> & {
  item: FormListField;
  values: any;
};

/**
 * `CrudFormItemRender` component.
 */

function CrudFormItemRender(props: CrudFormItemRenderProps): JSX.Element | null {
  const { form, formType, item, values } = props;
  const Component = components[item.type];
  const { watchChange } = item as any;
  const firstWatch = useRef<boolean>(true);
  const watchValues = useWatch({ control: form.control, name: watchChange?.watchFields ?? [] });
  const hasDisabled = useMemo(() => {
    return typeof (item as any).disabled === 'function' ? (item as any).disabled(values ?? {}) : (item as any).disabled;
  }, [item, values]);

  useEffect(() => {
    if (!watchChange || firstWatch.current) {
      firstWatch.current = false;

      return;
    }

    watchChange.onChange(watchValues, form);
  }, [form, watchChange, watchValues]);

  if ((item as any).visible && (item as any).visible(values) === false) {
    return null;
  }

  return (
    <Component
      form={form}
      formType={formType}
      item={{
        ...item,
        disabled: hasDisabled
      }}
      key={(item as any).key ?? (item as any).name}
    />
  );
}

/**
 * `CrudFormRender` component.
 */

export function CrudFormRender({ form, formType, formFields }: CrudFormRenderProps): JSX.Element {
  const values = form.watch();

  return (
    <>
      {formFields.map(item => {
        return (
          <CrudFormItemRender
            form={form}
            formType={formType}
            item={item}
            key={(item as any).key ?? (item as any).name}
            values={values}
          />
        );
      })}
    </>
  );
}

/**
 * Export `CrudForm` component.
 */

export function CrudForm({ onSubmit, formType, submitLabel, form, formFields }: Props): JSX.Element {
  return (
    <Form form={form} onSubmit={onSubmit} submitLabel={submitLabel}>
      <CrudFormRender form={form} formFields={formFields} formType={formType} />
    </Form>
  );
}
