/**
 * Module dependencies.
 */

import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { InputNumber, InputNumberProps } from './input-number';
import React, { ForwardedRef, forwardRef } from 'react';

/**
 * `Props` type.
 */

type Props<T extends FieldValues> = UseControllerProps<T> &
  InputNumberProps & {
    normalizeValue?: (newValue: string, value: string) => string;
  };

/**
 * `InputNumberFieldComponent` component.
 */

export function InputNumberFieldComponent<T extends FieldValues>(
  props: Props<T>,
  forwardedRef: ForwardedRef<any>
): JSX.Element {
  const { control, defaultValue, name, normalizeValue, rules, shouldUnregister, formatter, parser, ...inputProps } =
    props;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { name, onBlur, onChange, value }, fieldState: { error, invalid, isDirty, isTouched } }) => {
        return (
          <InputNumber
            formatter={formatter}
            invalid={invalid}
            isDirty={isDirty}
            isTouched={isTouched}
            name={name}
            onBlur={onBlur as any}
            parser={parser}
            value={value}
            {...inputProps}
            error={error}
            onChange={newValue => {
              const finalValue = normalizeValue ? normalizeValue(String(newValue), value) : newValue;

              onChange(parseFloat(finalValue as any));

              if (inputProps.onChange) {
                inputProps.onChange(finalValue);
              }
            }}
            ref={forwardedRef}
          />
        );
      }}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  );
}

/**
 * Export `InputNumberField`.
 */

export const InputNumberField = forwardRef<Props<any>, any>(InputNumberFieldComponent);
