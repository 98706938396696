/**
 * Module dependencies.
 */

import { InputNumberField } from 'app/components/atoms/forms/fields/input-number/input-number-field';
import React, { useCallback } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { InputNumberFormItem } from '../form-types';
import { defaultInputCurrencyFormatter, defaultInputCurrencyParser } from 'app/core/utils/currency';

/**
 * `FormInputNumberProps` type.
 */

export type FormInputNumberProps = {
  name: string;
  item: InputNumberFormItem;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * Export `FormInputNumber` component.
 */

export function FormInputNumber(props: FormInputNumberProps): JSX.Element {
  const { form, item } = props;
  const { control } = form;
  const {
    name,
    placeholder,
    label,
    disabled,
    rules,
    onChange,
    formatter = defaultInputCurrencyFormatter,
    parser = defaultInputCurrencyParser
  } = item;

  const handleChange = useCallback(
    (value: any) => {
      if (onChange) {
        onChange(value, form);
      }
    },
    [form, onChange]
  );

  return (
    <InputNumberField
      control={control}
      disabled={disabled}
      formatter={formatter}
      htmlType={'number'}
      label={label}
      name={name}
      onChange={handleChange}
      parser={parser}
      placeholder={placeholder ?? placeholder === false ? undefined : label}
      rules={rules}
    />
  );
}
