/**
 * Module dependencies.
 */

import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { RenderWidget } from '../form-types';

/**
 * `Props` type.
 */

export type RenderProps = {
  name: string;
  item: RenderWidget;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * Export `Render` component.
 */

export function Render(props: RenderProps): JSX.Element | null {
  const { item } = props;
  const Component = (item as any).renderComponent;

  if (Component) {
    return <Component {...props} />;
  }

  return (item as any).render;
}
